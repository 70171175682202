.icon-scroll {
    --#{$prefix}icon-scroll-width: #{$icon-scroll-width};
    --#{$prefix}icon-scroll-height: #{$icon-scroll-height};
    --#{$prefix}icon-scroll-border-width: #{$icon-scroll-border-width};
    --#{$prefix}icon-scroll-border-color: #{$icon-scroll-border-color};
    --#{$prefix}icon-scroll-border-radius: #{$icon-scroll-border-radius};

    --#{$prefix}icon-scroll-dot-size: #{$icon-scroll-dot-size};
    --#{$prefix}icon-scroll-dot-animation-duration: #{$icon-scroll-dot-animation-duration};

    display: inline-block;
    width: var(--#{$prefix}icon-scroll-width); 
    height: var(--#{$prefix}icon-scroll-height);
    border: var(--#{$prefix}icon-scroll-border-width) solid var(--#{$prefix}icon-scroll-border-color);
    border-radius: var(--#{$prefix}icon-scroll-border-radius);
    position: relative;

    &:before {
        content: "";
        position: absolute;
        width: var(--#{$prefix}icon-scroll-dot-size);
        height: var(--#{$prefix}icon-scroll-dot-size);
        top: var(--#{$prefix}icon-scroll-dot-size);
        left: calc(50% - (var(--#{$prefix}icon-scroll-dot-size)/2));
        border-radius: 50%;
        background: #fff;
        animation-duration: var(--#{$prefix}icon-scroll-dot-animation-duration);
        animation-iteration-count: infinite;
        animation-name: scroll;
    }
}

@-webkit-keyframes scroll {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        @include icon-scroll-transform(calc(var(--#{$prefix}icon-scroll-height) - calc(var(--#{$prefix}icon-scroll-dot-size) * 3)));
    }
}

@keyframes scroll {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        @include icon-scroll-transform(calc(var(--#{$prefix}icon-scroll-height) - calc(var(--#{$prefix}icon-scroll-dot-size) * 3)));
    }
}