.card {

    --#{$prefix}card-overlay-bottom-bg-gradient: #{$card-overlay-bottom-bg-gradient};

    --#{$prefix}card-content-hover-bg-color: #{$card-content-hover-bg-color};

    --#{$prefix}card-float-bottom-margin-top: #{$card-float-bottom-margin-top};
    --#{$prefix}card-float-bottom-margin-right: #{$card-float-bottom-margin-right};
    --#{$prefix}card-float-bottom-margin-left: #{$card-float-bottom-margin-left};

    --#{$prefix}card-float-side-pading-x: #{$card-float-side-pading-x};
    --#{$prefix}card-float-side-content-width: #{$card-float-side-content-width};
    --#{$prefix}card-float-side-content-margin-top: #{$card-float-side-content-margin-top};
    --#{$prefix}card-float-side-content-zindex: #{$card-float-side-content-zindex};

    &:hover {
        & figure.image-hover-scale img {
            transform: scale(#{$figure-image-scale});
            -webkit-transform: scale(#{$figure-image-scale});
            -moz-transform: scale(#{$figure-image-scale});
            -ms-transform: scale(#{$figure-image-scale});
            -o-transform: scale(#{$figure-image-scale});
        }

        & figure.image-hover-overlay::after {
            opacity: 1;
        }

        & figure .image-hover-icon {
            opacity: 1;
        }
    }

    /* Card Overlay Bootom */
    &.card-overlay-bottom {
        & .card-img-overlay {
            top: unset;
            background: var(--#{$prefix}card-overlay-bottom-bg-gradient);
        }
    }

    /* Card Content Hover */
    &.card-content-hover {
        & .card-body {
            @include transition-base;
        }

        &:hover .card-body {
            background-color: var(--#{$prefix}card-content-hover-bg-color) !important;

            & *:not(.btn) {
                color: var(--bs-white);
            }
        }
    }

    /* Card Float Bootom */
    &.card-float-bottom {
        & .card-body {
            margin-top: var(--#{$prefix}card-float-bottom-margin-top);
            margin-right: var(--#{$prefix}card-float-bottom-margin-right);
            margin-left: var(--#{$prefix}card-float-bottom-margin-left);
            position: relative;
            z-index: 3;
        }
    }

    /* Card Float Side */
    &.card-float-side {
        @include media-breakpoint-up(lg) {
            padding-right: var(--#{$prefix}card-float-side-pading-x);

            & .card-body {
                position: absolute;
                top: 3rem;
                right: 0;
                width: var(--#{$prefix}card-float-side-content-width);
                z-index: 3;
            }

            &.card-float-side-right {
                padding-right: var(--#{$prefix}card-float-side-pading-x);
                padding-left: unset;

                & .card-body {
                    right: 0;
                    left: unset;
                }
            }

            &.card-float-side-left {
                padding-right: unset;
                padding-left: var(--#{$prefix}card-float-side-pading-x);

                & .card-body {
                    right: unset;
                    left: 0;
                }
            }
        }
    }

}
