// Code
pre,
code,
kbd,
samp {
  font-weight: $font-weight-semibold;
}

// Code wrapper
.code-wrapper {
    margin-bottom: 0;
    position: relative;

    .btn-clipboard {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        cursor: pointer;
        transform: none;
        @include font-size(0.65rem);
        padding: 0.1rem .45rem 0.1rem;
        z-index: 3;
    }

    & pre>code[class*=language-] {
        padding: 2.25em;
    }
}