/* Heading Font */
.h-special-font {
    font-family: #{$font-family-special} !important;
}

.h-sub-font {
    font-family: #{$font-family-sub} !important;
}

/* Width */
.w-auto {
    width: auto !important;
}

/* Start, End */
.start-unset {
    left: unset !important;
}

.end-unset {
    right: unset !important;
}