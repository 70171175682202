// Transition
@mixin transition-base {
    transition: $transition-base;
    -webkit-transition: $transition-base;
    -moz-transition: $transition-base;
    -ms-transition: $transition-base;
    -o-transition: $transition-base;
}

// Icon Scroll Transform
@mixin icon-scroll-transform($value) {
    transform: translateY($value);
    -webkit-transform: translateY($value);
    -moz-transform: translateY($value);
    -ms-transform: translateY($value);
    -o-transform: translateY($value);
}

// Animation
@mixin animation($name, $duration: 0.35s, $delay: 0s) {
    $prefixes: -webkit-,
    -moz-,
    -ms-,
    -o-,
    '';

    @each $prefix in $prefixes {
        #{$prefix}animation-name: $name;
        #{$prefix}animation-duration: $duration;
        #{$prefix}animation-delay: $delay;
        #{$prefix}animation-fill-mode: both;
    }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @-ms-keyframes #{$name} {
        @content;
    }

    @-o-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

// Image/Video Background
@mixin background-cover($bg-size: '', $bg-attachment: '') {
    background-repeat: no-repeat;
    background-position: center center;

    @if $bg-size !='' {
        background-size: $bg-size;
    }

    @if $bg-attachment !='' {
        background-attachment: $bg-attachment !important;
    }
}

@mixin background-overlay($bg-opacity) {
    &.bg-overlay-#{$bg-opacity*1000}:before {
        background: rgba($black, $bg-opacity);
    }

    &.bg-overlay-#{$bg-opacity*1000}-light:before {
        background: rgba($white, $bg-opacity);
    }
}

@mixin background-video-overlay($bg-opacity) {
    &.bg-overlay-#{$bg-opacity*1000}:after {
        background: rgba($black, $bg-opacity);
    }

    &.bg-overlay-#{$bg-opacity*1000}-light:after {
        background: rgba($white, $bg-opacity);
    }
}

// Star Rate
@mixin bg-size ($size) {
    $heightValue: map-get((xs: 10, sm :16, md :24, lg :32), $size);
    background-size: #{$heightValue}+'px';
}

// Image overlay
@mixin image-overlay-scale {
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
}

@mixin image-overlay-befor-after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    transition: var(--#{$prefix}figure-image-transition);
}