$themes: (
    "default": $brown,
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "gray": $gray
);

@mixin theme($color, $bg) {
    [data-theme-color-toggle=#{$color}] {
        background: $bg !important;
        color: #fff !important;
        position: relative;
        display: block;

        i {
            opacity: 0;
            visibility: hidden;
        }

        &.selected i {
            opacity: 1;
            visibility: visible;
        }
    }
}

@each $color,
$bg in $themes {
    @include theme($color, $bg);
}