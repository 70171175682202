@include keyframes(slideIn) {

    0% {
        transform: translateY(-1rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
}

.slideIn {

    @include animation(slideIn);
}

.upDown {
    @include animation(mover, 0.75s);
}

@include keyframes(mover) {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-15px);
    }
}