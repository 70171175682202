/* START CONFIGURATION */

// Import bootstrap functions SCSS
@import "../../../node_modules/bootstrap/scss/functions";

// Import theme color variables SCSS 
@import 'theme-color-variables';

// Import theme variables SCSS 
@import 'variables';
// Import bootstrap variables SCSS
@import "../../../node_modules/bootstrap/scss/variables";

// Import theme variables dark SCSS 
@import "variables-dark";
// Import bootstrap variables SCSS (dark mode) 
@import "../../../node_modules/bootstrap/scss/variables-dark";

// Import bootstrap maps SCSS 
@import "../../../node_modules/bootstrap/scss/maps";

// Import bootstrap mixins SCSS 
@import "../../../node_modules/bootstrap/scss/mixins";
// Import theme mixin SCSS
@import "theme/mixins";

// Import bootstrap utilities SCSS 
@import "../../../node_modules/bootstrap/scss/utilities";
// Import theme utilities SCSS 
@import "theme/utilities";

/* END CONFIGURATION */

/*-----------------------------------------------------------*/

/* START LAYOUT & COMPONENTS */

//Import Google fonts
@import url($path-to-fonts);

// Import bootstrap root SCSS
@import "../../../node_modules/bootstrap/scss/root";
// Import theme root SCSS
@import "theme/root";

// Import bootstrap reboot SCSS 
@import "../../../node_modules/bootstrap/scss/reboot";
// Import theme reboot SCSS 
@import "theme/reboot";

// Import bootstrap type SCSS 
@import "../../../node_modules/bootstrap/scss/type";
// Import theme type SCSS 
@import "theme/type";

// Import bootstrap images SCSS 
@import "../../../node_modules/bootstrap/scss/images";
// Import theme images SCSS
@import "theme/image";

// Import bootstrap containers SCSS 
@import "../../../node_modules/bootstrap/scss/containers";

// Import bootstrap grid SCSS 
@import "../../../node_modules/bootstrap/scss/grid";

// Import bootstrap tables SCSS 
@import "../../../node_modules/bootstrap/scss/tables";

// Import bootstrap forms SCSS 
@import "../../../node_modules/bootstrap/scss/forms";

// Import bootstrap buttons SCSS 
@import "../../../node_modules/bootstrap/scss/buttons";
// Import theme button SCSS
@import "theme/buttons";

// Import bootstrap transitions SCSS 
@import "../../../node_modules/bootstrap/scss/transitions";

// Import bootstrap dropdown SCSS 
@import "../../../node_modules/bootstrap/scss/dropdown";

// Import bootstrap button-group SCSS 
//@import "../../../node_modules/bootstrap/scss/button-group";

// Import bootstrap nav SCSS 
@import "../../../node_modules/bootstrap/scss/nav";

// Import bootstrap navbar SCSS 
@import "../../../node_modules/bootstrap/scss/navbar";

// Import bootstrap card SCSS 
@import "../../../node_modules/bootstrap/scss/card";
// Import theme card SCSS
@import "theme/card";

// Import bootstrap accordion SCSS 
@import "../../../node_modules/bootstrap/scss/accordion";

// Import bootstrap breadcrumb SCSS 
@import "../../../node_modules/bootstrap/scss/breadcrumb";

// Import bootstrap pagination SCSS 
//@import "../../../node_modules/bootstrap/scss/pagination";

// Import bootstrap badge SCSS 
@import "../../../node_modules/bootstrap/scss/badge";

// Import bootstrap alert SCSS 
@import "../../../node_modules/bootstrap/scss/alert";

// Import bootstrap button-group SCSS 
@import "../../../node_modules/bootstrap/scss/progress";

// Import bootstrap button-group SCSS 
@import "../../../node_modules/bootstrap/scss/list-group";

// Import bootstrap close SCSS 
@import "../../../node_modules/bootstrap/scss/close";

// Import bootstrap button-group SCSS 
//@import "../../../node_modules/bootstrap/scss/toasts";

// Import bootstrap modal SCSS 
@import "../../../node_modules/bootstrap/scss/modal";

// Import bootstrap tooltip SCSS 
// @import "../../../node_modules/bootstrap/scss/tooltip";

// Import bootstrap popover SCSS 
// @import "../../../node_modules/bootstrap/scss/popover";

// Import bootstrap carousel SCSS 
@import "../../../node_modules/bootstrap/scss/carousel";

// Import bootstrap spinners SCSS 
@import "../../../node_modules/bootstrap/scss/spinners";

// Import bootstrap offcanvas SCSS 
@import "../../../node_modules/bootstrap/scss/offcanvas";

// Import bootstrap placeholders SCSS 
// @import "../../../node_modules/bootstrap/scss/placeholders";

/* END LAYOUT & COMPONENTS */

/*-----------------------------------------------------------*/

/* START HELPERS */

// Import bootstrap helpers SCSS
@import "../../../node_modules/bootstrap/scss/helpers";

/* END HELPERS */

/*-----------------------------------------------------------*/

/* START UTILITIES */

// Import bootstrap utilities SCSS
@import "../../../node_modules/bootstrap/scss/utilities/api";

/* END UTILITIES */

/*-----------------------------------------------------------*/

/* START THEME CUSTOM */

// Import theme header SCSS
@import "theme/header";

// Import theme hero SCSS
@import "theme/hero";

// Import theme header SCSS
@import "theme/check-rooms";

// Import theme scroll-top SCSS
@import "theme/scroll-top";

// Import theme image-bg SCSS
@import "theme/image-bg";

// Import theme image-bg SCSS
@import "theme/video-bg";

// Import theme animation SCSS
@import "theme/animation";

// Import theme star rate SCSS
@import "theme/star-rate";

// Import theme icon scroll SCSS
@import "theme/icon-scroll";

// Import theme room SCSS
@import "theme/room";

// Import theme theme colors SCSS
@import "theme/theme-colors";

// Import theme code SCSS
@import "theme/code";

// Import theme position SCSS
@import "theme/position";

/* END THEME CUSTOM */
