// Room slide
.room-slide {
    --#{$prefix}room-slide-height: #{$room-slide-height};
    --#{$prefix}room-slide-item-padding: #{$room-slide-item-padding};
    --#{$prefix}room-slide-item-title-pading-top: #{$room-slide-item-title-pading-top};
    --#{$prefix}room-slide-item-title-font-size-collapse: #{$room-slide-item-title-font-size-collapse};
    --#{$prefix}room-slide-item-content-padding-collapse: #{$room-slide-item-content-padding-collapse};
    --#{$prefix}room-slide-item-content-margin-bottom: #{$room-slide-item-content-margin-bottom};
    --#{$prefix}room-slide-item-button-margin-bottom: #{$room-slide-item-button-margin-bottom};
    --#{$prefix}card-overlay-bottom-bg-gradient: #{$card-overlay-bottom-bg-gradient};
    --#{$prefix}card-overlay-bottom-bg-gradient: #{$card-overlay-bottom-bg-gradient};

    @include media-breakpoint-up(lg) {
        display: flex;

        .room-slide-item {
            width: 16.666666%;
            height: var(--#{$prefix}room-slide-height);
            position: relative;
            overflow: hidden;
            @include transition-base;

            &:not(:last-child) {
                padding-right: var(--#{$prefix}room-slide-item-padding);
            }

            &.active {
                width: 50%;
                cursor: unset;

                .card-title {
                    @include transition-base;
                }

                .card.card-overlay-bottom .card-text {
                    visibility: visible;
                    opacity: 1;
                    margin-bottom: 0;
                }
            }

            & .card,
            & .card-img {
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
            }

            & .card-img-overlay {
                @include transition-base;
            }
            &:not(.active) .card-img-overlay {
                padding: var(--#{$prefix}room-slide-item-content-padding-collapse)!important;
            }

            & .card-title,
            & .card-link {
                @include transition-base;
            }

            & .card-text {
                visibility: hidden;
                opacity: 0;
                margin-bottom: var(--#{$prefix}room-slide-item-content-margin-bottom);
                @include transition-base;
            }

            &:not(.active) .card-title {
                font-size: var(--#{$prefix}room-slide-item-title-font-size-collapse);
                @include media-breakpoint-down(xl) {
                    width: min-content;
                }
            }

            &:not(.active) .card-link {
                opacity: 0;
                visibility: hidden;
                margin-bottom: var(--#{$prefix}room-slide-item-button-margin-bottom);
            }
        }

        &.room-slide-3col .room-slide-item {
            width: 25%;

            &.active {
                width: 50%;
            }
        }

        &.room-slide-5col .room-slide-item {
            width: 15%;

            &:not(.active) .card-title {
                width: min-content;
            }

            &.active {
                width: 40%;
            }
        }
    }
}