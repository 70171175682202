@charset "UTF-8";
/* START CONFIGURATION */
/* END CONFIGURATION */
/*-----------------------------------------------------------*/
/* START LAYOUT & COMPONENTS */
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Red+Hat+Text:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
:root,
[data-bs-theme=light] {
  --bs-blue: #366DC0;
  --bs-indigo: #633A94;
  --bs-purple: #504EAB;
  --bs-pink: #a2428c;
  --bs-red: #C44D4D;
  --bs-orange: #CF8452;
  --bs-yellow: #CCAC57;
  --bs-green: #548D57;
  --bs-teal: #329BA0;
  --bs-cyan: #2C83B1;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c6c6c;
  --bs-gray-dark: #343434;
  --bs-gray-100: #f4f4f4;
  --bs-gray-200: #e9e9e9;
  --bs-gray-300: #dedede;
  --bs-gray-400: #cecece;
  --bs-gray-500: #adadad;
  --bs-gray-600: #6c6c6c;
  --bs-gray-700: #494949;
  --bs-gray-800: #343434;
  --bs-gray-900: #181818;
  --bs-primary: #9B7C64;
  --bs-secondary: #494949;
  --bs-success: #548D57;
  --bs-info: #2C83B1;
  --bs-warning: #CCAC57;
  --bs-danger: #C44D4D;
  --bs-light: #f4f4f4;
  --bs-dark: #181818;
  --bs-primary-rgb: 155, 124, 100;
  --bs-secondary-rgb: 73, 73, 73;
  --bs-success-rgb: 84, 141, 87;
  --bs-info-rgb: 44, 131, 177;
  --bs-warning-rgb: 204, 172, 87;
  --bs-danger-rgb: 196, 77, 77;
  --bs-light-rgb: 244, 244, 244;
  --bs-dark-rgb: 24, 24, 24;
  --bs-primary-text-emphasis: #3e3228;
  --bs-secondary-text-emphasis: #1d1d1d;
  --bs-success-text-emphasis: #223823;
  --bs-info-text-emphasis: #123447;
  --bs-warning-text-emphasis: #524523;
  --bs-danger-text-emphasis: #4e1f1f;
  --bs-light-text-emphasis: #494949;
  --bs-dark-text-emphasis: #494949;
  --bs-primary-bg-subtle: #f5f2f0;
  --bs-secondary-bg-subtle: #ededed;
  --bs-success-bg-subtle: #eef4ee;
  --bs-info-bg-subtle: #eaf3f7;
  --bs-warning-bg-subtle: #faf7ee;
  --bs-danger-bg-subtle: #f9eded;
  --bs-light-bg-subtle: #fafafa;
  --bs-dark-bg-subtle: #cecece;
  --bs-primary-border-subtle: #d7cbc1;
  --bs-secondary-border-subtle: #b6b6b6;
  --bs-success-border-subtle: #bbd1bc;
  --bs-info-border-subtle: #abcde0;
  --bs-warning-border-subtle: #ebdebc;
  --bs-danger-border-subtle: #e7b8b8;
  --bs-light-border-subtle: #e9e9e9;
  --bs-dark-border-subtle: #adadad;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Jost", sans-serif;
  --bs-font-monospace: "Red Hat Mono", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1.125rem;
  --bs-body-font-weight: 300;
  --bs-body-line-height: 1.5;
  --bs-body-color: #181818;
  --bs-body-color-rgb: 24, 24, 24;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(24, 24, 24, 0.75);
  --bs-secondary-color-rgb: 24, 24, 24;
  --bs-secondary-bg: #e9e9e9;
  --bs-secondary-bg-rgb: 233, 233, 233;
  --bs-tertiary-color: rgba(24, 24, 24, 0.5);
  --bs-tertiary-color-rgb: 24, 24, 24;
  --bs-tertiary-bg: #f4f4f4;
  --bs-tertiary-bg-rgb: 244, 244, 244;
  --bs-heading-color: inherit;
  --bs-link-color: #9B7C64;
  --bs-link-color-rgb: 155, 124, 100;
  --bs-link-decoration: none;
  --bs-link-hover-color: #7c6350;
  --bs-link-hover-color-rgb: 124, 99, 80;
  --bs-code-color: #a2428c;
  --bs-highlight-bg: #e9e9e9;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #e9e9e9;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(155, 124, 100, 0.25);
  --bs-form-valid-color: #548D57;
  --bs-form-valid-border-color: #548D57;
  --bs-form-invalid-color: #C44D4D;
  --bs-form-invalid-border-color: #C44D4D;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dedede;
  --bs-body-color-rgb: 222, 222, 222;
  --bs-body-bg: #181818;
  --bs-body-bg-rgb: 24, 24, 24;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 222, 222, 0.75);
  --bs-secondary-color-rgb: 222, 222, 222;
  --bs-secondary-bg: #343434;
  --bs-secondary-bg-rgb: 52, 52, 52;
  --bs-tertiary-color: rgba(222, 222, 222, 0.5);
  --bs-tertiary-color-rgb: 222, 222, 222;
  --bs-tertiary-bg: #222222;
  --bs-tertiary-bg-rgb: 34, 34, 34;
  --bs-primary-text-emphasis: #c3b0a2;
  --bs-secondary-text-emphasis: #929292;
  --bs-success-text-emphasis: #98bb9a;
  --bs-info-text-emphasis: #80b5d0;
  --bs-warning-text-emphasis: #e0cd9a;
  --bs-danger-text-emphasis: #dc9494;
  --bs-light-text-emphasis: #f4f4f4;
  --bs-dark-text-emphasis: #dedede;
  --bs-primary-bg-subtle: #1f1914;
  --bs-secondary-bg-subtle: #0f0f0f;
  --bs-success-bg-subtle: #111c11;
  --bs-info-bg-subtle: #091a23;
  --bs-warning-bg-subtle: #292211;
  --bs-danger-bg-subtle: #270f0f;
  --bs-light-bg-subtle: #343434;
  --bs-dark-bg-subtle: #1a1a1a;
  --bs-primary-border-subtle: #5d4a3c;
  --bs-secondary-border-subtle: #2c2c2c;
  --bs-success-border-subtle: #325534;
  --bs-info-border-subtle: #1a4f6a;
  --bs-warning-border-subtle: #7a6734;
  --bs-danger-border-subtle: #762e2e;
  --bs-light-border-subtle: #494949;
  --bs-dark-border-subtle: #343434;
  --bs-heading-color: inherit;
  --bs-link-color: #e1d8d1;
  --bs-link-hover-color: #e7e0da;
  --bs-link-color-rgb: 225, 216, 209;
  --bs-link-hover-color-rgb: 231, 224, 218;
  --bs-code-color: #c78eba;
  --bs-border-color: #494949;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #98bb9a;
  --bs-form-valid-border-color: #98bb9a;
  --bs-form-invalid-color: #dc9494;
  --bs-form-invalid-border-color: #dc9494;
}

:root,
[data-bs-theme=light] {
  --bs-primary-light: #e1d8d1;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1.5rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.15;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Red Hat Text", sans-serif;
  font-weight: 500;
  line-height: 1.35;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.40625rem + 1.875vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.8125rem;
  }
}

h2, .h2 {
  font-size: calc(1.366875rem + 1.4025vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.41875rem;
  }
}

h3, .h3 {
  font-size: calc(1.321875rem + 0.8625vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.96875rem;
  }
}

h4, .h4 {
  font-size: calc(1.276875rem + 0.3225vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.51875rem;
  }
}

h5, .h5 {
  font-size: calc(1.254375rem + 0.0525vw);
}
@media (min-width: 1200px) {
  h5, .h5 {
    font-size: 1.29375rem;
  }
}

h6, .h6 {
  font-size: 1.125rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small, .small {
  font-size: 0.85em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.85em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.85em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.85em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  font-weight: 400;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

*::scrollbar {
  width: 12px;
}

*::scrollbar-track {
  background-color: var(--bs-gray-100);
}

*::scrollbar-thumb {
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: var(--bs-gray-500);
}

*::-moz-scrollbar {
  width: 12px;
}

*::-moz-scrollbar-track {
  background-color: var(--bs-gray-100);
}

*::-moz-scrollbar-thumb {
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: var(--bs-gray-500);
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background-color: var(--bs-gray-100);
}

*::-webkit-scrollbar-thumb {
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: var(--bs-gray-500);
}

::selection {
  background: #9B7C64;
  color: #fff;
}

::-moz-selection {
  background: #9B7C64;
  color: #fff;
}

::-webkit-selection {
  background: #9B7C64;
  color: #fff;
}

b,
strong {
  font-weight: 500;
}

mark, .mark {
  font-family: Red Hat Mono, monospace !important;
  font-size: 0.95625rem;
}

.lead {
  font-size: calc(1.265625rem + 0.1875vw);
  font-weight: 300;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.40625rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 500;
  line-height: 1.35;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 500;
  line-height: 1.35;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 500;
  line-height: 1.35;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 500;
  line-height: 1.35;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 500;
  line-height: 1.35;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 500;
  line-height: 1.35;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.85em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.125rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.85em;
  color: #6c6c6c;
}
.blockquote-footer::before {
  content: "\2014\a0";
}

/* Heading Font */
.h-special-font {
  font-family: Cinzel, serif !important;
}

.h-sub-font {
  font-family: Red Hat Text, sans-serif !important;
}

/* Width */
.w-auto {
  width: auto !important;
}

/* Start, End */
.start-unset {
  left: unset !important;
}

.end-unset {
  right: unset !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.85em;
  color: var(--bs-secondary-color);
}

figure {
  --bs-figure-image-transition: all 0.35s ease-in-out;
  --bs-figure-image-bg: var(--bs-primary-rgb);
  --bs-figure-image-bg-opacity: 0.35;
  --bs-figure-image-bg-static-opacity: 0.75;
  --bs-figure-image-scale: 1.055;
}
figure.image-hover-overlay-static {
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}
figure.image-hover-overlay-static:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  transition: var(--bs-figure-image-transition);
  background: rgba(var(--bs-figure-image-bg), var(--bs-figure-image-bg-static-opacity));
}
figure.image-hover-overlay {
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}
figure.image-hover-overlay:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  transition: var(--bs-figure-image-transition);
  background: rgba(var(--bs-figure-image-bg), var(--bs-figure-image-bg-opacity));
  opacity: 0;
}
figure.image-hover-overlay:hover::after {
  opacity: 1;
}
figure.image-hover-scale {
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}
figure.image-hover-scale img {
  transition: var(--bs-figure-image-transition);
}
figure.image-hover-scale:hover img {
  transform: scale(var(--bs-figure-image-scale));
}
figure .image-hover-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: var(--bs-figure-image-transition);
  z-index: 2;
  color: white;
}
figure:hover .image-hover-icon {
  opacity: 1;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1160px;
  }
}
@media (min-width: 1380px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1380px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.35rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.35rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.55rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.55rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 0.75rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 0.75rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.25rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.25rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 1.5rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 1.5rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 1.75rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 1.75rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 2.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 2.5rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 3rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 3rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 3.5rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 3.5rem;
}

.g-13,
.gx-13 {
  --bs-gutter-x: 4rem;
}

.g-13,
.gy-13 {
  --bs-gutter-y: 4rem;
}

.g-14,
.gx-14 {
  --bs-gutter-x: 4.5rem;
}

.g-14,
.gy-14 {
  --bs-gutter-y: 4.5rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 5rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 5rem;
}

.g-16,
.gx-16 {
  --bs-gutter-x: 5.5rem;
}

.g-16,
.gy-16 {
  --bs-gutter-y: 5.5rem;
}

.g-17,
.gx-17 {
  --bs-gutter-x: 6rem;
}

.g-17,
.gy-17 {
  --bs-gutter-y: 6rem;
}

.g-18,
.gx-18 {
  --bs-gutter-x: 6.5rem;
}

.g-18,
.gy-18 {
  --bs-gutter-y: 6.5rem;
}

.g-19,
.gx-19 {
  --bs-gutter-x: 7rem;
}

.g-19,
.gy-19 {
  --bs-gutter-y: 7rem;
}

.g-20,
.gx-20 {
  --bs-gutter-x: 7.5rem;
}

.g-20,
.gy-20 {
  --bs-gutter-y: 7.5rem;
}

.g-21,
.gx-21 {
  --bs-gutter-x: 8rem;
}

.g-21,
.gy-21 {
  --bs-gutter-y: 8rem;
}

.g-22,
.gx-22 {
  --bs-gutter-x: 8.5rem;
}

.g-22,
.gy-22 {
  --bs-gutter-y: 8.5rem;
}

.g-23,
.gx-23 {
  --bs-gutter-x: 9rem;
}

.g-23,
.gy-23 {
  --bs-gutter-y: 9rem;
}

.g-24,
.gx-24 {
  --bs-gutter-x: 9.5rem;
}

.g-24,
.gy-24 {
  --bs-gutter-y: 9.5rem;
}

.g-25,
.gx-25 {
  --bs-gutter-x: 10rem;
}

.g-25,
.gy-25 {
  --bs-gutter-y: 10rem;
}

.g-26,
.gx-26 {
  --bs-gutter-x: 10.5rem;
}

.g-26,
.gy-26 {
  --bs-gutter-y: 10.5rem;
}

.g-27,
.gx-27 {
  --bs-gutter-x: 11rem;
}

.g-27,
.gy-27 {
  --bs-gutter-y: 11rem;
}

.g-28,
.gx-28 {
  --bs-gutter-x: 12rem;
}

.g-28,
.gy-28 {
  --bs-gutter-y: 12rem;
}

.g-29,
.gx-29 {
  --bs-gutter-x: 13rem;
}

.g-29,
.gy-29 {
  --bs-gutter-y: 13rem;
}

.g-30,
.gx-30 {
  --bs-gutter-x: 14rem;
}

.g-30,
.gy-30 {
  --bs-gutter-y: 14rem;
}

.g-31,
.gx-31 {
  --bs-gutter-x: 15rem;
}

.g-31,
.gy-31 {
  --bs-gutter-y: 15rem;
}

.g-32,
.gx-32 {
  --bs-gutter-x: 16rem;
}

.g-32,
.gy-32 {
  --bs-gutter-y: 16rem;
}

.g-33,
.gx-33 {
  --bs-gutter-x: 17rem;
}

.g-33,
.gy-33 {
  --bs-gutter-y: 17rem;
}

.g-34,
.gx-34 {
  --bs-gutter-x: 18rem;
}

.g-34,
.gy-34 {
  --bs-gutter-y: 18rem;
}

.g-35,
.gx-35 {
  --bs-gutter-x: 19rem;
}

.g-35,
.gy-35 {
  --bs-gutter-y: 19rem;
}

.g-36,
.gx-36 {
  --bs-gutter-x: 20rem;
}

.g-36,
.gy-36 {
  --bs-gutter-y: 20rem;
}

.g-37,
.gx-37 {
  --bs-gutter-x: 21rem;
}

.g-37,
.gy-37 {
  --bs-gutter-y: 21rem;
}

.g-38,
.gx-38 {
  --bs-gutter-x: 22rem;
}

.g-38,
.gy-38 {
  --bs-gutter-y: 22rem;
}

.g-39,
.gx-39 {
  --bs-gutter-x: 23rem;
}

.g-39,
.gy-39 {
  --bs-gutter-y: 23rem;
}

.g-40,
.gx-40 {
  --bs-gutter-x: 24rem;
}

.g-40,
.gy-40 {
  --bs-gutter-y: 24rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.35rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.35rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.55rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.55rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 0.75rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 0.75rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 1.75rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 1.75rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 3.5rem;
  }
  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 3.5rem;
  }
  .g-sm-13,
  .gx-sm-13 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-13,
  .gy-sm-13 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-14,
  .gx-sm-14 {
    --bs-gutter-x: 4.5rem;
  }
  .g-sm-14,
  .gy-sm-14 {
    --bs-gutter-y: 4.5rem;
  }
  .g-sm-15,
  .gx-sm-15 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-15,
  .gy-sm-15 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-16,
  .gx-sm-16 {
    --bs-gutter-x: 5.5rem;
  }
  .g-sm-16,
  .gy-sm-16 {
    --bs-gutter-y: 5.5rem;
  }
  .g-sm-17,
  .gx-sm-17 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-17,
  .gy-sm-17 {
    --bs-gutter-y: 6rem;
  }
  .g-sm-18,
  .gx-sm-18 {
    --bs-gutter-x: 6.5rem;
  }
  .g-sm-18,
  .gy-sm-18 {
    --bs-gutter-y: 6.5rem;
  }
  .g-sm-19,
  .gx-sm-19 {
    --bs-gutter-x: 7rem;
  }
  .g-sm-19,
  .gy-sm-19 {
    --bs-gutter-y: 7rem;
  }
  .g-sm-20,
  .gx-sm-20 {
    --bs-gutter-x: 7.5rem;
  }
  .g-sm-20,
  .gy-sm-20 {
    --bs-gutter-y: 7.5rem;
  }
  .g-sm-21,
  .gx-sm-21 {
    --bs-gutter-x: 8rem;
  }
  .g-sm-21,
  .gy-sm-21 {
    --bs-gutter-y: 8rem;
  }
  .g-sm-22,
  .gx-sm-22 {
    --bs-gutter-x: 8.5rem;
  }
  .g-sm-22,
  .gy-sm-22 {
    --bs-gutter-y: 8.5rem;
  }
  .g-sm-23,
  .gx-sm-23 {
    --bs-gutter-x: 9rem;
  }
  .g-sm-23,
  .gy-sm-23 {
    --bs-gutter-y: 9rem;
  }
  .g-sm-24,
  .gx-sm-24 {
    --bs-gutter-x: 9.5rem;
  }
  .g-sm-24,
  .gy-sm-24 {
    --bs-gutter-y: 9.5rem;
  }
  .g-sm-25,
  .gx-sm-25 {
    --bs-gutter-x: 10rem;
  }
  .g-sm-25,
  .gy-sm-25 {
    --bs-gutter-y: 10rem;
  }
  .g-sm-26,
  .gx-sm-26 {
    --bs-gutter-x: 10.5rem;
  }
  .g-sm-26,
  .gy-sm-26 {
    --bs-gutter-y: 10.5rem;
  }
  .g-sm-27,
  .gx-sm-27 {
    --bs-gutter-x: 11rem;
  }
  .g-sm-27,
  .gy-sm-27 {
    --bs-gutter-y: 11rem;
  }
  .g-sm-28,
  .gx-sm-28 {
    --bs-gutter-x: 12rem;
  }
  .g-sm-28,
  .gy-sm-28 {
    --bs-gutter-y: 12rem;
  }
  .g-sm-29,
  .gx-sm-29 {
    --bs-gutter-x: 13rem;
  }
  .g-sm-29,
  .gy-sm-29 {
    --bs-gutter-y: 13rem;
  }
  .g-sm-30,
  .gx-sm-30 {
    --bs-gutter-x: 14rem;
  }
  .g-sm-30,
  .gy-sm-30 {
    --bs-gutter-y: 14rem;
  }
  .g-sm-31,
  .gx-sm-31 {
    --bs-gutter-x: 15rem;
  }
  .g-sm-31,
  .gy-sm-31 {
    --bs-gutter-y: 15rem;
  }
  .g-sm-32,
  .gx-sm-32 {
    --bs-gutter-x: 16rem;
  }
  .g-sm-32,
  .gy-sm-32 {
    --bs-gutter-y: 16rem;
  }
  .g-sm-33,
  .gx-sm-33 {
    --bs-gutter-x: 17rem;
  }
  .g-sm-33,
  .gy-sm-33 {
    --bs-gutter-y: 17rem;
  }
  .g-sm-34,
  .gx-sm-34 {
    --bs-gutter-x: 18rem;
  }
  .g-sm-34,
  .gy-sm-34 {
    --bs-gutter-y: 18rem;
  }
  .g-sm-35,
  .gx-sm-35 {
    --bs-gutter-x: 19rem;
  }
  .g-sm-35,
  .gy-sm-35 {
    --bs-gutter-y: 19rem;
  }
  .g-sm-36,
  .gx-sm-36 {
    --bs-gutter-x: 20rem;
  }
  .g-sm-36,
  .gy-sm-36 {
    --bs-gutter-y: 20rem;
  }
  .g-sm-37,
  .gx-sm-37 {
    --bs-gutter-x: 21rem;
  }
  .g-sm-37,
  .gy-sm-37 {
    --bs-gutter-y: 21rem;
  }
  .g-sm-38,
  .gx-sm-38 {
    --bs-gutter-x: 22rem;
  }
  .g-sm-38,
  .gy-sm-38 {
    --bs-gutter-y: 22rem;
  }
  .g-sm-39,
  .gx-sm-39 {
    --bs-gutter-x: 23rem;
  }
  .g-sm-39,
  .gy-sm-39 {
    --bs-gutter-y: 23rem;
  }
  .g-sm-40,
  .gx-sm-40 {
    --bs-gutter-x: 24rem;
  }
  .g-sm-40,
  .gy-sm-40 {
    --bs-gutter-y: 24rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.35rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.35rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.55rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.55rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 0.75rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 0.75rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 1.75rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 1.75rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 2rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 2rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 3rem;
  }
  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 3rem;
  }
  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 3.5rem;
  }
  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 3.5rem;
  }
  .g-md-13,
  .gx-md-13 {
    --bs-gutter-x: 4rem;
  }
  .g-md-13,
  .gy-md-13 {
    --bs-gutter-y: 4rem;
  }
  .g-md-14,
  .gx-md-14 {
    --bs-gutter-x: 4.5rem;
  }
  .g-md-14,
  .gy-md-14 {
    --bs-gutter-y: 4.5rem;
  }
  .g-md-15,
  .gx-md-15 {
    --bs-gutter-x: 5rem;
  }
  .g-md-15,
  .gy-md-15 {
    --bs-gutter-y: 5rem;
  }
  .g-md-16,
  .gx-md-16 {
    --bs-gutter-x: 5.5rem;
  }
  .g-md-16,
  .gy-md-16 {
    --bs-gutter-y: 5.5rem;
  }
  .g-md-17,
  .gx-md-17 {
    --bs-gutter-x: 6rem;
  }
  .g-md-17,
  .gy-md-17 {
    --bs-gutter-y: 6rem;
  }
  .g-md-18,
  .gx-md-18 {
    --bs-gutter-x: 6.5rem;
  }
  .g-md-18,
  .gy-md-18 {
    --bs-gutter-y: 6.5rem;
  }
  .g-md-19,
  .gx-md-19 {
    --bs-gutter-x: 7rem;
  }
  .g-md-19,
  .gy-md-19 {
    --bs-gutter-y: 7rem;
  }
  .g-md-20,
  .gx-md-20 {
    --bs-gutter-x: 7.5rem;
  }
  .g-md-20,
  .gy-md-20 {
    --bs-gutter-y: 7.5rem;
  }
  .g-md-21,
  .gx-md-21 {
    --bs-gutter-x: 8rem;
  }
  .g-md-21,
  .gy-md-21 {
    --bs-gutter-y: 8rem;
  }
  .g-md-22,
  .gx-md-22 {
    --bs-gutter-x: 8.5rem;
  }
  .g-md-22,
  .gy-md-22 {
    --bs-gutter-y: 8.5rem;
  }
  .g-md-23,
  .gx-md-23 {
    --bs-gutter-x: 9rem;
  }
  .g-md-23,
  .gy-md-23 {
    --bs-gutter-y: 9rem;
  }
  .g-md-24,
  .gx-md-24 {
    --bs-gutter-x: 9.5rem;
  }
  .g-md-24,
  .gy-md-24 {
    --bs-gutter-y: 9.5rem;
  }
  .g-md-25,
  .gx-md-25 {
    --bs-gutter-x: 10rem;
  }
  .g-md-25,
  .gy-md-25 {
    --bs-gutter-y: 10rem;
  }
  .g-md-26,
  .gx-md-26 {
    --bs-gutter-x: 10.5rem;
  }
  .g-md-26,
  .gy-md-26 {
    --bs-gutter-y: 10.5rem;
  }
  .g-md-27,
  .gx-md-27 {
    --bs-gutter-x: 11rem;
  }
  .g-md-27,
  .gy-md-27 {
    --bs-gutter-y: 11rem;
  }
  .g-md-28,
  .gx-md-28 {
    --bs-gutter-x: 12rem;
  }
  .g-md-28,
  .gy-md-28 {
    --bs-gutter-y: 12rem;
  }
  .g-md-29,
  .gx-md-29 {
    --bs-gutter-x: 13rem;
  }
  .g-md-29,
  .gy-md-29 {
    --bs-gutter-y: 13rem;
  }
  .g-md-30,
  .gx-md-30 {
    --bs-gutter-x: 14rem;
  }
  .g-md-30,
  .gy-md-30 {
    --bs-gutter-y: 14rem;
  }
  .g-md-31,
  .gx-md-31 {
    --bs-gutter-x: 15rem;
  }
  .g-md-31,
  .gy-md-31 {
    --bs-gutter-y: 15rem;
  }
  .g-md-32,
  .gx-md-32 {
    --bs-gutter-x: 16rem;
  }
  .g-md-32,
  .gy-md-32 {
    --bs-gutter-y: 16rem;
  }
  .g-md-33,
  .gx-md-33 {
    --bs-gutter-x: 17rem;
  }
  .g-md-33,
  .gy-md-33 {
    --bs-gutter-y: 17rem;
  }
  .g-md-34,
  .gx-md-34 {
    --bs-gutter-x: 18rem;
  }
  .g-md-34,
  .gy-md-34 {
    --bs-gutter-y: 18rem;
  }
  .g-md-35,
  .gx-md-35 {
    --bs-gutter-x: 19rem;
  }
  .g-md-35,
  .gy-md-35 {
    --bs-gutter-y: 19rem;
  }
  .g-md-36,
  .gx-md-36 {
    --bs-gutter-x: 20rem;
  }
  .g-md-36,
  .gy-md-36 {
    --bs-gutter-y: 20rem;
  }
  .g-md-37,
  .gx-md-37 {
    --bs-gutter-x: 21rem;
  }
  .g-md-37,
  .gy-md-37 {
    --bs-gutter-y: 21rem;
  }
  .g-md-38,
  .gx-md-38 {
    --bs-gutter-x: 22rem;
  }
  .g-md-38,
  .gy-md-38 {
    --bs-gutter-y: 22rem;
  }
  .g-md-39,
  .gx-md-39 {
    --bs-gutter-x: 23rem;
  }
  .g-md-39,
  .gy-md-39 {
    --bs-gutter-y: 23rem;
  }
  .g-md-40,
  .gx-md-40 {
    --bs-gutter-x: 24rem;
  }
  .g-md-40,
  .gy-md-40 {
    --bs-gutter-y: 24rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.35rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.35rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.55rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.55rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 0.75rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 0.75rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 1.75rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 1.75rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 3.5rem;
  }
  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 3.5rem;
  }
  .g-lg-13,
  .gx-lg-13 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-13,
  .gy-lg-13 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-14,
  .gx-lg-14 {
    --bs-gutter-x: 4.5rem;
  }
  .g-lg-14,
  .gy-lg-14 {
    --bs-gutter-y: 4.5rem;
  }
  .g-lg-15,
  .gx-lg-15 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-15,
  .gy-lg-15 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-16,
  .gx-lg-16 {
    --bs-gutter-x: 5.5rem;
  }
  .g-lg-16,
  .gy-lg-16 {
    --bs-gutter-y: 5.5rem;
  }
  .g-lg-17,
  .gx-lg-17 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-17,
  .gy-lg-17 {
    --bs-gutter-y: 6rem;
  }
  .g-lg-18,
  .gx-lg-18 {
    --bs-gutter-x: 6.5rem;
  }
  .g-lg-18,
  .gy-lg-18 {
    --bs-gutter-y: 6.5rem;
  }
  .g-lg-19,
  .gx-lg-19 {
    --bs-gutter-x: 7rem;
  }
  .g-lg-19,
  .gy-lg-19 {
    --bs-gutter-y: 7rem;
  }
  .g-lg-20,
  .gx-lg-20 {
    --bs-gutter-x: 7.5rem;
  }
  .g-lg-20,
  .gy-lg-20 {
    --bs-gutter-y: 7.5rem;
  }
  .g-lg-21,
  .gx-lg-21 {
    --bs-gutter-x: 8rem;
  }
  .g-lg-21,
  .gy-lg-21 {
    --bs-gutter-y: 8rem;
  }
  .g-lg-22,
  .gx-lg-22 {
    --bs-gutter-x: 8.5rem;
  }
  .g-lg-22,
  .gy-lg-22 {
    --bs-gutter-y: 8.5rem;
  }
  .g-lg-23,
  .gx-lg-23 {
    --bs-gutter-x: 9rem;
  }
  .g-lg-23,
  .gy-lg-23 {
    --bs-gutter-y: 9rem;
  }
  .g-lg-24,
  .gx-lg-24 {
    --bs-gutter-x: 9.5rem;
  }
  .g-lg-24,
  .gy-lg-24 {
    --bs-gutter-y: 9.5rem;
  }
  .g-lg-25,
  .gx-lg-25 {
    --bs-gutter-x: 10rem;
  }
  .g-lg-25,
  .gy-lg-25 {
    --bs-gutter-y: 10rem;
  }
  .g-lg-26,
  .gx-lg-26 {
    --bs-gutter-x: 10.5rem;
  }
  .g-lg-26,
  .gy-lg-26 {
    --bs-gutter-y: 10.5rem;
  }
  .g-lg-27,
  .gx-lg-27 {
    --bs-gutter-x: 11rem;
  }
  .g-lg-27,
  .gy-lg-27 {
    --bs-gutter-y: 11rem;
  }
  .g-lg-28,
  .gx-lg-28 {
    --bs-gutter-x: 12rem;
  }
  .g-lg-28,
  .gy-lg-28 {
    --bs-gutter-y: 12rem;
  }
  .g-lg-29,
  .gx-lg-29 {
    --bs-gutter-x: 13rem;
  }
  .g-lg-29,
  .gy-lg-29 {
    --bs-gutter-y: 13rem;
  }
  .g-lg-30,
  .gx-lg-30 {
    --bs-gutter-x: 14rem;
  }
  .g-lg-30,
  .gy-lg-30 {
    --bs-gutter-y: 14rem;
  }
  .g-lg-31,
  .gx-lg-31 {
    --bs-gutter-x: 15rem;
  }
  .g-lg-31,
  .gy-lg-31 {
    --bs-gutter-y: 15rem;
  }
  .g-lg-32,
  .gx-lg-32 {
    --bs-gutter-x: 16rem;
  }
  .g-lg-32,
  .gy-lg-32 {
    --bs-gutter-y: 16rem;
  }
  .g-lg-33,
  .gx-lg-33 {
    --bs-gutter-x: 17rem;
  }
  .g-lg-33,
  .gy-lg-33 {
    --bs-gutter-y: 17rem;
  }
  .g-lg-34,
  .gx-lg-34 {
    --bs-gutter-x: 18rem;
  }
  .g-lg-34,
  .gy-lg-34 {
    --bs-gutter-y: 18rem;
  }
  .g-lg-35,
  .gx-lg-35 {
    --bs-gutter-x: 19rem;
  }
  .g-lg-35,
  .gy-lg-35 {
    --bs-gutter-y: 19rem;
  }
  .g-lg-36,
  .gx-lg-36 {
    --bs-gutter-x: 20rem;
  }
  .g-lg-36,
  .gy-lg-36 {
    --bs-gutter-y: 20rem;
  }
  .g-lg-37,
  .gx-lg-37 {
    --bs-gutter-x: 21rem;
  }
  .g-lg-37,
  .gy-lg-37 {
    --bs-gutter-y: 21rem;
  }
  .g-lg-38,
  .gx-lg-38 {
    --bs-gutter-x: 22rem;
  }
  .g-lg-38,
  .gy-lg-38 {
    --bs-gutter-y: 22rem;
  }
  .g-lg-39,
  .gx-lg-39 {
    --bs-gutter-x: 23rem;
  }
  .g-lg-39,
  .gy-lg-39 {
    --bs-gutter-y: 23rem;
  }
  .g-lg-40,
  .gx-lg-40 {
    --bs-gutter-x: 24rem;
  }
  .g-lg-40,
  .gy-lg-40 {
    --bs-gutter-y: 24rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.35rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.35rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.55rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.55rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 1.75rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 1.75rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xl-13,
  .gx-xl-13 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-13,
  .gy-xl-13 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-14,
  .gx-xl-14 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xl-14,
  .gy-xl-14 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xl-15,
  .gx-xl-15 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-15,
  .gy-xl-15 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-16,
  .gx-xl-16 {
    --bs-gutter-x: 5.5rem;
  }
  .g-xl-16,
  .gy-xl-16 {
    --bs-gutter-y: 5.5rem;
  }
  .g-xl-17,
  .gx-xl-17 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-17,
  .gy-xl-17 {
    --bs-gutter-y: 6rem;
  }
  .g-xl-18,
  .gx-xl-18 {
    --bs-gutter-x: 6.5rem;
  }
  .g-xl-18,
  .gy-xl-18 {
    --bs-gutter-y: 6.5rem;
  }
  .g-xl-19,
  .gx-xl-19 {
    --bs-gutter-x: 7rem;
  }
  .g-xl-19,
  .gy-xl-19 {
    --bs-gutter-y: 7rem;
  }
  .g-xl-20,
  .gx-xl-20 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xl-20,
  .gy-xl-20 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xl-21,
  .gx-xl-21 {
    --bs-gutter-x: 8rem;
  }
  .g-xl-21,
  .gy-xl-21 {
    --bs-gutter-y: 8rem;
  }
  .g-xl-22,
  .gx-xl-22 {
    --bs-gutter-x: 8.5rem;
  }
  .g-xl-22,
  .gy-xl-22 {
    --bs-gutter-y: 8.5rem;
  }
  .g-xl-23,
  .gx-xl-23 {
    --bs-gutter-x: 9rem;
  }
  .g-xl-23,
  .gy-xl-23 {
    --bs-gutter-y: 9rem;
  }
  .g-xl-24,
  .gx-xl-24 {
    --bs-gutter-x: 9.5rem;
  }
  .g-xl-24,
  .gy-xl-24 {
    --bs-gutter-y: 9.5rem;
  }
  .g-xl-25,
  .gx-xl-25 {
    --bs-gutter-x: 10rem;
  }
  .g-xl-25,
  .gy-xl-25 {
    --bs-gutter-y: 10rem;
  }
  .g-xl-26,
  .gx-xl-26 {
    --bs-gutter-x: 10.5rem;
  }
  .g-xl-26,
  .gy-xl-26 {
    --bs-gutter-y: 10.5rem;
  }
  .g-xl-27,
  .gx-xl-27 {
    --bs-gutter-x: 11rem;
  }
  .g-xl-27,
  .gy-xl-27 {
    --bs-gutter-y: 11rem;
  }
  .g-xl-28,
  .gx-xl-28 {
    --bs-gutter-x: 12rem;
  }
  .g-xl-28,
  .gy-xl-28 {
    --bs-gutter-y: 12rem;
  }
  .g-xl-29,
  .gx-xl-29 {
    --bs-gutter-x: 13rem;
  }
  .g-xl-29,
  .gy-xl-29 {
    --bs-gutter-y: 13rem;
  }
  .g-xl-30,
  .gx-xl-30 {
    --bs-gutter-x: 14rem;
  }
  .g-xl-30,
  .gy-xl-30 {
    --bs-gutter-y: 14rem;
  }
  .g-xl-31,
  .gx-xl-31 {
    --bs-gutter-x: 15rem;
  }
  .g-xl-31,
  .gy-xl-31 {
    --bs-gutter-y: 15rem;
  }
  .g-xl-32,
  .gx-xl-32 {
    --bs-gutter-x: 16rem;
  }
  .g-xl-32,
  .gy-xl-32 {
    --bs-gutter-y: 16rem;
  }
  .g-xl-33,
  .gx-xl-33 {
    --bs-gutter-x: 17rem;
  }
  .g-xl-33,
  .gy-xl-33 {
    --bs-gutter-y: 17rem;
  }
  .g-xl-34,
  .gx-xl-34 {
    --bs-gutter-x: 18rem;
  }
  .g-xl-34,
  .gy-xl-34 {
    --bs-gutter-y: 18rem;
  }
  .g-xl-35,
  .gx-xl-35 {
    --bs-gutter-x: 19rem;
  }
  .g-xl-35,
  .gy-xl-35 {
    --bs-gutter-y: 19rem;
  }
  .g-xl-36,
  .gx-xl-36 {
    --bs-gutter-x: 20rem;
  }
  .g-xl-36,
  .gy-xl-36 {
    --bs-gutter-y: 20rem;
  }
  .g-xl-37,
  .gx-xl-37 {
    --bs-gutter-x: 21rem;
  }
  .g-xl-37,
  .gy-xl-37 {
    --bs-gutter-y: 21rem;
  }
  .g-xl-38,
  .gx-xl-38 {
    --bs-gutter-x: 22rem;
  }
  .g-xl-38,
  .gy-xl-38 {
    --bs-gutter-y: 22rem;
  }
  .g-xl-39,
  .gx-xl-39 {
    --bs-gutter-x: 23rem;
  }
  .g-xl-39,
  .gy-xl-39 {
    --bs-gutter-y: 23rem;
  }
  .g-xl-40,
  .gx-xl-40 {
    --bs-gutter-x: 24rem;
  }
  .g-xl-40,
  .gy-xl-40 {
    --bs-gutter-y: 24rem;
  }
}
@media (min-width: 1380px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.35rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.35rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.55rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.55rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 1.75rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 1.75rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xxl-13,
  .gx-xxl-13 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-13,
  .gy-xxl-13 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-14,
  .gx-xxl-14 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xxl-14,
  .gy-xxl-14 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xxl-15,
  .gx-xxl-15 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-15,
  .gy-xxl-15 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-16,
  .gx-xxl-16 {
    --bs-gutter-x: 5.5rem;
  }
  .g-xxl-16,
  .gy-xxl-16 {
    --bs-gutter-y: 5.5rem;
  }
  .g-xxl-17,
  .gx-xxl-17 {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-17,
  .gy-xxl-17 {
    --bs-gutter-y: 6rem;
  }
  .g-xxl-18,
  .gx-xxl-18 {
    --bs-gutter-x: 6.5rem;
  }
  .g-xxl-18,
  .gy-xxl-18 {
    --bs-gutter-y: 6.5rem;
  }
  .g-xxl-19,
  .gx-xxl-19 {
    --bs-gutter-x: 7rem;
  }
  .g-xxl-19,
  .gy-xxl-19 {
    --bs-gutter-y: 7rem;
  }
  .g-xxl-20,
  .gx-xxl-20 {
    --bs-gutter-x: 7.5rem;
  }
  .g-xxl-20,
  .gy-xxl-20 {
    --bs-gutter-y: 7.5rem;
  }
  .g-xxl-21,
  .gx-xxl-21 {
    --bs-gutter-x: 8rem;
  }
  .g-xxl-21,
  .gy-xxl-21 {
    --bs-gutter-y: 8rem;
  }
  .g-xxl-22,
  .gx-xxl-22 {
    --bs-gutter-x: 8.5rem;
  }
  .g-xxl-22,
  .gy-xxl-22 {
    --bs-gutter-y: 8.5rem;
  }
  .g-xxl-23,
  .gx-xxl-23 {
    --bs-gutter-x: 9rem;
  }
  .g-xxl-23,
  .gy-xxl-23 {
    --bs-gutter-y: 9rem;
  }
  .g-xxl-24,
  .gx-xxl-24 {
    --bs-gutter-x: 9.5rem;
  }
  .g-xxl-24,
  .gy-xxl-24 {
    --bs-gutter-y: 9.5rem;
  }
  .g-xxl-25,
  .gx-xxl-25 {
    --bs-gutter-x: 10rem;
  }
  .g-xxl-25,
  .gy-xxl-25 {
    --bs-gutter-y: 10rem;
  }
  .g-xxl-26,
  .gx-xxl-26 {
    --bs-gutter-x: 10.5rem;
  }
  .g-xxl-26,
  .gy-xxl-26 {
    --bs-gutter-y: 10.5rem;
  }
  .g-xxl-27,
  .gx-xxl-27 {
    --bs-gutter-x: 11rem;
  }
  .g-xxl-27,
  .gy-xxl-27 {
    --bs-gutter-y: 11rem;
  }
  .g-xxl-28,
  .gx-xxl-28 {
    --bs-gutter-x: 12rem;
  }
  .g-xxl-28,
  .gy-xxl-28 {
    --bs-gutter-y: 12rem;
  }
  .g-xxl-29,
  .gx-xxl-29 {
    --bs-gutter-x: 13rem;
  }
  .g-xxl-29,
  .gy-xxl-29 {
    --bs-gutter-y: 13rem;
  }
  .g-xxl-30,
  .gx-xxl-30 {
    --bs-gutter-x: 14rem;
  }
  .g-xxl-30,
  .gy-xxl-30 {
    --bs-gutter-y: 14rem;
  }
  .g-xxl-31,
  .gx-xxl-31 {
    --bs-gutter-x: 15rem;
  }
  .g-xxl-31,
  .gy-xxl-31 {
    --bs-gutter-y: 15rem;
  }
  .g-xxl-32,
  .gx-xxl-32 {
    --bs-gutter-x: 16rem;
  }
  .g-xxl-32,
  .gy-xxl-32 {
    --bs-gutter-y: 16rem;
  }
  .g-xxl-33,
  .gx-xxl-33 {
    --bs-gutter-x: 17rem;
  }
  .g-xxl-33,
  .gy-xxl-33 {
    --bs-gutter-y: 17rem;
  }
  .g-xxl-34,
  .gx-xxl-34 {
    --bs-gutter-x: 18rem;
  }
  .g-xxl-34,
  .gy-xxl-34 {
    --bs-gutter-y: 18rem;
  }
  .g-xxl-35,
  .gx-xxl-35 {
    --bs-gutter-x: 19rem;
  }
  .g-xxl-35,
  .gy-xxl-35 {
    --bs-gutter-y: 19rem;
  }
  .g-xxl-36,
  .gx-xxl-36 {
    --bs-gutter-x: 20rem;
  }
  .g-xxl-36,
  .gy-xxl-36 {
    --bs-gutter-y: 20rem;
  }
  .g-xxl-37,
  .gx-xxl-37 {
    --bs-gutter-x: 21rem;
  }
  .g-xxl-37,
  .gy-xxl-37 {
    --bs-gutter-y: 21rem;
  }
  .g-xxl-38,
  .gx-xxl-38 {
    --bs-gutter-x: 22rem;
  }
  .g-xxl-38,
  .gy-xxl-38 {
    --bs-gutter-y: 22rem;
  }
  .g-xxl-39,
  .gx-xxl-39 {
    --bs-gutter-x: 23rem;
  }
  .g-xxl-39,
  .gy-xxl-39 {
    --bs-gutter-y: 23rem;
  }
  .g-xxl-40,
  .gx-xxl-40 {
    --bs-gutter-x: 24rem;
  }
  .g-xxl-40,
  .gy-xxl-40 {
    --bs-gutter-y: 24rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.35rem 0.35rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #ebe5e0;
  --bs-table-border-color: #d4ceca;
  --bs-table-striped-bg: #dfdad5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d4ceca;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #d9d4cf;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #dbdbdb;
  --bs-table-border-color: #c5c5c5;
  --bs-table-striped-bg: #d0d0d0;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #c5c5c5;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #cbcbcb;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #dde8dd;
  --bs-table-border-color: #c7d1c7;
  --bs-table-striped-bg: #d2dcd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c7d1c7;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #ccd7cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #d5e6ef;
  --bs-table-border-color: #c0cfd7;
  --bs-table-striped-bg: #cadbe3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c0cfd7;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #c5d5dd;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #f5eedd;
  --bs-table-border-color: #ddd6c7;
  --bs-table-striped-bg: #e9e2d2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ddd6c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e3dccc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f3dbdb;
  --bs-table-border-color: #dbc5c5;
  --bs-table-striped-bg: #e7d0d0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dbc5c5;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #e1cbcb;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f4f4f4;
  --bs-table-border-color: gainsboro;
  --bs-table-striped-bg: #e8e8e8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: gainsboro;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e2e2e2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #181818;
  --bs-table-border-color: #2f2f2f;
  --bs-table-striped-bg: #242424;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #2f2f2f;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #292929;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1379.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.55rem + var(--bs-border-width));
  padding-bottom: calc(0.55rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.75rem + var(--bs-border-width));
  padding-bottom: calc(0.75rem + var(--bs-border-width));
  font-size: calc(1.265625rem + 0.1875vw);
}
@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.40625rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.35rem + var(--bs-border-width));
  padding-bottom: calc(0.35rem + var(--bs-border-width));
  font-size: 0.95625rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.85em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.55rem 1rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0;
  transition: border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #cdbeb2;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(155, 124, 100, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.55rem 1rem;
  margin: -0.55rem -1rem;
  margin-inline-end: 1rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.55rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.7rem + calc(var(--bs-border-width) * 2));
  padding: 0.35rem 0.75rem;
  font-size: 0.95625rem;
}
.form-control-sm::file-selector-button {
  padding: 0.35rem 0.75rem;
  margin: -0.35rem -0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.75rem 1.25rem;
  font-size: calc(1.265625rem + 0.1875vw);
}
@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.40625rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.75rem 1.25rem;
  margin: -0.75rem -1.25rem;
  margin-inline-end: 1.25rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.1rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.7rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 1.1rem + calc(var(--bs-border-width) * 2));
  padding: 0.55rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.7rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1.5rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343434' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.55rem 3rem 0.55rem 1rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0;
  transition: border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #cdbeb2;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(155, 124, 100, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  padding-left: 0.75rem;
  font-size: 0.95625rem;
}

.form-select-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  font-size: calc(1.265625rem + 0.1875vw);
}
@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.40625rem;
  }
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dedede' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.6875rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #cdbeb2;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(155, 124, 100, 0.25);
}
.form-check-input:checked {
  background-color: #9B7C64;
  border-color: #9B7C64;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #9B7C64;
  border-color: #9B7C64;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23cdbeb2'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(155, 124, 100, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(155, 124, 100, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #9B7C64;
  border: 0;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #e1d8d1;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #9B7C64;
  border: 0;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #e1d8d1;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.5rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c6c6c;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.55rem 1rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.75rem 1.25rem;
  font-size: calc(1.265625rem + 0.1875vw);
}
@media (min-width: 1200px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    font-size: 1.40625rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.35rem 0.75rem;
  font-size: 0.95625rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.85em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.95625rem;
  color: #fff;
  background-color: var(--bs-success);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 1.1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23548D57' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.275rem) center;
  background-size: calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.1rem);
  background-position: top calc(0.375em + 0.275rem) right calc(0.375em + 0.275rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23548D57' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 5.5rem;
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 1.1rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.85em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.95625rem;
  color: #fff;
  background-color: var(--bs-danger);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 1.1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23C44D4D'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23C44D4D' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.275rem) center;
  background-size: calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.1rem);
  background-position: top calc(0.375em + 0.275rem) right calc(0.375em + 0.275rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23C44D4D'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23C44D4D' stroke='none'/%3e%3c/svg%3e");
  padding-right: 5.5rem;
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 1.1rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1rem;
  --bs-btn-padding-y: 0.55rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.125rem;
  --bs-btn-font-weight: 300;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0 rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  background-color: var(--bs-btn-bg);
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #9B7C64;
  --bs-btn-border-color: #9B7C64;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #846955;
  --bs-btn-hover-border-color: #7c6350;
  --bs-btn-focus-shadow-rgb: 170, 144, 123;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #7c6350;
  --bs-btn-active-border-color: #745d4b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #9B7C64;
  --bs-btn-disabled-border-color: #9B7C64;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #494949;
  --bs-btn-border-color: #494949;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3e3e3e;
  --bs-btn-hover-border-color: #3a3a3a;
  --bs-btn-focus-shadow-rgb: 100, 100, 100;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3a3a3a;
  --bs-btn-active-border-color: #373737;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #494949;
  --bs-btn-disabled-border-color: #494949;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #548D57;
  --bs-btn-border-color: #548D57;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #47784a;
  --bs-btn-hover-border-color: #437146;
  --bs-btn-focus-shadow-rgb: 110, 158, 112;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #437146;
  --bs-btn-active-border-color: #3f6a41;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #548D57;
  --bs-btn-disabled-border-color: #548D57;
}

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2C83B1;
  --bs-btn-border-color: #2C83B1;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #256f96;
  --bs-btn-hover-border-color: #23698e;
  --bs-btn-focus-shadow-rgb: 76, 150, 189;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #23698e;
  --bs-btn-active-border-color: #216285;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2C83B1;
  --bs-btn-disabled-border-color: #2C83B1;
}

.btn-warning {
  --bs-btn-color: #fff;
  --bs-btn-bg: #CCAC57;
  --bs-btn-border-color: #CCAC57;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ad924a;
  --bs-btn-hover-border-color: #a38a46;
  --bs-btn-focus-shadow-rgb: 212, 184, 112;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #a38a46;
  --bs-btn-active-border-color: #998141;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #CCAC57;
  --bs-btn-disabled-border-color: #CCAC57;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #C44D4D;
  --bs-btn-border-color: #C44D4D;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #a74141;
  --bs-btn-hover-border-color: #9d3e3e;
  --bs-btn-focus-shadow-rgb: 205, 104, 104;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #9d3e3e;
  --bs-btn-active-border-color: #933a3a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #C44D4D;
  --bs-btn-disabled-border-color: #C44D4D;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f4f4f4;
  --bs-btn-border-color: #f4f4f4;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cfcfcf;
  --bs-btn-hover-border-color: #c3c3c3;
  --bs-btn-focus-shadow-rgb: 207, 207, 207;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c3c3c3;
  --bs-btn-active-border-color: #b7b7b7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f4f4f4;
  --bs-btn-disabled-border-color: #f4f4f4;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #181818;
  --bs-btn-border-color: #181818;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3b3b3b;
  --bs-btn-hover-border-color: #2f2f2f;
  --bs-btn-focus-shadow-rgb: 59, 59, 59;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #464646;
  --bs-btn-active-border-color: #2f2f2f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #181818;
  --bs-btn-disabled-border-color: #181818;
}

.btn-outline-primary {
  --bs-btn-color: #9B7C64;
  --bs-btn-border-color: #9B7C64;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #9B7C64;
  --bs-btn-hover-border-color: #9B7C64;
  --bs-btn-focus-shadow-rgb: 155, 124, 100;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #9B7C64;
  --bs-btn-active-border-color: #9B7C64;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #9B7C64;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #9B7C64;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #494949;
  --bs-btn-border-color: #494949;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #494949;
  --bs-btn-hover-border-color: #494949;
  --bs-btn-focus-shadow-rgb: 73, 73, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #494949;
  --bs-btn-active-border-color: #494949;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #494949;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #494949;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #548D57;
  --bs-btn-border-color: #548D57;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #548D57;
  --bs-btn-hover-border-color: #548D57;
  --bs-btn-focus-shadow-rgb: 84, 141, 87;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #548D57;
  --bs-btn-active-border-color: #548D57;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #548D57;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #548D57;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #2C83B1;
  --bs-btn-border-color: #2C83B1;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2C83B1;
  --bs-btn-hover-border-color: #2C83B1;
  --bs-btn-focus-shadow-rgb: 44, 131, 177;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2C83B1;
  --bs-btn-active-border-color: #2C83B1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2C83B1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2C83B1;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #CCAC57;
  --bs-btn-border-color: #CCAC57;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #CCAC57;
  --bs-btn-hover-border-color: #CCAC57;
  --bs-btn-focus-shadow-rgb: 204, 172, 87;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #CCAC57;
  --bs-btn-active-border-color: #CCAC57;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #CCAC57;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #CCAC57;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #C44D4D;
  --bs-btn-border-color: #C44D4D;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #C44D4D;
  --bs-btn-hover-border-color: #C44D4D;
  --bs-btn-focus-shadow-rgb: 196, 77, 77;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #C44D4D;
  --bs-btn-active-border-color: #C44D4D;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #C44D4D;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #C44D4D;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f4f4f4;
  --bs-btn-border-color: #f4f4f4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f4f4f4;
  --bs-btn-hover-border-color: #f4f4f4;
  --bs-btn-focus-shadow-rgb: 244, 244, 244;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f4f4f4;
  --bs-btn-active-border-color: #f4f4f4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f4f4f4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f4f4f4;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #181818;
  --bs-btn-border-color: #181818;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #181818;
  --bs-btn-hover-border-color: #181818;
  --bs-btn-focus-shadow-rgb: 24, 24, 24;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #181818;
  --bs-btn-active-border-color: #181818;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #181818;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #181818;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 300;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c6c6c;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 170, 144, 123;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-font-size: calc(1.265625rem + 0.1875vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .btn-lg {
    --bs-btn-font-size: 1.40625rem;
  }
}

.btn-sm {
  --bs-btn-padding-y: 0.35rem;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-font-size: 0.95625rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

/* Video play button */
.btn-video-play {
  --bs-btn-video-play-color: var(--bs-primary);
  --bs-btn-video-play-size: 64px;
  --bs-btn-video-play-size-md: 64px;
  --bs-btn-video-play-size-sm: 48px;
  --bs-btn-video-play-size-lg: 80px;
  --bs-btn-video-play-border-size: 16px;
  --bs-btn-video-play-border-size-md: 16px;
  --bs-btn-video-play-border-size-sm: 12px;
  --bs-btn-video-play-border-size-lg: 20px;
  --bs-btn-video-play-animation: pulse-border 1500ms ease-out infinite;
  --bs-btn-video-play-transition: all 0.35s ease-in-out;
  padding-left: 5px;
  position: relative;
  box-sizing: content-box;
  display: inline-flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: calc(var(--bs-btn-video-play-size) + 10px);
  height: calc(var(--bs-btn-video-play-size) + 10px);
}
.btn-video-play:before, .btn-video-play:after {
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  content: "";
  display: inline-block;
  width: var(--bs-btn-video-play-size);
  height: var(--bs-btn-video-play-size);
  border-radius: 50%;
  transition: var(--bs-btn-video-play-transition);
  background: var(--bs-btn-video-play-color);
}
.btn-video-play:before {
  animation: var(--bs-btn-video-play-animation);
}
.btn-video-play:hover:before, .btn-video-play:hover:after {
  opacity: 0.55;
}
.btn-video-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: var(--bs-btn-video-play-border-size) solid #fff;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.btn-video-play.btn-video-play-sm:before, .btn-video-play.btn-video-play-sm:after {
  width: var(--bs-btn-video-play-size-sm);
  height: var(--bs-btn-video-play-size-sm);
}
.btn-video-play.btn-video-play-sm {
  width: calc(var(--bs-btn-video-play-size-sm) + 4px + 4px);
  height: calc(var(--bs-btn-video-play-size-sm) + 4px + 4px);
}
.btn-video-play.btn-video-play-sm span {
  border-left: var(--bs-btn-video-play-border-size-sm) solid #fff;
  border-top: calc(var(--bs-btn-video-play-border-size-sm) - 4px) solid transparent;
  border-bottom: calc(var(--bs-btn-video-play-border-size-sm) - 4px) solid transparent;
}
.btn-video-play.btn-video-play-md:before, .btn-video-play.btn-video-play-md:after {
  width: var(--bs-btn-video-play-size-md);
  height: var(--bs-btn-video-play-size-md);
}
.btn-video-play.btn-video-play-md {
  width: calc(var(--bs-btn-video-play-size-md) + 6px + 4px);
  height: calc(var(--bs-btn-video-play-size-md) + 6px + 4px);
}
.btn-video-play.btn-video-play-md span {
  border-left: var(--bs-btn-video-play-border-size-md) solid #fff;
  border-top: calc(var(--bs-btn-video-play-border-size-md) - 6px) solid transparent;
  border-bottom: calc(var(--bs-btn-video-play-border-size-md) - 6px) solid transparent;
}
.btn-video-play.btn-video-play-lg:before, .btn-video-play.btn-video-play-lg:after {
  width: var(--bs-btn-video-play-size-lg);
  height: var(--bs-btn-video-play-size-lg);
}
.btn-video-play.btn-video-play-lg {
  width: calc(var(--bs-btn-video-play-size-lg) + 8px + 4px);
  height: calc(var(--bs-btn-video-play-size-lg) + 8px + 4px);
}
.btn-video-play.btn-video-play-lg span {
  border-left: var(--bs-btn-video-play-border-size-lg) solid #fff;
  border-top: calc(var(--bs-btn-video-play-border-size-lg) - 8px) solid transparent;
  border-bottom: calc(var(--bs-btn-video-play-border-size-lg) - 8px) solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.fade {
  transition: opacity 0.2s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.4s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.4s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 12rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 1rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1.125rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #9B7C64;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1.25rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c6c6c;
  --bs-dropdown-header-padding-x: 1.25rem;
  --bs-dropdown-header-padding-y: 1rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1380px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 300;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.95625rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dedede;
  --bs-dropdown-bg: #343434;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dedede;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #9B7C64;
  --bs-dropdown-link-disabled-color: #adadad;
  --bs-dropdown-header-color: #adadad;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(155, 124, 100, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #9B7C64;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 600;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.2890625rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.40625rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.65rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.40625rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2824, 24, 24, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1380px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: 1rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.75rem;
  --bs-card-cap-padding-x: 1.5rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1.5rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
}

.card {
  --bs-card-overlay-bottom-bg-gradient: linear-gradient(0deg, rgb(22, 21, 20) 0%, rgba(22, 21, 20, 0) 100%);
  --bs-card-content-hover-bg-color: #494949;
  --bs-card-float-bottom-margin-top: -1.5rem;
  --bs-card-float-bottom-margin-right: 1rem;
  --bs-card-float-bottom-margin-left: 1rem;
  --bs-card-float-side-pading-x: 20%;
  --bs-card-float-side-content-width: 40%;
  --bs-card-float-side-content-margin-top: 3rem;
  --bs-card-float-side-content-zindex: 3;
  /* Card Overlay Bootom */
  /* Card Content Hover */
  /* Card Float Bootom */
  /* Card Float Side */
}
.card:hover figure.image-hover-scale img {
  transform: scale(1.055);
  -webkit-transform: scale(1.055);
  -moz-transform: scale(1.055);
  -ms-transform: scale(1.055);
  -o-transform: scale(1.055);
}
.card:hover figure.image-hover-overlay::after {
  opacity: 1;
}
.card:hover figure .image-hover-icon {
  opacity: 1;
}
.card.card-overlay-bottom .card-img-overlay {
  top: unset;
  background: var(--bs-card-overlay-bottom-bg-gradient);
}
.card.card-content-hover .card-body {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}
.card.card-content-hover:hover .card-body {
  background-color: var(--bs-card-content-hover-bg-color) !important;
}
.card.card-content-hover:hover .card-body *:not(.btn) {
  color: var(--bs-white);
}
.card.card-float-bottom .card-body {
  margin-top: var(--bs-card-float-bottom-margin-top);
  margin-right: var(--bs-card-float-bottom-margin-right);
  margin-left: var(--bs-card-float-bottom-margin-left);
  position: relative;
  z-index: 3;
}
@media (min-width: 992px) {
  .card.card-float-side {
    padding-right: var(--bs-card-float-side-pading-x);
  }
  .card.card-float-side .card-body {
    position: absolute;
    top: 3rem;
    right: 0;
    width: var(--bs-card-float-side-content-width);
    z-index: 3;
  }
  .card.card-float-side.card-float-side-right {
    padding-right: var(--bs-card-float-side-pading-x);
    padding-left: unset;
  }
  .card.card-float-side.card-float-side-right .card-body {
    right: 0;
    left: unset;
  }
  .card.card-float-side.card-float-side-left {
    padding-right: unset;
    padding-left: var(--bs-card-float-side-pading-x);
  }
  .card.card-float-side.card-float-side-left .card-body {
    right: unset;
    left: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out, border-radius 0.25s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.5rem;
  --bs-accordion-btn-padding-y: 1.25rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23181818'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.25s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233e3228'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #cdbeb2;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0 rgba(155, 124, 100, 0.25);
  --bs-accordion-body-padding-x: 1.5rem;
  --bs-accordion-body-padding-y: 1.25rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1.125rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c3b0a2'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c3b0a2'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 400;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1.5rem;
  --bs-alert-padding-y: 1.25rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 4.5rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.5625rem 1.5rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.84375rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #9B7C64;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #9B7C64;
  --bs-list-group-active-border-color: #9B7C64;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1380px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0 rgba(155, 124, 100, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1.5rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1.5rem;
  --bs-modal-header-padding-y: 1.5rem;
  --bs-modal-header-padding: 1.5rem 1.5rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1379.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 0%;
  bottom: 0;
  left: 0%;
  padding-top: 0;
  padding-bottom: 0;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(0) grayscale(0);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #fff;
}
.carousel-dark .carousel-caption {
  color: #fff;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(0) grayscale(0);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #fff;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #fff;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1.5rem;
  --bs-offcanvas-padding-y: 1.5rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.35s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1379.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1379.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1379.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1379.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1379.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1379.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1379.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1379.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1380px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
} /* END LAYOUT & COMPONENTS */
/*-----------------------------------------------------------*/
/* START HELPERS */
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(124, 99, 80, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(124, 99, 80, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(58, 58, 58, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(58, 58, 58, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(67, 113, 70, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(67, 113, 70, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(35, 105, 142, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(35, 105, 142, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(163, 138, 70, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(163, 138, 70, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(157, 62, 62, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(157, 62, 62, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(246, 246, 246, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(246, 246, 246, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(19, 19, 19, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(19, 19, 19, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1380px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.15;
}

/* END HELPERS */
/*-----------------------------------------------------------*/
/* START UTILITIES */
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.35rem !important;
}

.m-3 {
  margin: 0.55rem !important;
}

.m-4 {
  margin: 0.75rem !important;
}

.m-5 {
  margin: 1rem !important;
}

.m-6 {
  margin: 1.25rem !important;
}

.m-7 {
  margin: 1.5rem !important;
}

.m-8 {
  margin: 1.75rem !important;
}

.m-9 {
  margin: 2rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-11 {
  margin: 3rem !important;
}

.m-12 {
  margin: 3.5rem !important;
}

.m-13 {
  margin: 4rem !important;
}

.m-14 {
  margin: 4.5rem !important;
}

.m-15 {
  margin: 5rem !important;
}

.m-16 {
  margin: 5.5rem !important;
}

.m-17 {
  margin: 6rem !important;
}

.m-18 {
  margin: 6.5rem !important;
}

.m-19 {
  margin: 7rem !important;
}

.m-20 {
  margin: 7.5rem !important;
}

.m-21 {
  margin: 8rem !important;
}

.m-22 {
  margin: 8.5rem !important;
}

.m-23 {
  margin: 9rem !important;
}

.m-24 {
  margin: 9.5rem !important;
}

.m-25 {
  margin: 10rem !important;
}

.m-26 {
  margin: 10.5rem !important;
}

.m-27 {
  margin: 11rem !important;
}

.m-28 {
  margin: 12rem !important;
}

.m-29 {
  margin: 13rem !important;
}

.m-30 {
  margin: 14rem !important;
}

.m-31 {
  margin: 15rem !important;
}

.m-32 {
  margin: 16rem !important;
}

.m-33 {
  margin: 17rem !important;
}

.m-34 {
  margin: 18rem !important;
}

.m-35 {
  margin: 19rem !important;
}

.m-36 {
  margin: 20rem !important;
}

.m-37 {
  margin: 21rem !important;
}

.m-38 {
  margin: 22rem !important;
}

.m-39 {
  margin: 23rem !important;
}

.m-40 {
  margin: 24rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.35rem !important;
  margin-left: 0.35rem !important;
}

.mx-3 {
  margin-right: 0.55rem !important;
  margin-left: 0.55rem !important;
}

.mx-4 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-5 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-6 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-7 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-8 {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}

.mx-9 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-10 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-11 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-12 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-13 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-14 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-15 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-16 {
  margin-right: 5.5rem !important;
  margin-left: 5.5rem !important;
}

.mx-17 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-18 {
  margin-right: 6.5rem !important;
  margin-left: 6.5rem !important;
}

.mx-19 {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-20 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important;
}

.mx-21 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-22 {
  margin-right: 8.5rem !important;
  margin-left: 8.5rem !important;
}

.mx-23 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-24 {
  margin-right: 9.5rem !important;
  margin-left: 9.5rem !important;
}

.mx-25 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-26 {
  margin-right: 10.5rem !important;
  margin-left: 10.5rem !important;
}

.mx-27 {
  margin-right: 11rem !important;
  margin-left: 11rem !important;
}

.mx-28 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.mx-29 {
  margin-right: 13rem !important;
  margin-left: 13rem !important;
}

.mx-30 {
  margin-right: 14rem !important;
  margin-left: 14rem !important;
}

.mx-31 {
  margin-right: 15rem !important;
  margin-left: 15rem !important;
}

.mx-32 {
  margin-right: 16rem !important;
  margin-left: 16rem !important;
}

.mx-33 {
  margin-right: 17rem !important;
  margin-left: 17rem !important;
}

.mx-34 {
  margin-right: 18rem !important;
  margin-left: 18rem !important;
}

.mx-35 {
  margin-right: 19rem !important;
  margin-left: 19rem !important;
}

.mx-36 {
  margin-right: 20rem !important;
  margin-left: 20rem !important;
}

.mx-37 {
  margin-right: 21rem !important;
  margin-left: 21rem !important;
}

.mx-38 {
  margin-right: 22rem !important;
  margin-left: 22rem !important;
}

.mx-39 {
  margin-right: 23rem !important;
  margin-left: 23rem !important;
}

.mx-40 {
  margin-right: 24rem !important;
  margin-left: 24rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.35rem !important;
  margin-bottom: 0.35rem !important;
}

.my-3 {
  margin-top: 0.55rem !important;
  margin-bottom: 0.55rem !important;
}

.my-4 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-5 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-6 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-7 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-8 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-9 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-11 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-12 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-13 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-14 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-15 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-16 {
  margin-top: 5.5rem !important;
  margin-bottom: 5.5rem !important;
}

.my-17 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-18 {
  margin-top: 6.5rem !important;
  margin-bottom: 6.5rem !important;
}

.my-19 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-20 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.my-21 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-22 {
  margin-top: 8.5rem !important;
  margin-bottom: 8.5rem !important;
}

.my-23 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-24 {
  margin-top: 9.5rem !important;
  margin-bottom: 9.5rem !important;
}

.my-25 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-26 {
  margin-top: 10.5rem !important;
  margin-bottom: 10.5rem !important;
}

.my-27 {
  margin-top: 11rem !important;
  margin-bottom: 11rem !important;
}

.my-28 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-29 {
  margin-top: 13rem !important;
  margin-bottom: 13rem !important;
}

.my-30 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.my-31 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.my-32 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.my-33 {
  margin-top: 17rem !important;
  margin-bottom: 17rem !important;
}

.my-34 {
  margin-top: 18rem !important;
  margin-bottom: 18rem !important;
}

.my-35 {
  margin-top: 19rem !important;
  margin-bottom: 19rem !important;
}

.my-36 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.my-37 {
  margin-top: 21rem !important;
  margin-bottom: 21rem !important;
}

.my-38 {
  margin-top: 22rem !important;
  margin-bottom: 22rem !important;
}

.my-39 {
  margin-top: 23rem !important;
  margin-bottom: 23rem !important;
}

.my-40 {
  margin-top: 24rem !important;
  margin-bottom: 24rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.35rem !important;
}

.mt-3 {
  margin-top: 0.55rem !important;
}

.mt-4 {
  margin-top: 0.75rem !important;
}

.mt-5 {
  margin-top: 1rem !important;
}

.mt-6 {
  margin-top: 1.25rem !important;
}

.mt-7 {
  margin-top: 1.5rem !important;
}

.mt-8 {
  margin-top: 1.75rem !important;
}

.mt-9 {
  margin-top: 2rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-11 {
  margin-top: 3rem !important;
}

.mt-12 {
  margin-top: 3.5rem !important;
}

.mt-13 {
  margin-top: 4rem !important;
}

.mt-14 {
  margin-top: 4.5rem !important;
}

.mt-15 {
  margin-top: 5rem !important;
}

.mt-16 {
  margin-top: 5.5rem !important;
}

.mt-17 {
  margin-top: 6rem !important;
}

.mt-18 {
  margin-top: 6.5rem !important;
}

.mt-19 {
  margin-top: 7rem !important;
}

.mt-20 {
  margin-top: 7.5rem !important;
}

.mt-21 {
  margin-top: 8rem !important;
}

.mt-22 {
  margin-top: 8.5rem !important;
}

.mt-23 {
  margin-top: 9rem !important;
}

.mt-24 {
  margin-top: 9.5rem !important;
}

.mt-25 {
  margin-top: 10rem !important;
}

.mt-26 {
  margin-top: 10.5rem !important;
}

.mt-27 {
  margin-top: 11rem !important;
}

.mt-28 {
  margin-top: 12rem !important;
}

.mt-29 {
  margin-top: 13rem !important;
}

.mt-30 {
  margin-top: 14rem !important;
}

.mt-31 {
  margin-top: 15rem !important;
}

.mt-32 {
  margin-top: 16rem !important;
}

.mt-33 {
  margin-top: 17rem !important;
}

.mt-34 {
  margin-top: 18rem !important;
}

.mt-35 {
  margin-top: 19rem !important;
}

.mt-36 {
  margin-top: 20rem !important;
}

.mt-37 {
  margin-top: 21rem !important;
}

.mt-38 {
  margin-top: 22rem !important;
}

.mt-39 {
  margin-top: 23rem !important;
}

.mt-40 {
  margin-top: 24rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.35rem !important;
}

.me-3 {
  margin-right: 0.55rem !important;
}

.me-4 {
  margin-right: 0.75rem !important;
}

.me-5 {
  margin-right: 1rem !important;
}

.me-6 {
  margin-right: 1.25rem !important;
}

.me-7 {
  margin-right: 1.5rem !important;
}

.me-8 {
  margin-right: 1.75rem !important;
}

.me-9 {
  margin-right: 2rem !important;
}

.me-10 {
  margin-right: 2.5rem !important;
}

.me-11 {
  margin-right: 3rem !important;
}

.me-12 {
  margin-right: 3.5rem !important;
}

.me-13 {
  margin-right: 4rem !important;
}

.me-14 {
  margin-right: 4.5rem !important;
}

.me-15 {
  margin-right: 5rem !important;
}

.me-16 {
  margin-right: 5.5rem !important;
}

.me-17 {
  margin-right: 6rem !important;
}

.me-18 {
  margin-right: 6.5rem !important;
}

.me-19 {
  margin-right: 7rem !important;
}

.me-20 {
  margin-right: 7.5rem !important;
}

.me-21 {
  margin-right: 8rem !important;
}

.me-22 {
  margin-right: 8.5rem !important;
}

.me-23 {
  margin-right: 9rem !important;
}

.me-24 {
  margin-right: 9.5rem !important;
}

.me-25 {
  margin-right: 10rem !important;
}

.me-26 {
  margin-right: 10.5rem !important;
}

.me-27 {
  margin-right: 11rem !important;
}

.me-28 {
  margin-right: 12rem !important;
}

.me-29 {
  margin-right: 13rem !important;
}

.me-30 {
  margin-right: 14rem !important;
}

.me-31 {
  margin-right: 15rem !important;
}

.me-32 {
  margin-right: 16rem !important;
}

.me-33 {
  margin-right: 17rem !important;
}

.me-34 {
  margin-right: 18rem !important;
}

.me-35 {
  margin-right: 19rem !important;
}

.me-36 {
  margin-right: 20rem !important;
}

.me-37 {
  margin-right: 21rem !important;
}

.me-38 {
  margin-right: 22rem !important;
}

.me-39 {
  margin-right: 23rem !important;
}

.me-40 {
  margin-right: 24rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.35rem !important;
}

.mb-3 {
  margin-bottom: 0.55rem !important;
}

.mb-4 {
  margin-bottom: 0.75rem !important;
}

.mb-5 {
  margin-bottom: 1rem !important;
}

.mb-6 {
  margin-bottom: 1.25rem !important;
}

.mb-7 {
  margin-bottom: 1.5rem !important;
}

.mb-8 {
  margin-bottom: 1.75rem !important;
}

.mb-9 {
  margin-bottom: 2rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-11 {
  margin-bottom: 3rem !important;
}

.mb-12 {
  margin-bottom: 3.5rem !important;
}

.mb-13 {
  margin-bottom: 4rem !important;
}

.mb-14 {
  margin-bottom: 4.5rem !important;
}

.mb-15 {
  margin-bottom: 5rem !important;
}

.mb-16 {
  margin-bottom: 5.5rem !important;
}

.mb-17 {
  margin-bottom: 6rem !important;
}

.mb-18 {
  margin-bottom: 6.5rem !important;
}

.mb-19 {
  margin-bottom: 7rem !important;
}

.mb-20 {
  margin-bottom: 7.5rem !important;
}

.mb-21 {
  margin-bottom: 8rem !important;
}

.mb-22 {
  margin-bottom: 8.5rem !important;
}

.mb-23 {
  margin-bottom: 9rem !important;
}

.mb-24 {
  margin-bottom: 9.5rem !important;
}

.mb-25 {
  margin-bottom: 10rem !important;
}

.mb-26 {
  margin-bottom: 10.5rem !important;
}

.mb-27 {
  margin-bottom: 11rem !important;
}

.mb-28 {
  margin-bottom: 12rem !important;
}

.mb-29 {
  margin-bottom: 13rem !important;
}

.mb-30 {
  margin-bottom: 14rem !important;
}

.mb-31 {
  margin-bottom: 15rem !important;
}

.mb-32 {
  margin-bottom: 16rem !important;
}

.mb-33 {
  margin-bottom: 17rem !important;
}

.mb-34 {
  margin-bottom: 18rem !important;
}

.mb-35 {
  margin-bottom: 19rem !important;
}

.mb-36 {
  margin-bottom: 20rem !important;
}

.mb-37 {
  margin-bottom: 21rem !important;
}

.mb-38 {
  margin-bottom: 22rem !important;
}

.mb-39 {
  margin-bottom: 23rem !important;
}

.mb-40 {
  margin-bottom: 24rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.35rem !important;
}

.ms-3 {
  margin-left: 0.55rem !important;
}

.ms-4 {
  margin-left: 0.75rem !important;
}

.ms-5 {
  margin-left: 1rem !important;
}

.ms-6 {
  margin-left: 1.25rem !important;
}

.ms-7 {
  margin-left: 1.5rem !important;
}

.ms-8 {
  margin-left: 1.75rem !important;
}

.ms-9 {
  margin-left: 2rem !important;
}

.ms-10 {
  margin-left: 2.5rem !important;
}

.ms-11 {
  margin-left: 3rem !important;
}

.ms-12 {
  margin-left: 3.5rem !important;
}

.ms-13 {
  margin-left: 4rem !important;
}

.ms-14 {
  margin-left: 4.5rem !important;
}

.ms-15 {
  margin-left: 5rem !important;
}

.ms-16 {
  margin-left: 5.5rem !important;
}

.ms-17 {
  margin-left: 6rem !important;
}

.ms-18 {
  margin-left: 6.5rem !important;
}

.ms-19 {
  margin-left: 7rem !important;
}

.ms-20 {
  margin-left: 7.5rem !important;
}

.ms-21 {
  margin-left: 8rem !important;
}

.ms-22 {
  margin-left: 8.5rem !important;
}

.ms-23 {
  margin-left: 9rem !important;
}

.ms-24 {
  margin-left: 9.5rem !important;
}

.ms-25 {
  margin-left: 10rem !important;
}

.ms-26 {
  margin-left: 10.5rem !important;
}

.ms-27 {
  margin-left: 11rem !important;
}

.ms-28 {
  margin-left: 12rem !important;
}

.ms-29 {
  margin-left: 13rem !important;
}

.ms-30 {
  margin-left: 14rem !important;
}

.ms-31 {
  margin-left: 15rem !important;
}

.ms-32 {
  margin-left: 16rem !important;
}

.ms-33 {
  margin-left: 17rem !important;
}

.ms-34 {
  margin-left: 18rem !important;
}

.ms-35 {
  margin-left: 19rem !important;
}

.ms-36 {
  margin-left: 20rem !important;
}

.ms-37 {
  margin-left: 21rem !important;
}

.ms-38 {
  margin-left: 22rem !important;
}

.ms-39 {
  margin-left: 23rem !important;
}

.ms-40 {
  margin-left: 24rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.35rem !important;
}

.p-3 {
  padding: 0.55rem !important;
}

.p-4 {
  padding: 0.75rem !important;
}

.p-5 {
  padding: 1rem !important;
}

.p-6 {
  padding: 1.25rem !important;
}

.p-7 {
  padding: 1.5rem !important;
}

.p-8 {
  padding: 1.75rem !important;
}

.p-9 {
  padding: 2rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-11 {
  padding: 3rem !important;
}

.p-12 {
  padding: 3.5rem !important;
}

.p-13 {
  padding: 4rem !important;
}

.p-14 {
  padding: 4.5rem !important;
}

.p-15 {
  padding: 5rem !important;
}

.p-16 {
  padding: 5.5rem !important;
}

.p-17 {
  padding: 6rem !important;
}

.p-18 {
  padding: 6.5rem !important;
}

.p-19 {
  padding: 7rem !important;
}

.p-20 {
  padding: 7.5rem !important;
}

.p-21 {
  padding: 8rem !important;
}

.p-22 {
  padding: 8.5rem !important;
}

.p-23 {
  padding: 9rem !important;
}

.p-24 {
  padding: 9.5rem !important;
}

.p-25 {
  padding: 10rem !important;
}

.p-26 {
  padding: 10.5rem !important;
}

.p-27 {
  padding: 11rem !important;
}

.p-28 {
  padding: 12rem !important;
}

.p-29 {
  padding: 13rem !important;
}

.p-30 {
  padding: 14rem !important;
}

.p-31 {
  padding: 15rem !important;
}

.p-32 {
  padding: 16rem !important;
}

.p-33 {
  padding: 17rem !important;
}

.p-34 {
  padding: 18rem !important;
}

.p-35 {
  padding: 19rem !important;
}

.p-36 {
  padding: 20rem !important;
}

.p-37 {
  padding: 21rem !important;
}

.p-38 {
  padding: 22rem !important;
}

.p-39 {
  padding: 23rem !important;
}

.p-40 {
  padding: 24rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.35rem !important;
  padding-left: 0.35rem !important;
}

.px-3 {
  padding-right: 0.55rem !important;
  padding-left: 0.55rem !important;
}

.px-4 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-5 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-6 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-7 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-8 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}

.px-9 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-11 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-12 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-13 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-14 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-15 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-16 {
  padding-right: 5.5rem !important;
  padding-left: 5.5rem !important;
}

.px-17 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-18 {
  padding-right: 6.5rem !important;
  padding-left: 6.5rem !important;
}

.px-19 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.px-20 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important;
}

.px-21 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-22 {
  padding-right: 8.5rem !important;
  padding-left: 8.5rem !important;
}

.px-23 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.px-24 {
  padding-right: 9.5rem !important;
  padding-left: 9.5rem !important;
}

.px-25 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-26 {
  padding-right: 10.5rem !important;
  padding-left: 10.5rem !important;
}

.px-27 {
  padding-right: 11rem !important;
  padding-left: 11rem !important;
}

.px-28 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.px-29 {
  padding-right: 13rem !important;
  padding-left: 13rem !important;
}

.px-30 {
  padding-right: 14rem !important;
  padding-left: 14rem !important;
}

.px-31 {
  padding-right: 15rem !important;
  padding-left: 15rem !important;
}

.px-32 {
  padding-right: 16rem !important;
  padding-left: 16rem !important;
}

.px-33 {
  padding-right: 17rem !important;
  padding-left: 17rem !important;
}

.px-34 {
  padding-right: 18rem !important;
  padding-left: 18rem !important;
}

.px-35 {
  padding-right: 19rem !important;
  padding-left: 19rem !important;
}

.px-36 {
  padding-right: 20rem !important;
  padding-left: 20rem !important;
}

.px-37 {
  padding-right: 21rem !important;
  padding-left: 21rem !important;
}

.px-38 {
  padding-right: 22rem !important;
  padding-left: 22rem !important;
}

.px-39 {
  padding-right: 23rem !important;
  padding-left: 23rem !important;
}

.px-40 {
  padding-right: 24rem !important;
  padding-left: 24rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.35rem !important;
  padding-bottom: 0.35rem !important;
}

.py-3 {
  padding-top: 0.55rem !important;
  padding-bottom: 0.55rem !important;
}

.py-4 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-5 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-6 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-7 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-8 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-9 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-11 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-12 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-13 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-14 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-15 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-16 {
  padding-top: 5.5rem !important;
  padding-bottom: 5.5rem !important;
}

.py-17 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-18 {
  padding-top: 6.5rem !important;
  padding-bottom: 6.5rem !important;
}

.py-19 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-20 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.py-21 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-22 {
  padding-top: 8.5rem !important;
  padding-bottom: 8.5rem !important;
}

.py-23 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-24 {
  padding-top: 9.5rem !important;
  padding-bottom: 9.5rem !important;
}

.py-25 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-26 {
  padding-top: 10.5rem !important;
  padding-bottom: 10.5rem !important;
}

.py-27 {
  padding-top: 11rem !important;
  padding-bottom: 11rem !important;
}

.py-28 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.py-29 {
  padding-top: 13rem !important;
  padding-bottom: 13rem !important;
}

.py-30 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.py-31 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.py-32 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.py-33 {
  padding-top: 17rem !important;
  padding-bottom: 17rem !important;
}

.py-34 {
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}

.py-35 {
  padding-top: 19rem !important;
  padding-bottom: 19rem !important;
}

.py-36 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.py-37 {
  padding-top: 21rem !important;
  padding-bottom: 21rem !important;
}

.py-38 {
  padding-top: 22rem !important;
  padding-bottom: 22rem !important;
}

.py-39 {
  padding-top: 23rem !important;
  padding-bottom: 23rem !important;
}

.py-40 {
  padding-top: 24rem !important;
  padding-bottom: 24rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.35rem !important;
}

.pt-3 {
  padding-top: 0.55rem !important;
}

.pt-4 {
  padding-top: 0.75rem !important;
}

.pt-5 {
  padding-top: 1rem !important;
}

.pt-6 {
  padding-top: 1.25rem !important;
}

.pt-7 {
  padding-top: 1.5rem !important;
}

.pt-8 {
  padding-top: 1.75rem !important;
}

.pt-9 {
  padding-top: 2rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-11 {
  padding-top: 3rem !important;
}

.pt-12 {
  padding-top: 3.5rem !important;
}

.pt-13 {
  padding-top: 4rem !important;
}

.pt-14 {
  padding-top: 4.5rem !important;
}

.pt-15 {
  padding-top: 5rem !important;
}

.pt-16 {
  padding-top: 5.5rem !important;
}

.pt-17 {
  padding-top: 6rem !important;
}

.pt-18 {
  padding-top: 6.5rem !important;
}

.pt-19 {
  padding-top: 7rem !important;
}

.pt-20 {
  padding-top: 7.5rem !important;
}

.pt-21 {
  padding-top: 8rem !important;
}

.pt-22 {
  padding-top: 8.5rem !important;
}

.pt-23 {
  padding-top: 9rem !important;
}

.pt-24 {
  padding-top: 9.5rem !important;
}

.pt-25 {
  padding-top: 10rem !important;
}

.pt-26 {
  padding-top: 10.5rem !important;
}

.pt-27 {
  padding-top: 11rem !important;
}

.pt-28 {
  padding-top: 12rem !important;
}

.pt-29 {
  padding-top: 13rem !important;
}

.pt-30 {
  padding-top: 14rem !important;
}

.pt-31 {
  padding-top: 15rem !important;
}

.pt-32 {
  padding-top: 16rem !important;
}

.pt-33 {
  padding-top: 17rem !important;
}

.pt-34 {
  padding-top: 18rem !important;
}

.pt-35 {
  padding-top: 19rem !important;
}

.pt-36 {
  padding-top: 20rem !important;
}

.pt-37 {
  padding-top: 21rem !important;
}

.pt-38 {
  padding-top: 22rem !important;
}

.pt-39 {
  padding-top: 23rem !important;
}

.pt-40 {
  padding-top: 24rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.35rem !important;
}

.pe-3 {
  padding-right: 0.55rem !important;
}

.pe-4 {
  padding-right: 0.75rem !important;
}

.pe-5 {
  padding-right: 1rem !important;
}

.pe-6 {
  padding-right: 1.25rem !important;
}

.pe-7 {
  padding-right: 1.5rem !important;
}

.pe-8 {
  padding-right: 1.75rem !important;
}

.pe-9 {
  padding-right: 2rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

.pe-11 {
  padding-right: 3rem !important;
}

.pe-12 {
  padding-right: 3.5rem !important;
}

.pe-13 {
  padding-right: 4rem !important;
}

.pe-14 {
  padding-right: 4.5rem !important;
}

.pe-15 {
  padding-right: 5rem !important;
}

.pe-16 {
  padding-right: 5.5rem !important;
}

.pe-17 {
  padding-right: 6rem !important;
}

.pe-18 {
  padding-right: 6.5rem !important;
}

.pe-19 {
  padding-right: 7rem !important;
}

.pe-20 {
  padding-right: 7.5rem !important;
}

.pe-21 {
  padding-right: 8rem !important;
}

.pe-22 {
  padding-right: 8.5rem !important;
}

.pe-23 {
  padding-right: 9rem !important;
}

.pe-24 {
  padding-right: 9.5rem !important;
}

.pe-25 {
  padding-right: 10rem !important;
}

.pe-26 {
  padding-right: 10.5rem !important;
}

.pe-27 {
  padding-right: 11rem !important;
}

.pe-28 {
  padding-right: 12rem !important;
}

.pe-29 {
  padding-right: 13rem !important;
}

.pe-30 {
  padding-right: 14rem !important;
}

.pe-31 {
  padding-right: 15rem !important;
}

.pe-32 {
  padding-right: 16rem !important;
}

.pe-33 {
  padding-right: 17rem !important;
}

.pe-34 {
  padding-right: 18rem !important;
}

.pe-35 {
  padding-right: 19rem !important;
}

.pe-36 {
  padding-right: 20rem !important;
}

.pe-37 {
  padding-right: 21rem !important;
}

.pe-38 {
  padding-right: 22rem !important;
}

.pe-39 {
  padding-right: 23rem !important;
}

.pe-40 {
  padding-right: 24rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.35rem !important;
}

.pb-3 {
  padding-bottom: 0.55rem !important;
}

.pb-4 {
  padding-bottom: 0.75rem !important;
}

.pb-5 {
  padding-bottom: 1rem !important;
}

.pb-6 {
  padding-bottom: 1.25rem !important;
}

.pb-7 {
  padding-bottom: 1.5rem !important;
}

.pb-8 {
  padding-bottom: 1.75rem !important;
}

.pb-9 {
  padding-bottom: 2rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-11 {
  padding-bottom: 3rem !important;
}

.pb-12 {
  padding-bottom: 3.5rem !important;
}

.pb-13 {
  padding-bottom: 4rem !important;
}

.pb-14 {
  padding-bottom: 4.5rem !important;
}

.pb-15 {
  padding-bottom: 5rem !important;
}

.pb-16 {
  padding-bottom: 5.5rem !important;
}

.pb-17 {
  padding-bottom: 6rem !important;
}

.pb-18 {
  padding-bottom: 6.5rem !important;
}

.pb-19 {
  padding-bottom: 7rem !important;
}

.pb-20 {
  padding-bottom: 7.5rem !important;
}

.pb-21 {
  padding-bottom: 8rem !important;
}

.pb-22 {
  padding-bottom: 8.5rem !important;
}

.pb-23 {
  padding-bottom: 9rem !important;
}

.pb-24 {
  padding-bottom: 9.5rem !important;
}

.pb-25 {
  padding-bottom: 10rem !important;
}

.pb-26 {
  padding-bottom: 10.5rem !important;
}

.pb-27 {
  padding-bottom: 11rem !important;
}

.pb-28 {
  padding-bottom: 12rem !important;
}

.pb-29 {
  padding-bottom: 13rem !important;
}

.pb-30 {
  padding-bottom: 14rem !important;
}

.pb-31 {
  padding-bottom: 15rem !important;
}

.pb-32 {
  padding-bottom: 16rem !important;
}

.pb-33 {
  padding-bottom: 17rem !important;
}

.pb-34 {
  padding-bottom: 18rem !important;
}

.pb-35 {
  padding-bottom: 19rem !important;
}

.pb-36 {
  padding-bottom: 20rem !important;
}

.pb-37 {
  padding-bottom: 21rem !important;
}

.pb-38 {
  padding-bottom: 22rem !important;
}

.pb-39 {
  padding-bottom: 23rem !important;
}

.pb-40 {
  padding-bottom: 24rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.35rem !important;
}

.ps-3 {
  padding-left: 0.55rem !important;
}

.ps-4 {
  padding-left: 0.75rem !important;
}

.ps-5 {
  padding-left: 1rem !important;
}

.ps-6 {
  padding-left: 1.25rem !important;
}

.ps-7 {
  padding-left: 1.5rem !important;
}

.ps-8 {
  padding-left: 1.75rem !important;
}

.ps-9 {
  padding-left: 2rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

.ps-11 {
  padding-left: 3rem !important;
}

.ps-12 {
  padding-left: 3.5rem !important;
}

.ps-13 {
  padding-left: 4rem !important;
}

.ps-14 {
  padding-left: 4.5rem !important;
}

.ps-15 {
  padding-left: 5rem !important;
}

.ps-16 {
  padding-left: 5.5rem !important;
}

.ps-17 {
  padding-left: 6rem !important;
}

.ps-18 {
  padding-left: 6.5rem !important;
}

.ps-19 {
  padding-left: 7rem !important;
}

.ps-20 {
  padding-left: 7.5rem !important;
}

.ps-21 {
  padding-left: 8rem !important;
}

.ps-22 {
  padding-left: 8.5rem !important;
}

.ps-23 {
  padding-left: 9rem !important;
}

.ps-24 {
  padding-left: 9.5rem !important;
}

.ps-25 {
  padding-left: 10rem !important;
}

.ps-26 {
  padding-left: 10.5rem !important;
}

.ps-27 {
  padding-left: 11rem !important;
}

.ps-28 {
  padding-left: 12rem !important;
}

.ps-29 {
  padding-left: 13rem !important;
}

.ps-30 {
  padding-left: 14rem !important;
}

.ps-31 {
  padding-left: 15rem !important;
}

.ps-32 {
  padding-left: 16rem !important;
}

.ps-33 {
  padding-left: 17rem !important;
}

.ps-34 {
  padding-left: 18rem !important;
}

.ps-35 {
  padding-left: 19rem !important;
}

.ps-36 {
  padding-left: 20rem !important;
}

.ps-37 {
  padding-left: 21rem !important;
}

.ps-38 {
  padding-left: 22rem !important;
}

.ps-39 {
  padding-left: 23rem !important;
}

.ps-40 {
  padding-left: 24rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.35rem !important;
}

.gap-3 {
  gap: 0.55rem !important;
}

.gap-4 {
  gap: 0.75rem !important;
}

.gap-5 {
  gap: 1rem !important;
}

.gap-6 {
  gap: 1.25rem !important;
}

.gap-7 {
  gap: 1.5rem !important;
}

.gap-8 {
  gap: 1.75rem !important;
}

.gap-9 {
  gap: 2rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-11 {
  gap: 3rem !important;
}

.gap-12 {
  gap: 3.5rem !important;
}

.gap-13 {
  gap: 4rem !important;
}

.gap-14 {
  gap: 4.5rem !important;
}

.gap-15 {
  gap: 5rem !important;
}

.gap-16 {
  gap: 5.5rem !important;
}

.gap-17 {
  gap: 6rem !important;
}

.gap-18 {
  gap: 6.5rem !important;
}

.gap-19 {
  gap: 7rem !important;
}

.gap-20 {
  gap: 7.5rem !important;
}

.gap-21 {
  gap: 8rem !important;
}

.gap-22 {
  gap: 8.5rem !important;
}

.gap-23 {
  gap: 9rem !important;
}

.gap-24 {
  gap: 9.5rem !important;
}

.gap-25 {
  gap: 10rem !important;
}

.gap-26 {
  gap: 10.5rem !important;
}

.gap-27 {
  gap: 11rem !important;
}

.gap-28 {
  gap: 12rem !important;
}

.gap-29 {
  gap: 13rem !important;
}

.gap-30 {
  gap: 14rem !important;
}

.gap-31 {
  gap: 15rem !important;
}

.gap-32 {
  gap: 16rem !important;
}

.gap-33 {
  gap: 17rem !important;
}

.gap-34 {
  gap: 18rem !important;
}

.gap-35 {
  gap: 19rem !important;
}

.gap-36 {
  gap: 20rem !important;
}

.gap-37 {
  gap: 21rem !important;
}

.gap-38 {
  gap: 22rem !important;
}

.gap-39 {
  gap: 23rem !important;
}

.gap-40 {
  gap: 24rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.35rem !important;
}

.row-gap-3 {
  row-gap: 0.55rem !important;
}

.row-gap-4 {
  row-gap: 0.75rem !important;
}

.row-gap-5 {
  row-gap: 1rem !important;
}

.row-gap-6 {
  row-gap: 1.25rem !important;
}

.row-gap-7 {
  row-gap: 1.5rem !important;
}

.row-gap-8 {
  row-gap: 1.75rem !important;
}

.row-gap-9 {
  row-gap: 2rem !important;
}

.row-gap-10 {
  row-gap: 2.5rem !important;
}

.row-gap-11 {
  row-gap: 3rem !important;
}

.row-gap-12 {
  row-gap: 3.5rem !important;
}

.row-gap-13 {
  row-gap: 4rem !important;
}

.row-gap-14 {
  row-gap: 4.5rem !important;
}

.row-gap-15 {
  row-gap: 5rem !important;
}

.row-gap-16 {
  row-gap: 5.5rem !important;
}

.row-gap-17 {
  row-gap: 6rem !important;
}

.row-gap-18 {
  row-gap: 6.5rem !important;
}

.row-gap-19 {
  row-gap: 7rem !important;
}

.row-gap-20 {
  row-gap: 7.5rem !important;
}

.row-gap-21 {
  row-gap: 8rem !important;
}

.row-gap-22 {
  row-gap: 8.5rem !important;
}

.row-gap-23 {
  row-gap: 9rem !important;
}

.row-gap-24 {
  row-gap: 9.5rem !important;
}

.row-gap-25 {
  row-gap: 10rem !important;
}

.row-gap-26 {
  row-gap: 10.5rem !important;
}

.row-gap-27 {
  row-gap: 11rem !important;
}

.row-gap-28 {
  row-gap: 12rem !important;
}

.row-gap-29 {
  row-gap: 13rem !important;
}

.row-gap-30 {
  row-gap: 14rem !important;
}

.row-gap-31 {
  row-gap: 15rem !important;
}

.row-gap-32 {
  row-gap: 16rem !important;
}

.row-gap-33 {
  row-gap: 17rem !important;
}

.row-gap-34 {
  row-gap: 18rem !important;
}

.row-gap-35 {
  row-gap: 19rem !important;
}

.row-gap-36 {
  row-gap: 20rem !important;
}

.row-gap-37 {
  row-gap: 21rem !important;
}

.row-gap-38 {
  row-gap: 22rem !important;
}

.row-gap-39 {
  row-gap: 23rem !important;
}

.row-gap-40 {
  row-gap: 24rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.35rem !important;
}

.column-gap-3 {
  column-gap: 0.55rem !important;
}

.column-gap-4 {
  column-gap: 0.75rem !important;
}

.column-gap-5 {
  column-gap: 1rem !important;
}

.column-gap-6 {
  column-gap: 1.25rem !important;
}

.column-gap-7 {
  column-gap: 1.5rem !important;
}

.column-gap-8 {
  column-gap: 1.75rem !important;
}

.column-gap-9 {
  column-gap: 2rem !important;
}

.column-gap-10 {
  column-gap: 2.5rem !important;
}

.column-gap-11 {
  column-gap: 3rem !important;
}

.column-gap-12 {
  column-gap: 3.5rem !important;
}

.column-gap-13 {
  column-gap: 4rem !important;
}

.column-gap-14 {
  column-gap: 4.5rem !important;
}

.column-gap-15 {
  column-gap: 5rem !important;
}

.column-gap-16 {
  column-gap: 5.5rem !important;
}

.column-gap-17 {
  column-gap: 6rem !important;
}

.column-gap-18 {
  column-gap: 6.5rem !important;
}

.column-gap-19 {
  column-gap: 7rem !important;
}

.column-gap-20 {
  column-gap: 7.5rem !important;
}

.column-gap-21 {
  column-gap: 8rem !important;
}

.column-gap-22 {
  column-gap: 8.5rem !important;
}

.column-gap-23 {
  column-gap: 9rem !important;
}

.column-gap-24 {
  column-gap: 9.5rem !important;
}

.column-gap-25 {
  column-gap: 10rem !important;
}

.column-gap-26 {
  column-gap: 10.5rem !important;
}

.column-gap-27 {
  column-gap: 11rem !important;
}

.column-gap-28 {
  column-gap: 12rem !important;
}

.column-gap-29 {
  column-gap: 13rem !important;
}

.column-gap-30 {
  column-gap: 14rem !important;
}

.column-gap-31 {
  column-gap: 15rem !important;
}

.column-gap-32 {
  column-gap: 16rem !important;
}

.column-gap-33 {
  column-gap: 17rem !important;
}

.column-gap-34 {
  column-gap: 18rem !important;
}

.column-gap-35 {
  column-gap: 19rem !important;
}

.column-gap-36 {
  column-gap: 20rem !important;
}

.column-gap-37 {
  column-gap: 21rem !important;
}

.column-gap-38 {
  column-gap: 22rem !important;
}

.column-gap-39 {
  column-gap: 23rem !important;
}

.column-gap-40 {
  column-gap: 24rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.40625rem + 1.875vw) !important;
}

.fs-2 {
  font-size: calc(1.366875rem + 1.4025vw) !important;
}

.fs-3 {
  font-size: calc(1.321875rem + 0.8625vw) !important;
}

.fs-4 {
  font-size: calc(1.276875rem + 0.3225vw) !important;
}

.fs-5 {
  font-size: calc(1.254375rem + 0.0525vw) !important;
}

.fs-6 {
  font-size: 1.125rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: 100 !important;
}

.fw-light {
  font-weight: 200 !important;
}

.fw-normal {
  font-weight: 300 !important;
}

.fw-medium {
  font-weight: 400 !important;
}

.fw-semibold {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-0 {
  --bs-bg-opacity: 0;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-15 {
  --bs-bg-opacity: 0.15;
}

.bg-opacity-20 {
  --bs-bg-opacity: 0.2;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-30 {
  --bs-bg-opacity: 0.3;
}

.bg-opacity-35 {
  --bs-bg-opacity: 0.35;
}

.bg-opacity-40 {
  --bs-bg-opacity: 0.4;
}

.bg-opacity-45 {
  --bs-bg-opacity: 0.45;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-55 {
  --bs-bg-opacity: 0.55;
}

.bg-opacity-60 {
  --bs-bg-opacity: 0.6;
}

.bg-opacity-65 {
  --bs-bg-opacity: 0.65;
}

.bg-opacity-70 {
  --bs-bg-opacity: 0.7;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-80 {
  --bs-bg-opacity: 0.8;
}

.bg-opacity-85 {
  --bs-bg-opacity: 0.85;
}

.bg-opacity-90 {
  --bs-bg-opacity: 0.9;
}

.bg-opacity-95 {
  --bs-bg-opacity: 0.95;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

.z-6 {
  z-index: 6 !important;
}

.z-7 {
  z-index: 7 !important;
}

.z-8 {
  z-index: 8 !important;
}

.z-9 {
  z-index: 9 !important;
}

.z-z1 {
  z-index: 999999 !important;
}

.cursor-alias {
  cursor: alias !important;
}

.cursor-all-scroll {
  cursor: all-scroll !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-cell {
  cursor: cell !important;
}

.cursor-col-resize {
  cursor: col-resize !important;
}

.cursor-context-menu {
  cursor: context-menu !important;
}

.cursor-copy {
  cursor: copy !important;
}

.cursor-crosshair {
  cursor: crosshair !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-e-resize {
  cursor: e-resize !important;
}

.cursor-ew-resize {
  cursor: ew-resize !important;
}

.cursor-grab {
  cursor: grab !important;
}

.cursor-grabbing {
  cursor: grabbing !important;
}

.cursor-help {
  cursor: help !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-n-resize {
  cursor: n-resize !important;
}

.cursor-ne-resize {
  cursor: ne-resize !important;
}

.cursor-nesw-resize {
  cursor: nesw-resize !important;
}

.cursor-ns-resize {
  cursor: ns-resize !important;
}

.cursor-nw-resize {
  cursor: nw-resize !important;
}

.cursor-nwse-resize {
  cursor: nwse-resize !important;
}

.cursor-no-drop {
  cursor: no-drop !important;
}

.cursor-none {
  cursor: none !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-progress {
  cursor: progress !important;
}

.cursor-row-resize {
  cursor: row-resize !important;
}

.cursor-s-resize {
  cursor: s-resize !important;
}

.cursor-se-resize {
  cursor: se-resize !important;
}

.cursor-sw-resize {
  cursor: sw-resize !important;
}

.cursor-text {
  cursor: text !important;
}

.cursor-url {
  cursor: url !important;
}

.cursor-w-resize {
  cursor: w-resize !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-zoom-in {
  cursor: zoom-in !important;
}

.cursor-zoom-out {
  cursor: zoom-out !important;
}

.fsm-1 {
  font-size: 0.95rem !important;
}

.fsm-2 {
  font-size: 0.9rem !important;
}

.fsm-3 {
  font-size: 0.85rem !important;
}

.fsm-4 {
  font-size: 0.8rem !important;
}

.fsm-5 {
  font-size: 0.75rem !important;
}

.fsm-6 {
  font-size: 0.7rem !important;
}

.fsm-7 {
  font-size: 0.65rem !important;
}

.fsm-8 {
  font-size: 0.6rem !important;
}

.fsm-9 {
  font-size: 0.55rem !important;
}

.ls-1 {
  letter-spacing: 0.15rem !important;
}

.ls-2 {
  letter-spacing: 0.25rem !important;
}

.ls-3 {
  letter-spacing: 0.35rem !important;
}

.ls-4 {
  letter-spacing: 0.45rem !important;
}

.ls-5 {
  letter-spacing: 0.55rem !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.35rem !important;
  }
  .m-sm-3 {
    margin: 0.55rem !important;
  }
  .m-sm-4 {
    margin: 0.75rem !important;
  }
  .m-sm-5 {
    margin: 1rem !important;
  }
  .m-sm-6 {
    margin: 1.25rem !important;
  }
  .m-sm-7 {
    margin: 1.5rem !important;
  }
  .m-sm-8 {
    margin: 1.75rem !important;
  }
  .m-sm-9 {
    margin: 2rem !important;
  }
  .m-sm-10 {
    margin: 2.5rem !important;
  }
  .m-sm-11 {
    margin: 3rem !important;
  }
  .m-sm-12 {
    margin: 3.5rem !important;
  }
  .m-sm-13 {
    margin: 4rem !important;
  }
  .m-sm-14 {
    margin: 4.5rem !important;
  }
  .m-sm-15 {
    margin: 5rem !important;
  }
  .m-sm-16 {
    margin: 5.5rem !important;
  }
  .m-sm-17 {
    margin: 6rem !important;
  }
  .m-sm-18 {
    margin: 6.5rem !important;
  }
  .m-sm-19 {
    margin: 7rem !important;
  }
  .m-sm-20 {
    margin: 7.5rem !important;
  }
  .m-sm-21 {
    margin: 8rem !important;
  }
  .m-sm-22 {
    margin: 8.5rem !important;
  }
  .m-sm-23 {
    margin: 9rem !important;
  }
  .m-sm-24 {
    margin: 9.5rem !important;
  }
  .m-sm-25 {
    margin: 10rem !important;
  }
  .m-sm-26 {
    margin: 10.5rem !important;
  }
  .m-sm-27 {
    margin: 11rem !important;
  }
  .m-sm-28 {
    margin: 12rem !important;
  }
  .m-sm-29 {
    margin: 13rem !important;
  }
  .m-sm-30 {
    margin: 14rem !important;
  }
  .m-sm-31 {
    margin: 15rem !important;
  }
  .m-sm-32 {
    margin: 16rem !important;
  }
  .m-sm-33 {
    margin: 17rem !important;
  }
  .m-sm-34 {
    margin: 18rem !important;
  }
  .m-sm-35 {
    margin: 19rem !important;
  }
  .m-sm-36 {
    margin: 20rem !important;
  }
  .m-sm-37 {
    margin: 21rem !important;
  }
  .m-sm-38 {
    margin: 22rem !important;
  }
  .m-sm-39 {
    margin: 23rem !important;
  }
  .m-sm-40 {
    margin: 24rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.35rem !important;
    margin-left: 0.35rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.55rem !important;
    margin-left: 0.55rem !important;
  }
  .mx-sm-4 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-5 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-6 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-7 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-8 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-sm-9 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-11 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-12 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-sm-13 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-14 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-15 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-16 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-sm-17 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-18 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-sm-19 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-sm-20 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-sm-21 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-sm-22 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }
  .mx-sm-23 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-sm-24 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }
  .mx-sm-25 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-26 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }
  .mx-sm-27 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-sm-28 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-sm-29 {
    margin-right: 13rem !important;
    margin-left: 13rem !important;
  }
  .mx-sm-30 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-sm-31 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-sm-32 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-sm-33 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-sm-34 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-sm-35 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-sm-36 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-sm-37 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-sm-38 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-sm-39 {
    margin-right: 23rem !important;
    margin-left: 23rem !important;
  }
  .mx-sm-40 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.35rem !important;
    margin-bottom: 0.35rem !important;
  }
  .my-sm-3 {
    margin-top: 0.55rem !important;
    margin-bottom: 0.55rem !important;
  }
  .my-sm-4 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-5 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-6 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-7 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-8 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-sm-9 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-11 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-12 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-13 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-14 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-15 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-16 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-sm-17 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-18 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-sm-19 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-sm-20 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-sm-21 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-sm-22 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }
  .my-sm-23 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-sm-24 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }
  .my-sm-25 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-26 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }
  .my-sm-27 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-sm-28 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-sm-29 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }
  .my-sm-30 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-sm-31 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-sm-32 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-sm-33 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-sm-34 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-sm-35 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-sm-36 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-sm-37 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-sm-38 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-sm-39 {
    margin-top: 23rem !important;
    margin-bottom: 23rem !important;
  }
  .my-sm-40 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.35rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.55rem !important;
  }
  .mt-sm-4 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-5 {
    margin-top: 1rem !important;
  }
  .mt-sm-6 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-7 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-8 {
    margin-top: 1.75rem !important;
  }
  .mt-sm-9 {
    margin-top: 2rem !important;
  }
  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-11 {
    margin-top: 3rem !important;
  }
  .mt-sm-12 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-13 {
    margin-top: 4rem !important;
  }
  .mt-sm-14 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-15 {
    margin-top: 5rem !important;
  }
  .mt-sm-16 {
    margin-top: 5.5rem !important;
  }
  .mt-sm-17 {
    margin-top: 6rem !important;
  }
  .mt-sm-18 {
    margin-top: 6.5rem !important;
  }
  .mt-sm-19 {
    margin-top: 7rem !important;
  }
  .mt-sm-20 {
    margin-top: 7.5rem !important;
  }
  .mt-sm-21 {
    margin-top: 8rem !important;
  }
  .mt-sm-22 {
    margin-top: 8.5rem !important;
  }
  .mt-sm-23 {
    margin-top: 9rem !important;
  }
  .mt-sm-24 {
    margin-top: 9.5rem !important;
  }
  .mt-sm-25 {
    margin-top: 10rem !important;
  }
  .mt-sm-26 {
    margin-top: 10.5rem !important;
  }
  .mt-sm-27 {
    margin-top: 11rem !important;
  }
  .mt-sm-28 {
    margin-top: 12rem !important;
  }
  .mt-sm-29 {
    margin-top: 13rem !important;
  }
  .mt-sm-30 {
    margin-top: 14rem !important;
  }
  .mt-sm-31 {
    margin-top: 15rem !important;
  }
  .mt-sm-32 {
    margin-top: 16rem !important;
  }
  .mt-sm-33 {
    margin-top: 17rem !important;
  }
  .mt-sm-34 {
    margin-top: 18rem !important;
  }
  .mt-sm-35 {
    margin-top: 19rem !important;
  }
  .mt-sm-36 {
    margin-top: 20rem !important;
  }
  .mt-sm-37 {
    margin-top: 21rem !important;
  }
  .mt-sm-38 {
    margin-top: 22rem !important;
  }
  .mt-sm-39 {
    margin-top: 23rem !important;
  }
  .mt-sm-40 {
    margin-top: 24rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.35rem !important;
  }
  .me-sm-3 {
    margin-right: 0.55rem !important;
  }
  .me-sm-4 {
    margin-right: 0.75rem !important;
  }
  .me-sm-5 {
    margin-right: 1rem !important;
  }
  .me-sm-6 {
    margin-right: 1.25rem !important;
  }
  .me-sm-7 {
    margin-right: 1.5rem !important;
  }
  .me-sm-8 {
    margin-right: 1.75rem !important;
  }
  .me-sm-9 {
    margin-right: 2rem !important;
  }
  .me-sm-10 {
    margin-right: 2.5rem !important;
  }
  .me-sm-11 {
    margin-right: 3rem !important;
  }
  .me-sm-12 {
    margin-right: 3.5rem !important;
  }
  .me-sm-13 {
    margin-right: 4rem !important;
  }
  .me-sm-14 {
    margin-right: 4.5rem !important;
  }
  .me-sm-15 {
    margin-right: 5rem !important;
  }
  .me-sm-16 {
    margin-right: 5.5rem !important;
  }
  .me-sm-17 {
    margin-right: 6rem !important;
  }
  .me-sm-18 {
    margin-right: 6.5rem !important;
  }
  .me-sm-19 {
    margin-right: 7rem !important;
  }
  .me-sm-20 {
    margin-right: 7.5rem !important;
  }
  .me-sm-21 {
    margin-right: 8rem !important;
  }
  .me-sm-22 {
    margin-right: 8.5rem !important;
  }
  .me-sm-23 {
    margin-right: 9rem !important;
  }
  .me-sm-24 {
    margin-right: 9.5rem !important;
  }
  .me-sm-25 {
    margin-right: 10rem !important;
  }
  .me-sm-26 {
    margin-right: 10.5rem !important;
  }
  .me-sm-27 {
    margin-right: 11rem !important;
  }
  .me-sm-28 {
    margin-right: 12rem !important;
  }
  .me-sm-29 {
    margin-right: 13rem !important;
  }
  .me-sm-30 {
    margin-right: 14rem !important;
  }
  .me-sm-31 {
    margin-right: 15rem !important;
  }
  .me-sm-32 {
    margin-right: 16rem !important;
  }
  .me-sm-33 {
    margin-right: 17rem !important;
  }
  .me-sm-34 {
    margin-right: 18rem !important;
  }
  .me-sm-35 {
    margin-right: 19rem !important;
  }
  .me-sm-36 {
    margin-right: 20rem !important;
  }
  .me-sm-37 {
    margin-right: 21rem !important;
  }
  .me-sm-38 {
    margin-right: 22rem !important;
  }
  .me-sm-39 {
    margin-right: 23rem !important;
  }
  .me-sm-40 {
    margin-right: 24rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.35rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.55rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 1.75rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-13 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-14 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-16 {
    margin-bottom: 5.5rem !important;
  }
  .mb-sm-17 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-18 {
    margin-bottom: 6.5rem !important;
  }
  .mb-sm-19 {
    margin-bottom: 7rem !important;
  }
  .mb-sm-20 {
    margin-bottom: 7.5rem !important;
  }
  .mb-sm-21 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-22 {
    margin-bottom: 8.5rem !important;
  }
  .mb-sm-23 {
    margin-bottom: 9rem !important;
  }
  .mb-sm-24 {
    margin-bottom: 9.5rem !important;
  }
  .mb-sm-25 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-26 {
    margin-bottom: 10.5rem !important;
  }
  .mb-sm-27 {
    margin-bottom: 11rem !important;
  }
  .mb-sm-28 {
    margin-bottom: 12rem !important;
  }
  .mb-sm-29 {
    margin-bottom: 13rem !important;
  }
  .mb-sm-30 {
    margin-bottom: 14rem !important;
  }
  .mb-sm-31 {
    margin-bottom: 15rem !important;
  }
  .mb-sm-32 {
    margin-bottom: 16rem !important;
  }
  .mb-sm-33 {
    margin-bottom: 17rem !important;
  }
  .mb-sm-34 {
    margin-bottom: 18rem !important;
  }
  .mb-sm-35 {
    margin-bottom: 19rem !important;
  }
  .mb-sm-36 {
    margin-bottom: 20rem !important;
  }
  .mb-sm-37 {
    margin-bottom: 21rem !important;
  }
  .mb-sm-38 {
    margin-bottom: 22rem !important;
  }
  .mb-sm-39 {
    margin-bottom: 23rem !important;
  }
  .mb-sm-40 {
    margin-bottom: 24rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.35rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.55rem !important;
  }
  .ms-sm-4 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-5 {
    margin-left: 1rem !important;
  }
  .ms-sm-6 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-7 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-8 {
    margin-left: 1.75rem !important;
  }
  .ms-sm-9 {
    margin-left: 2rem !important;
  }
  .ms-sm-10 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-11 {
    margin-left: 3rem !important;
  }
  .ms-sm-12 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-13 {
    margin-left: 4rem !important;
  }
  .ms-sm-14 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-15 {
    margin-left: 5rem !important;
  }
  .ms-sm-16 {
    margin-left: 5.5rem !important;
  }
  .ms-sm-17 {
    margin-left: 6rem !important;
  }
  .ms-sm-18 {
    margin-left: 6.5rem !important;
  }
  .ms-sm-19 {
    margin-left: 7rem !important;
  }
  .ms-sm-20 {
    margin-left: 7.5rem !important;
  }
  .ms-sm-21 {
    margin-left: 8rem !important;
  }
  .ms-sm-22 {
    margin-left: 8.5rem !important;
  }
  .ms-sm-23 {
    margin-left: 9rem !important;
  }
  .ms-sm-24 {
    margin-left: 9.5rem !important;
  }
  .ms-sm-25 {
    margin-left: 10rem !important;
  }
  .ms-sm-26 {
    margin-left: 10.5rem !important;
  }
  .ms-sm-27 {
    margin-left: 11rem !important;
  }
  .ms-sm-28 {
    margin-left: 12rem !important;
  }
  .ms-sm-29 {
    margin-left: 13rem !important;
  }
  .ms-sm-30 {
    margin-left: 14rem !important;
  }
  .ms-sm-31 {
    margin-left: 15rem !important;
  }
  .ms-sm-32 {
    margin-left: 16rem !important;
  }
  .ms-sm-33 {
    margin-left: 17rem !important;
  }
  .ms-sm-34 {
    margin-left: 18rem !important;
  }
  .ms-sm-35 {
    margin-left: 19rem !important;
  }
  .ms-sm-36 {
    margin-left: 20rem !important;
  }
  .ms-sm-37 {
    margin-left: 21rem !important;
  }
  .ms-sm-38 {
    margin-left: 22rem !important;
  }
  .ms-sm-39 {
    margin-left: 23rem !important;
  }
  .ms-sm-40 {
    margin-left: 24rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.35rem !important;
  }
  .p-sm-3 {
    padding: 0.55rem !important;
  }
  .p-sm-4 {
    padding: 0.75rem !important;
  }
  .p-sm-5 {
    padding: 1rem !important;
  }
  .p-sm-6 {
    padding: 1.25rem !important;
  }
  .p-sm-7 {
    padding: 1.5rem !important;
  }
  .p-sm-8 {
    padding: 1.75rem !important;
  }
  .p-sm-9 {
    padding: 2rem !important;
  }
  .p-sm-10 {
    padding: 2.5rem !important;
  }
  .p-sm-11 {
    padding: 3rem !important;
  }
  .p-sm-12 {
    padding: 3.5rem !important;
  }
  .p-sm-13 {
    padding: 4rem !important;
  }
  .p-sm-14 {
    padding: 4.5rem !important;
  }
  .p-sm-15 {
    padding: 5rem !important;
  }
  .p-sm-16 {
    padding: 5.5rem !important;
  }
  .p-sm-17 {
    padding: 6rem !important;
  }
  .p-sm-18 {
    padding: 6.5rem !important;
  }
  .p-sm-19 {
    padding: 7rem !important;
  }
  .p-sm-20 {
    padding: 7.5rem !important;
  }
  .p-sm-21 {
    padding: 8rem !important;
  }
  .p-sm-22 {
    padding: 8.5rem !important;
  }
  .p-sm-23 {
    padding: 9rem !important;
  }
  .p-sm-24 {
    padding: 9.5rem !important;
  }
  .p-sm-25 {
    padding: 10rem !important;
  }
  .p-sm-26 {
    padding: 10.5rem !important;
  }
  .p-sm-27 {
    padding: 11rem !important;
  }
  .p-sm-28 {
    padding: 12rem !important;
  }
  .p-sm-29 {
    padding: 13rem !important;
  }
  .p-sm-30 {
    padding: 14rem !important;
  }
  .p-sm-31 {
    padding: 15rem !important;
  }
  .p-sm-32 {
    padding: 16rem !important;
  }
  .p-sm-33 {
    padding: 17rem !important;
  }
  .p-sm-34 {
    padding: 18rem !important;
  }
  .p-sm-35 {
    padding: 19rem !important;
  }
  .p-sm-36 {
    padding: 20rem !important;
  }
  .p-sm-37 {
    padding: 21rem !important;
  }
  .p-sm-38 {
    padding: 22rem !important;
  }
  .p-sm-39 {
    padding: 23rem !important;
  }
  .p-sm-40 {
    padding: 24rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.35rem !important;
    padding-left: 0.35rem !important;
  }
  .px-sm-3 {
    padding-right: 0.55rem !important;
    padding-left: 0.55rem !important;
  }
  .px-sm-4 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-sm-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-6 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-8 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-sm-9 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-11 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-12 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-sm-13 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-14 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-sm-15 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-16 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-sm-17 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-18 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-sm-19 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-sm-20 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-sm-21 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-sm-22 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }
  .px-sm-23 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-sm-24 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }
  .px-sm-25 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-sm-26 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }
  .px-sm-27 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-sm-28 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-sm-29 {
    padding-right: 13rem !important;
    padding-left: 13rem !important;
  }
  .px-sm-30 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-sm-31 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .px-sm-32 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-sm-33 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-sm-34 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-sm-35 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-sm-36 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-sm-37 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-sm-38 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-sm-39 {
    padding-right: 23rem !important;
    padding-left: 23rem !important;
  }
  .px-sm-40 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.35rem !important;
    padding-bottom: 0.35rem !important;
  }
  .py-sm-3 {
    padding-top: 0.55rem !important;
    padding-bottom: 0.55rem !important;
  }
  .py-sm-4 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-sm-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-6 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-7 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-8 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-sm-9 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-11 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-12 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-13 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-14 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-sm-15 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-16 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-sm-17 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-18 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-sm-19 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-sm-20 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-sm-21 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-sm-22 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }
  .py-sm-23 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-sm-24 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }
  .py-sm-25 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-sm-26 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }
  .py-sm-27 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-sm-28 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-sm-29 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }
  .py-sm-30 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-sm-31 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .py-sm-32 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-sm-33 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-sm-34 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-sm-35 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-sm-36 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-sm-37 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-sm-38 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-sm-39 {
    padding-top: 23rem !important;
    padding-bottom: 23rem !important;
  }
  .py-sm-40 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.35rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.55rem !important;
  }
  .pt-sm-4 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-5 {
    padding-top: 1rem !important;
  }
  .pt-sm-6 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-7 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-8 {
    padding-top: 1.75rem !important;
  }
  .pt-sm-9 {
    padding-top: 2rem !important;
  }
  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-11 {
    padding-top: 3rem !important;
  }
  .pt-sm-12 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-13 {
    padding-top: 4rem !important;
  }
  .pt-sm-14 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-15 {
    padding-top: 5rem !important;
  }
  .pt-sm-16 {
    padding-top: 5.5rem !important;
  }
  .pt-sm-17 {
    padding-top: 6rem !important;
  }
  .pt-sm-18 {
    padding-top: 6.5rem !important;
  }
  .pt-sm-19 {
    padding-top: 7rem !important;
  }
  .pt-sm-20 {
    padding-top: 7.5rem !important;
  }
  .pt-sm-21 {
    padding-top: 8rem !important;
  }
  .pt-sm-22 {
    padding-top: 8.5rem !important;
  }
  .pt-sm-23 {
    padding-top: 9rem !important;
  }
  .pt-sm-24 {
    padding-top: 9.5rem !important;
  }
  .pt-sm-25 {
    padding-top: 10rem !important;
  }
  .pt-sm-26 {
    padding-top: 10.5rem !important;
  }
  .pt-sm-27 {
    padding-top: 11rem !important;
  }
  .pt-sm-28 {
    padding-top: 12rem !important;
  }
  .pt-sm-29 {
    padding-top: 13rem !important;
  }
  .pt-sm-30 {
    padding-top: 14rem !important;
  }
  .pt-sm-31 {
    padding-top: 15rem !important;
  }
  .pt-sm-32 {
    padding-top: 16rem !important;
  }
  .pt-sm-33 {
    padding-top: 17rem !important;
  }
  .pt-sm-34 {
    padding-top: 18rem !important;
  }
  .pt-sm-35 {
    padding-top: 19rem !important;
  }
  .pt-sm-36 {
    padding-top: 20rem !important;
  }
  .pt-sm-37 {
    padding-top: 21rem !important;
  }
  .pt-sm-38 {
    padding-top: 22rem !important;
  }
  .pt-sm-39 {
    padding-top: 23rem !important;
  }
  .pt-sm-40 {
    padding-top: 24rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.35rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.55rem !important;
  }
  .pe-sm-4 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-5 {
    padding-right: 1rem !important;
  }
  .pe-sm-6 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-7 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-8 {
    padding-right: 1.75rem !important;
  }
  .pe-sm-9 {
    padding-right: 2rem !important;
  }
  .pe-sm-10 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-11 {
    padding-right: 3rem !important;
  }
  .pe-sm-12 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-13 {
    padding-right: 4rem !important;
  }
  .pe-sm-14 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-15 {
    padding-right: 5rem !important;
  }
  .pe-sm-16 {
    padding-right: 5.5rem !important;
  }
  .pe-sm-17 {
    padding-right: 6rem !important;
  }
  .pe-sm-18 {
    padding-right: 6.5rem !important;
  }
  .pe-sm-19 {
    padding-right: 7rem !important;
  }
  .pe-sm-20 {
    padding-right: 7.5rem !important;
  }
  .pe-sm-21 {
    padding-right: 8rem !important;
  }
  .pe-sm-22 {
    padding-right: 8.5rem !important;
  }
  .pe-sm-23 {
    padding-right: 9rem !important;
  }
  .pe-sm-24 {
    padding-right: 9.5rem !important;
  }
  .pe-sm-25 {
    padding-right: 10rem !important;
  }
  .pe-sm-26 {
    padding-right: 10.5rem !important;
  }
  .pe-sm-27 {
    padding-right: 11rem !important;
  }
  .pe-sm-28 {
    padding-right: 12rem !important;
  }
  .pe-sm-29 {
    padding-right: 13rem !important;
  }
  .pe-sm-30 {
    padding-right: 14rem !important;
  }
  .pe-sm-31 {
    padding-right: 15rem !important;
  }
  .pe-sm-32 {
    padding-right: 16rem !important;
  }
  .pe-sm-33 {
    padding-right: 17rem !important;
  }
  .pe-sm-34 {
    padding-right: 18rem !important;
  }
  .pe-sm-35 {
    padding-right: 19rem !important;
  }
  .pe-sm-36 {
    padding-right: 20rem !important;
  }
  .pe-sm-37 {
    padding-right: 21rem !important;
  }
  .pe-sm-38 {
    padding-right: 22rem !important;
  }
  .pe-sm-39 {
    padding-right: 23rem !important;
  }
  .pe-sm-40 {
    padding-right: 24rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.35rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.55rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 1.75rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-13 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-14 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-16 {
    padding-bottom: 5.5rem !important;
  }
  .pb-sm-17 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-18 {
    padding-bottom: 6.5rem !important;
  }
  .pb-sm-19 {
    padding-bottom: 7rem !important;
  }
  .pb-sm-20 {
    padding-bottom: 7.5rem !important;
  }
  .pb-sm-21 {
    padding-bottom: 8rem !important;
  }
  .pb-sm-22 {
    padding-bottom: 8.5rem !important;
  }
  .pb-sm-23 {
    padding-bottom: 9rem !important;
  }
  .pb-sm-24 {
    padding-bottom: 9.5rem !important;
  }
  .pb-sm-25 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-26 {
    padding-bottom: 10.5rem !important;
  }
  .pb-sm-27 {
    padding-bottom: 11rem !important;
  }
  .pb-sm-28 {
    padding-bottom: 12rem !important;
  }
  .pb-sm-29 {
    padding-bottom: 13rem !important;
  }
  .pb-sm-30 {
    padding-bottom: 14rem !important;
  }
  .pb-sm-31 {
    padding-bottom: 15rem !important;
  }
  .pb-sm-32 {
    padding-bottom: 16rem !important;
  }
  .pb-sm-33 {
    padding-bottom: 17rem !important;
  }
  .pb-sm-34 {
    padding-bottom: 18rem !important;
  }
  .pb-sm-35 {
    padding-bottom: 19rem !important;
  }
  .pb-sm-36 {
    padding-bottom: 20rem !important;
  }
  .pb-sm-37 {
    padding-bottom: 21rem !important;
  }
  .pb-sm-38 {
    padding-bottom: 22rem !important;
  }
  .pb-sm-39 {
    padding-bottom: 23rem !important;
  }
  .pb-sm-40 {
    padding-bottom: 24rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.35rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.55rem !important;
  }
  .ps-sm-4 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-5 {
    padding-left: 1rem !important;
  }
  .ps-sm-6 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-7 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-8 {
    padding-left: 1.75rem !important;
  }
  .ps-sm-9 {
    padding-left: 2rem !important;
  }
  .ps-sm-10 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-11 {
    padding-left: 3rem !important;
  }
  .ps-sm-12 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-13 {
    padding-left: 4rem !important;
  }
  .ps-sm-14 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-15 {
    padding-left: 5rem !important;
  }
  .ps-sm-16 {
    padding-left: 5.5rem !important;
  }
  .ps-sm-17 {
    padding-left: 6rem !important;
  }
  .ps-sm-18 {
    padding-left: 6.5rem !important;
  }
  .ps-sm-19 {
    padding-left: 7rem !important;
  }
  .ps-sm-20 {
    padding-left: 7.5rem !important;
  }
  .ps-sm-21 {
    padding-left: 8rem !important;
  }
  .ps-sm-22 {
    padding-left: 8.5rem !important;
  }
  .ps-sm-23 {
    padding-left: 9rem !important;
  }
  .ps-sm-24 {
    padding-left: 9.5rem !important;
  }
  .ps-sm-25 {
    padding-left: 10rem !important;
  }
  .ps-sm-26 {
    padding-left: 10.5rem !important;
  }
  .ps-sm-27 {
    padding-left: 11rem !important;
  }
  .ps-sm-28 {
    padding-left: 12rem !important;
  }
  .ps-sm-29 {
    padding-left: 13rem !important;
  }
  .ps-sm-30 {
    padding-left: 14rem !important;
  }
  .ps-sm-31 {
    padding-left: 15rem !important;
  }
  .ps-sm-32 {
    padding-left: 16rem !important;
  }
  .ps-sm-33 {
    padding-left: 17rem !important;
  }
  .ps-sm-34 {
    padding-left: 18rem !important;
  }
  .ps-sm-35 {
    padding-left: 19rem !important;
  }
  .ps-sm-36 {
    padding-left: 20rem !important;
  }
  .ps-sm-37 {
    padding-left: 21rem !important;
  }
  .ps-sm-38 {
    padding-left: 22rem !important;
  }
  .ps-sm-39 {
    padding-left: 23rem !important;
  }
  .ps-sm-40 {
    padding-left: 24rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.35rem !important;
  }
  .gap-sm-3 {
    gap: 0.55rem !important;
  }
  .gap-sm-4 {
    gap: 0.75rem !important;
  }
  .gap-sm-5 {
    gap: 1rem !important;
  }
  .gap-sm-6 {
    gap: 1.25rem !important;
  }
  .gap-sm-7 {
    gap: 1.5rem !important;
  }
  .gap-sm-8 {
    gap: 1.75rem !important;
  }
  .gap-sm-9 {
    gap: 2rem !important;
  }
  .gap-sm-10 {
    gap: 2.5rem !important;
  }
  .gap-sm-11 {
    gap: 3rem !important;
  }
  .gap-sm-12 {
    gap: 3.5rem !important;
  }
  .gap-sm-13 {
    gap: 4rem !important;
  }
  .gap-sm-14 {
    gap: 4.5rem !important;
  }
  .gap-sm-15 {
    gap: 5rem !important;
  }
  .gap-sm-16 {
    gap: 5.5rem !important;
  }
  .gap-sm-17 {
    gap: 6rem !important;
  }
  .gap-sm-18 {
    gap: 6.5rem !important;
  }
  .gap-sm-19 {
    gap: 7rem !important;
  }
  .gap-sm-20 {
    gap: 7.5rem !important;
  }
  .gap-sm-21 {
    gap: 8rem !important;
  }
  .gap-sm-22 {
    gap: 8.5rem !important;
  }
  .gap-sm-23 {
    gap: 9rem !important;
  }
  .gap-sm-24 {
    gap: 9.5rem !important;
  }
  .gap-sm-25 {
    gap: 10rem !important;
  }
  .gap-sm-26 {
    gap: 10.5rem !important;
  }
  .gap-sm-27 {
    gap: 11rem !important;
  }
  .gap-sm-28 {
    gap: 12rem !important;
  }
  .gap-sm-29 {
    gap: 13rem !important;
  }
  .gap-sm-30 {
    gap: 14rem !important;
  }
  .gap-sm-31 {
    gap: 15rem !important;
  }
  .gap-sm-32 {
    gap: 16rem !important;
  }
  .gap-sm-33 {
    gap: 17rem !important;
  }
  .gap-sm-34 {
    gap: 18rem !important;
  }
  .gap-sm-35 {
    gap: 19rem !important;
  }
  .gap-sm-36 {
    gap: 20rem !important;
  }
  .gap-sm-37 {
    gap: 21rem !important;
  }
  .gap-sm-38 {
    gap: 22rem !important;
  }
  .gap-sm-39 {
    gap: 23rem !important;
  }
  .gap-sm-40 {
    gap: 24rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.35rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 0.55rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 0.75rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 1.25rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 1.75rem !important;
  }
  .row-gap-sm-9 {
    row-gap: 2rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-sm-11 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-12 {
    row-gap: 3.5rem !important;
  }
  .row-gap-sm-13 {
    row-gap: 4rem !important;
  }
  .row-gap-sm-14 {
    row-gap: 4.5rem !important;
  }
  .row-gap-sm-15 {
    row-gap: 5rem !important;
  }
  .row-gap-sm-16 {
    row-gap: 5.5rem !important;
  }
  .row-gap-sm-17 {
    row-gap: 6rem !important;
  }
  .row-gap-sm-18 {
    row-gap: 6.5rem !important;
  }
  .row-gap-sm-19 {
    row-gap: 7rem !important;
  }
  .row-gap-sm-20 {
    row-gap: 7.5rem !important;
  }
  .row-gap-sm-21 {
    row-gap: 8rem !important;
  }
  .row-gap-sm-22 {
    row-gap: 8.5rem !important;
  }
  .row-gap-sm-23 {
    row-gap: 9rem !important;
  }
  .row-gap-sm-24 {
    row-gap: 9.5rem !important;
  }
  .row-gap-sm-25 {
    row-gap: 10rem !important;
  }
  .row-gap-sm-26 {
    row-gap: 10.5rem !important;
  }
  .row-gap-sm-27 {
    row-gap: 11rem !important;
  }
  .row-gap-sm-28 {
    row-gap: 12rem !important;
  }
  .row-gap-sm-29 {
    row-gap: 13rem !important;
  }
  .row-gap-sm-30 {
    row-gap: 14rem !important;
  }
  .row-gap-sm-31 {
    row-gap: 15rem !important;
  }
  .row-gap-sm-32 {
    row-gap: 16rem !important;
  }
  .row-gap-sm-33 {
    row-gap: 17rem !important;
  }
  .row-gap-sm-34 {
    row-gap: 18rem !important;
  }
  .row-gap-sm-35 {
    row-gap: 19rem !important;
  }
  .row-gap-sm-36 {
    row-gap: 20rem !important;
  }
  .row-gap-sm-37 {
    row-gap: 21rem !important;
  }
  .row-gap-sm-38 {
    row-gap: 22rem !important;
  }
  .row-gap-sm-39 {
    row-gap: 23rem !important;
  }
  .row-gap-sm-40 {
    row-gap: 24rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.35rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 0.55rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 0.75rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 1.25rem !important;
  }
  .column-gap-sm-7 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-8 {
    column-gap: 1.75rem !important;
  }
  .column-gap-sm-9 {
    column-gap: 2rem !important;
  }
  .column-gap-sm-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-sm-11 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-12 {
    column-gap: 3.5rem !important;
  }
  .column-gap-sm-13 {
    column-gap: 4rem !important;
  }
  .column-gap-sm-14 {
    column-gap: 4.5rem !important;
  }
  .column-gap-sm-15 {
    column-gap: 5rem !important;
  }
  .column-gap-sm-16 {
    column-gap: 5.5rem !important;
  }
  .column-gap-sm-17 {
    column-gap: 6rem !important;
  }
  .column-gap-sm-18 {
    column-gap: 6.5rem !important;
  }
  .column-gap-sm-19 {
    column-gap: 7rem !important;
  }
  .column-gap-sm-20 {
    column-gap: 7.5rem !important;
  }
  .column-gap-sm-21 {
    column-gap: 8rem !important;
  }
  .column-gap-sm-22 {
    column-gap: 8.5rem !important;
  }
  .column-gap-sm-23 {
    column-gap: 9rem !important;
  }
  .column-gap-sm-24 {
    column-gap: 9.5rem !important;
  }
  .column-gap-sm-25 {
    column-gap: 10rem !important;
  }
  .column-gap-sm-26 {
    column-gap: 10.5rem !important;
  }
  .column-gap-sm-27 {
    column-gap: 11rem !important;
  }
  .column-gap-sm-28 {
    column-gap: 12rem !important;
  }
  .column-gap-sm-29 {
    column-gap: 13rem !important;
  }
  .column-gap-sm-30 {
    column-gap: 14rem !important;
  }
  .column-gap-sm-31 {
    column-gap: 15rem !important;
  }
  .column-gap-sm-32 {
    column-gap: 16rem !important;
  }
  .column-gap-sm-33 {
    column-gap: 17rem !important;
  }
  .column-gap-sm-34 {
    column-gap: 18rem !important;
  }
  .column-gap-sm-35 {
    column-gap: 19rem !important;
  }
  .column-gap-sm-36 {
    column-gap: 20rem !important;
  }
  .column-gap-sm-37 {
    column-gap: 21rem !important;
  }
  .column-gap-sm-38 {
    column-gap: 22rem !important;
  }
  .column-gap-sm-39 {
    column-gap: 23rem !important;
  }
  .column-gap-sm-40 {
    column-gap: 24rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.35rem !important;
  }
  .m-md-3 {
    margin: 0.55rem !important;
  }
  .m-md-4 {
    margin: 0.75rem !important;
  }
  .m-md-5 {
    margin: 1rem !important;
  }
  .m-md-6 {
    margin: 1.25rem !important;
  }
  .m-md-7 {
    margin: 1.5rem !important;
  }
  .m-md-8 {
    margin: 1.75rem !important;
  }
  .m-md-9 {
    margin: 2rem !important;
  }
  .m-md-10 {
    margin: 2.5rem !important;
  }
  .m-md-11 {
    margin: 3rem !important;
  }
  .m-md-12 {
    margin: 3.5rem !important;
  }
  .m-md-13 {
    margin: 4rem !important;
  }
  .m-md-14 {
    margin: 4.5rem !important;
  }
  .m-md-15 {
    margin: 5rem !important;
  }
  .m-md-16 {
    margin: 5.5rem !important;
  }
  .m-md-17 {
    margin: 6rem !important;
  }
  .m-md-18 {
    margin: 6.5rem !important;
  }
  .m-md-19 {
    margin: 7rem !important;
  }
  .m-md-20 {
    margin: 7.5rem !important;
  }
  .m-md-21 {
    margin: 8rem !important;
  }
  .m-md-22 {
    margin: 8.5rem !important;
  }
  .m-md-23 {
    margin: 9rem !important;
  }
  .m-md-24 {
    margin: 9.5rem !important;
  }
  .m-md-25 {
    margin: 10rem !important;
  }
  .m-md-26 {
    margin: 10.5rem !important;
  }
  .m-md-27 {
    margin: 11rem !important;
  }
  .m-md-28 {
    margin: 12rem !important;
  }
  .m-md-29 {
    margin: 13rem !important;
  }
  .m-md-30 {
    margin: 14rem !important;
  }
  .m-md-31 {
    margin: 15rem !important;
  }
  .m-md-32 {
    margin: 16rem !important;
  }
  .m-md-33 {
    margin: 17rem !important;
  }
  .m-md-34 {
    margin: 18rem !important;
  }
  .m-md-35 {
    margin: 19rem !important;
  }
  .m-md-36 {
    margin: 20rem !important;
  }
  .m-md-37 {
    margin: 21rem !important;
  }
  .m-md-38 {
    margin: 22rem !important;
  }
  .m-md-39 {
    margin: 23rem !important;
  }
  .m-md-40 {
    margin: 24rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.35rem !important;
    margin-left: 0.35rem !important;
  }
  .mx-md-3 {
    margin-right: 0.55rem !important;
    margin-left: 0.55rem !important;
  }
  .mx-md-4 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-5 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-6 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-7 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-8 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-md-9 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-11 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-12 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-md-13 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-14 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-15 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-16 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-md-17 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-18 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-md-19 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-md-20 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-md-21 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-md-22 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }
  .mx-md-23 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-md-24 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }
  .mx-md-25 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-26 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }
  .mx-md-27 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-md-28 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-md-29 {
    margin-right: 13rem !important;
    margin-left: 13rem !important;
  }
  .mx-md-30 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-md-31 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-md-32 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-md-33 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-md-34 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-md-35 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-md-36 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-md-37 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-md-38 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-md-39 {
    margin-right: 23rem !important;
    margin-left: 23rem !important;
  }
  .mx-md-40 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.35rem !important;
    margin-bottom: 0.35rem !important;
  }
  .my-md-3 {
    margin-top: 0.55rem !important;
    margin-bottom: 0.55rem !important;
  }
  .my-md-4 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-5 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-6 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-7 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-8 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-md-9 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-11 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-12 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-13 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-14 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-15 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-16 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-md-17 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-18 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-md-19 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-md-20 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-md-21 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-md-22 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }
  .my-md-23 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-md-24 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }
  .my-md-25 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-26 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }
  .my-md-27 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-md-28 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-md-29 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }
  .my-md-30 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-md-31 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-md-32 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-md-33 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-md-34 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-md-35 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-md-36 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-md-37 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-md-38 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-md-39 {
    margin-top: 23rem !important;
    margin-bottom: 23rem !important;
  }
  .my-md-40 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.35rem !important;
  }
  .mt-md-3 {
    margin-top: 0.55rem !important;
  }
  .mt-md-4 {
    margin-top: 0.75rem !important;
  }
  .mt-md-5 {
    margin-top: 1rem !important;
  }
  .mt-md-6 {
    margin-top: 1.25rem !important;
  }
  .mt-md-7 {
    margin-top: 1.5rem !important;
  }
  .mt-md-8 {
    margin-top: 1.75rem !important;
  }
  .mt-md-9 {
    margin-top: 2rem !important;
  }
  .mt-md-10 {
    margin-top: 2.5rem !important;
  }
  .mt-md-11 {
    margin-top: 3rem !important;
  }
  .mt-md-12 {
    margin-top: 3.5rem !important;
  }
  .mt-md-13 {
    margin-top: 4rem !important;
  }
  .mt-md-14 {
    margin-top: 4.5rem !important;
  }
  .mt-md-15 {
    margin-top: 5rem !important;
  }
  .mt-md-16 {
    margin-top: 5.5rem !important;
  }
  .mt-md-17 {
    margin-top: 6rem !important;
  }
  .mt-md-18 {
    margin-top: 6.5rem !important;
  }
  .mt-md-19 {
    margin-top: 7rem !important;
  }
  .mt-md-20 {
    margin-top: 7.5rem !important;
  }
  .mt-md-21 {
    margin-top: 8rem !important;
  }
  .mt-md-22 {
    margin-top: 8.5rem !important;
  }
  .mt-md-23 {
    margin-top: 9rem !important;
  }
  .mt-md-24 {
    margin-top: 9.5rem !important;
  }
  .mt-md-25 {
    margin-top: 10rem !important;
  }
  .mt-md-26 {
    margin-top: 10.5rem !important;
  }
  .mt-md-27 {
    margin-top: 11rem !important;
  }
  .mt-md-28 {
    margin-top: 12rem !important;
  }
  .mt-md-29 {
    margin-top: 13rem !important;
  }
  .mt-md-30 {
    margin-top: 14rem !important;
  }
  .mt-md-31 {
    margin-top: 15rem !important;
  }
  .mt-md-32 {
    margin-top: 16rem !important;
  }
  .mt-md-33 {
    margin-top: 17rem !important;
  }
  .mt-md-34 {
    margin-top: 18rem !important;
  }
  .mt-md-35 {
    margin-top: 19rem !important;
  }
  .mt-md-36 {
    margin-top: 20rem !important;
  }
  .mt-md-37 {
    margin-top: 21rem !important;
  }
  .mt-md-38 {
    margin-top: 22rem !important;
  }
  .mt-md-39 {
    margin-top: 23rem !important;
  }
  .mt-md-40 {
    margin-top: 24rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.35rem !important;
  }
  .me-md-3 {
    margin-right: 0.55rem !important;
  }
  .me-md-4 {
    margin-right: 0.75rem !important;
  }
  .me-md-5 {
    margin-right: 1rem !important;
  }
  .me-md-6 {
    margin-right: 1.25rem !important;
  }
  .me-md-7 {
    margin-right: 1.5rem !important;
  }
  .me-md-8 {
    margin-right: 1.75rem !important;
  }
  .me-md-9 {
    margin-right: 2rem !important;
  }
  .me-md-10 {
    margin-right: 2.5rem !important;
  }
  .me-md-11 {
    margin-right: 3rem !important;
  }
  .me-md-12 {
    margin-right: 3.5rem !important;
  }
  .me-md-13 {
    margin-right: 4rem !important;
  }
  .me-md-14 {
    margin-right: 4.5rem !important;
  }
  .me-md-15 {
    margin-right: 5rem !important;
  }
  .me-md-16 {
    margin-right: 5.5rem !important;
  }
  .me-md-17 {
    margin-right: 6rem !important;
  }
  .me-md-18 {
    margin-right: 6.5rem !important;
  }
  .me-md-19 {
    margin-right: 7rem !important;
  }
  .me-md-20 {
    margin-right: 7.5rem !important;
  }
  .me-md-21 {
    margin-right: 8rem !important;
  }
  .me-md-22 {
    margin-right: 8.5rem !important;
  }
  .me-md-23 {
    margin-right: 9rem !important;
  }
  .me-md-24 {
    margin-right: 9.5rem !important;
  }
  .me-md-25 {
    margin-right: 10rem !important;
  }
  .me-md-26 {
    margin-right: 10.5rem !important;
  }
  .me-md-27 {
    margin-right: 11rem !important;
  }
  .me-md-28 {
    margin-right: 12rem !important;
  }
  .me-md-29 {
    margin-right: 13rem !important;
  }
  .me-md-30 {
    margin-right: 14rem !important;
  }
  .me-md-31 {
    margin-right: 15rem !important;
  }
  .me-md-32 {
    margin-right: 16rem !important;
  }
  .me-md-33 {
    margin-right: 17rem !important;
  }
  .me-md-34 {
    margin-right: 18rem !important;
  }
  .me-md-35 {
    margin-right: 19rem !important;
  }
  .me-md-36 {
    margin-right: 20rem !important;
  }
  .me-md-37 {
    margin-right: 21rem !important;
  }
  .me-md-38 {
    margin-right: 22rem !important;
  }
  .me-md-39 {
    margin-right: 23rem !important;
  }
  .me-md-40 {
    margin-right: 24rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.35rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.55rem !important;
  }
  .mb-md-4 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1rem !important;
  }
  .mb-md-6 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-7 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-8 {
    margin-bottom: 1.75rem !important;
  }
  .mb-md-9 {
    margin-bottom: 2rem !important;
  }
  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-11 {
    margin-bottom: 3rem !important;
  }
  .mb-md-12 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-13 {
    margin-bottom: 4rem !important;
  }
  .mb-md-14 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-15 {
    margin-bottom: 5rem !important;
  }
  .mb-md-16 {
    margin-bottom: 5.5rem !important;
  }
  .mb-md-17 {
    margin-bottom: 6rem !important;
  }
  .mb-md-18 {
    margin-bottom: 6.5rem !important;
  }
  .mb-md-19 {
    margin-bottom: 7rem !important;
  }
  .mb-md-20 {
    margin-bottom: 7.5rem !important;
  }
  .mb-md-21 {
    margin-bottom: 8rem !important;
  }
  .mb-md-22 {
    margin-bottom: 8.5rem !important;
  }
  .mb-md-23 {
    margin-bottom: 9rem !important;
  }
  .mb-md-24 {
    margin-bottom: 9.5rem !important;
  }
  .mb-md-25 {
    margin-bottom: 10rem !important;
  }
  .mb-md-26 {
    margin-bottom: 10.5rem !important;
  }
  .mb-md-27 {
    margin-bottom: 11rem !important;
  }
  .mb-md-28 {
    margin-bottom: 12rem !important;
  }
  .mb-md-29 {
    margin-bottom: 13rem !important;
  }
  .mb-md-30 {
    margin-bottom: 14rem !important;
  }
  .mb-md-31 {
    margin-bottom: 15rem !important;
  }
  .mb-md-32 {
    margin-bottom: 16rem !important;
  }
  .mb-md-33 {
    margin-bottom: 17rem !important;
  }
  .mb-md-34 {
    margin-bottom: 18rem !important;
  }
  .mb-md-35 {
    margin-bottom: 19rem !important;
  }
  .mb-md-36 {
    margin-bottom: 20rem !important;
  }
  .mb-md-37 {
    margin-bottom: 21rem !important;
  }
  .mb-md-38 {
    margin-bottom: 22rem !important;
  }
  .mb-md-39 {
    margin-bottom: 23rem !important;
  }
  .mb-md-40 {
    margin-bottom: 24rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.35rem !important;
  }
  .ms-md-3 {
    margin-left: 0.55rem !important;
  }
  .ms-md-4 {
    margin-left: 0.75rem !important;
  }
  .ms-md-5 {
    margin-left: 1rem !important;
  }
  .ms-md-6 {
    margin-left: 1.25rem !important;
  }
  .ms-md-7 {
    margin-left: 1.5rem !important;
  }
  .ms-md-8 {
    margin-left: 1.75rem !important;
  }
  .ms-md-9 {
    margin-left: 2rem !important;
  }
  .ms-md-10 {
    margin-left: 2.5rem !important;
  }
  .ms-md-11 {
    margin-left: 3rem !important;
  }
  .ms-md-12 {
    margin-left: 3.5rem !important;
  }
  .ms-md-13 {
    margin-left: 4rem !important;
  }
  .ms-md-14 {
    margin-left: 4.5rem !important;
  }
  .ms-md-15 {
    margin-left: 5rem !important;
  }
  .ms-md-16 {
    margin-left: 5.5rem !important;
  }
  .ms-md-17 {
    margin-left: 6rem !important;
  }
  .ms-md-18 {
    margin-left: 6.5rem !important;
  }
  .ms-md-19 {
    margin-left: 7rem !important;
  }
  .ms-md-20 {
    margin-left: 7.5rem !important;
  }
  .ms-md-21 {
    margin-left: 8rem !important;
  }
  .ms-md-22 {
    margin-left: 8.5rem !important;
  }
  .ms-md-23 {
    margin-left: 9rem !important;
  }
  .ms-md-24 {
    margin-left: 9.5rem !important;
  }
  .ms-md-25 {
    margin-left: 10rem !important;
  }
  .ms-md-26 {
    margin-left: 10.5rem !important;
  }
  .ms-md-27 {
    margin-left: 11rem !important;
  }
  .ms-md-28 {
    margin-left: 12rem !important;
  }
  .ms-md-29 {
    margin-left: 13rem !important;
  }
  .ms-md-30 {
    margin-left: 14rem !important;
  }
  .ms-md-31 {
    margin-left: 15rem !important;
  }
  .ms-md-32 {
    margin-left: 16rem !important;
  }
  .ms-md-33 {
    margin-left: 17rem !important;
  }
  .ms-md-34 {
    margin-left: 18rem !important;
  }
  .ms-md-35 {
    margin-left: 19rem !important;
  }
  .ms-md-36 {
    margin-left: 20rem !important;
  }
  .ms-md-37 {
    margin-left: 21rem !important;
  }
  .ms-md-38 {
    margin-left: 22rem !important;
  }
  .ms-md-39 {
    margin-left: 23rem !important;
  }
  .ms-md-40 {
    margin-left: 24rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.35rem !important;
  }
  .p-md-3 {
    padding: 0.55rem !important;
  }
  .p-md-4 {
    padding: 0.75rem !important;
  }
  .p-md-5 {
    padding: 1rem !important;
  }
  .p-md-6 {
    padding: 1.25rem !important;
  }
  .p-md-7 {
    padding: 1.5rem !important;
  }
  .p-md-8 {
    padding: 1.75rem !important;
  }
  .p-md-9 {
    padding: 2rem !important;
  }
  .p-md-10 {
    padding: 2.5rem !important;
  }
  .p-md-11 {
    padding: 3rem !important;
  }
  .p-md-12 {
    padding: 3.5rem !important;
  }
  .p-md-13 {
    padding: 4rem !important;
  }
  .p-md-14 {
    padding: 4.5rem !important;
  }
  .p-md-15 {
    padding: 5rem !important;
  }
  .p-md-16 {
    padding: 5.5rem !important;
  }
  .p-md-17 {
    padding: 6rem !important;
  }
  .p-md-18 {
    padding: 6.5rem !important;
  }
  .p-md-19 {
    padding: 7rem !important;
  }
  .p-md-20 {
    padding: 7.5rem !important;
  }
  .p-md-21 {
    padding: 8rem !important;
  }
  .p-md-22 {
    padding: 8.5rem !important;
  }
  .p-md-23 {
    padding: 9rem !important;
  }
  .p-md-24 {
    padding: 9.5rem !important;
  }
  .p-md-25 {
    padding: 10rem !important;
  }
  .p-md-26 {
    padding: 10.5rem !important;
  }
  .p-md-27 {
    padding: 11rem !important;
  }
  .p-md-28 {
    padding: 12rem !important;
  }
  .p-md-29 {
    padding: 13rem !important;
  }
  .p-md-30 {
    padding: 14rem !important;
  }
  .p-md-31 {
    padding: 15rem !important;
  }
  .p-md-32 {
    padding: 16rem !important;
  }
  .p-md-33 {
    padding: 17rem !important;
  }
  .p-md-34 {
    padding: 18rem !important;
  }
  .p-md-35 {
    padding: 19rem !important;
  }
  .p-md-36 {
    padding: 20rem !important;
  }
  .p-md-37 {
    padding: 21rem !important;
  }
  .p-md-38 {
    padding: 22rem !important;
  }
  .p-md-39 {
    padding: 23rem !important;
  }
  .p-md-40 {
    padding: 24rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.35rem !important;
    padding-left: 0.35rem !important;
  }
  .px-md-3 {
    padding-right: 0.55rem !important;
    padding-left: 0.55rem !important;
  }
  .px-md-4 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-md-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-6 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-8 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-md-9 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-11 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-12 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-md-13 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-14 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-md-15 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-16 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-md-17 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-18 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-md-19 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-md-20 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-md-21 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-md-22 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }
  .px-md-23 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-md-24 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }
  .px-md-25 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-md-26 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }
  .px-md-27 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-md-28 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-md-29 {
    padding-right: 13rem !important;
    padding-left: 13rem !important;
  }
  .px-md-30 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-md-31 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .px-md-32 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-md-33 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-md-34 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-md-35 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-md-36 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-md-37 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-md-38 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-md-39 {
    padding-right: 23rem !important;
    padding-left: 23rem !important;
  }
  .px-md-40 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.35rem !important;
    padding-bottom: 0.35rem !important;
  }
  .py-md-3 {
    padding-top: 0.55rem !important;
    padding-bottom: 0.55rem !important;
  }
  .py-md-4 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-md-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-6 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-7 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-8 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-md-9 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-11 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-12 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-13 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-14 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-md-15 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-16 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-md-17 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-18 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-md-19 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-md-20 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-md-21 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-md-22 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }
  .py-md-23 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-md-24 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }
  .py-md-25 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-md-26 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }
  .py-md-27 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-md-28 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-md-29 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }
  .py-md-30 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-md-31 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .py-md-32 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-md-33 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-md-34 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-md-35 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-md-36 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-md-37 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-md-38 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-md-39 {
    padding-top: 23rem !important;
    padding-bottom: 23rem !important;
  }
  .py-md-40 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.35rem !important;
  }
  .pt-md-3 {
    padding-top: 0.55rem !important;
  }
  .pt-md-4 {
    padding-top: 0.75rem !important;
  }
  .pt-md-5 {
    padding-top: 1rem !important;
  }
  .pt-md-6 {
    padding-top: 1.25rem !important;
  }
  .pt-md-7 {
    padding-top: 1.5rem !important;
  }
  .pt-md-8 {
    padding-top: 1.75rem !important;
  }
  .pt-md-9 {
    padding-top: 2rem !important;
  }
  .pt-md-10 {
    padding-top: 2.5rem !important;
  }
  .pt-md-11 {
    padding-top: 3rem !important;
  }
  .pt-md-12 {
    padding-top: 3.5rem !important;
  }
  .pt-md-13 {
    padding-top: 4rem !important;
  }
  .pt-md-14 {
    padding-top: 4.5rem !important;
  }
  .pt-md-15 {
    padding-top: 5rem !important;
  }
  .pt-md-16 {
    padding-top: 5.5rem !important;
  }
  .pt-md-17 {
    padding-top: 6rem !important;
  }
  .pt-md-18 {
    padding-top: 6.5rem !important;
  }
  .pt-md-19 {
    padding-top: 7rem !important;
  }
  .pt-md-20 {
    padding-top: 7.5rem !important;
  }
  .pt-md-21 {
    padding-top: 8rem !important;
  }
  .pt-md-22 {
    padding-top: 8.5rem !important;
  }
  .pt-md-23 {
    padding-top: 9rem !important;
  }
  .pt-md-24 {
    padding-top: 9.5rem !important;
  }
  .pt-md-25 {
    padding-top: 10rem !important;
  }
  .pt-md-26 {
    padding-top: 10.5rem !important;
  }
  .pt-md-27 {
    padding-top: 11rem !important;
  }
  .pt-md-28 {
    padding-top: 12rem !important;
  }
  .pt-md-29 {
    padding-top: 13rem !important;
  }
  .pt-md-30 {
    padding-top: 14rem !important;
  }
  .pt-md-31 {
    padding-top: 15rem !important;
  }
  .pt-md-32 {
    padding-top: 16rem !important;
  }
  .pt-md-33 {
    padding-top: 17rem !important;
  }
  .pt-md-34 {
    padding-top: 18rem !important;
  }
  .pt-md-35 {
    padding-top: 19rem !important;
  }
  .pt-md-36 {
    padding-top: 20rem !important;
  }
  .pt-md-37 {
    padding-top: 21rem !important;
  }
  .pt-md-38 {
    padding-top: 22rem !important;
  }
  .pt-md-39 {
    padding-top: 23rem !important;
  }
  .pt-md-40 {
    padding-top: 24rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.35rem !important;
  }
  .pe-md-3 {
    padding-right: 0.55rem !important;
  }
  .pe-md-4 {
    padding-right: 0.75rem !important;
  }
  .pe-md-5 {
    padding-right: 1rem !important;
  }
  .pe-md-6 {
    padding-right: 1.25rem !important;
  }
  .pe-md-7 {
    padding-right: 1.5rem !important;
  }
  .pe-md-8 {
    padding-right: 1.75rem !important;
  }
  .pe-md-9 {
    padding-right: 2rem !important;
  }
  .pe-md-10 {
    padding-right: 2.5rem !important;
  }
  .pe-md-11 {
    padding-right: 3rem !important;
  }
  .pe-md-12 {
    padding-right: 3.5rem !important;
  }
  .pe-md-13 {
    padding-right: 4rem !important;
  }
  .pe-md-14 {
    padding-right: 4.5rem !important;
  }
  .pe-md-15 {
    padding-right: 5rem !important;
  }
  .pe-md-16 {
    padding-right: 5.5rem !important;
  }
  .pe-md-17 {
    padding-right: 6rem !important;
  }
  .pe-md-18 {
    padding-right: 6.5rem !important;
  }
  .pe-md-19 {
    padding-right: 7rem !important;
  }
  .pe-md-20 {
    padding-right: 7.5rem !important;
  }
  .pe-md-21 {
    padding-right: 8rem !important;
  }
  .pe-md-22 {
    padding-right: 8.5rem !important;
  }
  .pe-md-23 {
    padding-right: 9rem !important;
  }
  .pe-md-24 {
    padding-right: 9.5rem !important;
  }
  .pe-md-25 {
    padding-right: 10rem !important;
  }
  .pe-md-26 {
    padding-right: 10.5rem !important;
  }
  .pe-md-27 {
    padding-right: 11rem !important;
  }
  .pe-md-28 {
    padding-right: 12rem !important;
  }
  .pe-md-29 {
    padding-right: 13rem !important;
  }
  .pe-md-30 {
    padding-right: 14rem !important;
  }
  .pe-md-31 {
    padding-right: 15rem !important;
  }
  .pe-md-32 {
    padding-right: 16rem !important;
  }
  .pe-md-33 {
    padding-right: 17rem !important;
  }
  .pe-md-34 {
    padding-right: 18rem !important;
  }
  .pe-md-35 {
    padding-right: 19rem !important;
  }
  .pe-md-36 {
    padding-right: 20rem !important;
  }
  .pe-md-37 {
    padding-right: 21rem !important;
  }
  .pe-md-38 {
    padding-right: 22rem !important;
  }
  .pe-md-39 {
    padding-right: 23rem !important;
  }
  .pe-md-40 {
    padding-right: 24rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.35rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.55rem !important;
  }
  .pb-md-4 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1rem !important;
  }
  .pb-md-6 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-7 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-8 {
    padding-bottom: 1.75rem !important;
  }
  .pb-md-9 {
    padding-bottom: 2rem !important;
  }
  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-11 {
    padding-bottom: 3rem !important;
  }
  .pb-md-12 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-13 {
    padding-bottom: 4rem !important;
  }
  .pb-md-14 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-15 {
    padding-bottom: 5rem !important;
  }
  .pb-md-16 {
    padding-bottom: 5.5rem !important;
  }
  .pb-md-17 {
    padding-bottom: 6rem !important;
  }
  .pb-md-18 {
    padding-bottom: 6.5rem !important;
  }
  .pb-md-19 {
    padding-bottom: 7rem !important;
  }
  .pb-md-20 {
    padding-bottom: 7.5rem !important;
  }
  .pb-md-21 {
    padding-bottom: 8rem !important;
  }
  .pb-md-22 {
    padding-bottom: 8.5rem !important;
  }
  .pb-md-23 {
    padding-bottom: 9rem !important;
  }
  .pb-md-24 {
    padding-bottom: 9.5rem !important;
  }
  .pb-md-25 {
    padding-bottom: 10rem !important;
  }
  .pb-md-26 {
    padding-bottom: 10.5rem !important;
  }
  .pb-md-27 {
    padding-bottom: 11rem !important;
  }
  .pb-md-28 {
    padding-bottom: 12rem !important;
  }
  .pb-md-29 {
    padding-bottom: 13rem !important;
  }
  .pb-md-30 {
    padding-bottom: 14rem !important;
  }
  .pb-md-31 {
    padding-bottom: 15rem !important;
  }
  .pb-md-32 {
    padding-bottom: 16rem !important;
  }
  .pb-md-33 {
    padding-bottom: 17rem !important;
  }
  .pb-md-34 {
    padding-bottom: 18rem !important;
  }
  .pb-md-35 {
    padding-bottom: 19rem !important;
  }
  .pb-md-36 {
    padding-bottom: 20rem !important;
  }
  .pb-md-37 {
    padding-bottom: 21rem !important;
  }
  .pb-md-38 {
    padding-bottom: 22rem !important;
  }
  .pb-md-39 {
    padding-bottom: 23rem !important;
  }
  .pb-md-40 {
    padding-bottom: 24rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.35rem !important;
  }
  .ps-md-3 {
    padding-left: 0.55rem !important;
  }
  .ps-md-4 {
    padding-left: 0.75rem !important;
  }
  .ps-md-5 {
    padding-left: 1rem !important;
  }
  .ps-md-6 {
    padding-left: 1.25rem !important;
  }
  .ps-md-7 {
    padding-left: 1.5rem !important;
  }
  .ps-md-8 {
    padding-left: 1.75rem !important;
  }
  .ps-md-9 {
    padding-left: 2rem !important;
  }
  .ps-md-10 {
    padding-left: 2.5rem !important;
  }
  .ps-md-11 {
    padding-left: 3rem !important;
  }
  .ps-md-12 {
    padding-left: 3.5rem !important;
  }
  .ps-md-13 {
    padding-left: 4rem !important;
  }
  .ps-md-14 {
    padding-left: 4.5rem !important;
  }
  .ps-md-15 {
    padding-left: 5rem !important;
  }
  .ps-md-16 {
    padding-left: 5.5rem !important;
  }
  .ps-md-17 {
    padding-left: 6rem !important;
  }
  .ps-md-18 {
    padding-left: 6.5rem !important;
  }
  .ps-md-19 {
    padding-left: 7rem !important;
  }
  .ps-md-20 {
    padding-left: 7.5rem !important;
  }
  .ps-md-21 {
    padding-left: 8rem !important;
  }
  .ps-md-22 {
    padding-left: 8.5rem !important;
  }
  .ps-md-23 {
    padding-left: 9rem !important;
  }
  .ps-md-24 {
    padding-left: 9.5rem !important;
  }
  .ps-md-25 {
    padding-left: 10rem !important;
  }
  .ps-md-26 {
    padding-left: 10.5rem !important;
  }
  .ps-md-27 {
    padding-left: 11rem !important;
  }
  .ps-md-28 {
    padding-left: 12rem !important;
  }
  .ps-md-29 {
    padding-left: 13rem !important;
  }
  .ps-md-30 {
    padding-left: 14rem !important;
  }
  .ps-md-31 {
    padding-left: 15rem !important;
  }
  .ps-md-32 {
    padding-left: 16rem !important;
  }
  .ps-md-33 {
    padding-left: 17rem !important;
  }
  .ps-md-34 {
    padding-left: 18rem !important;
  }
  .ps-md-35 {
    padding-left: 19rem !important;
  }
  .ps-md-36 {
    padding-left: 20rem !important;
  }
  .ps-md-37 {
    padding-left: 21rem !important;
  }
  .ps-md-38 {
    padding-left: 22rem !important;
  }
  .ps-md-39 {
    padding-left: 23rem !important;
  }
  .ps-md-40 {
    padding-left: 24rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.35rem !important;
  }
  .gap-md-3 {
    gap: 0.55rem !important;
  }
  .gap-md-4 {
    gap: 0.75rem !important;
  }
  .gap-md-5 {
    gap: 1rem !important;
  }
  .gap-md-6 {
    gap: 1.25rem !important;
  }
  .gap-md-7 {
    gap: 1.5rem !important;
  }
  .gap-md-8 {
    gap: 1.75rem !important;
  }
  .gap-md-9 {
    gap: 2rem !important;
  }
  .gap-md-10 {
    gap: 2.5rem !important;
  }
  .gap-md-11 {
    gap: 3rem !important;
  }
  .gap-md-12 {
    gap: 3.5rem !important;
  }
  .gap-md-13 {
    gap: 4rem !important;
  }
  .gap-md-14 {
    gap: 4.5rem !important;
  }
  .gap-md-15 {
    gap: 5rem !important;
  }
  .gap-md-16 {
    gap: 5.5rem !important;
  }
  .gap-md-17 {
    gap: 6rem !important;
  }
  .gap-md-18 {
    gap: 6.5rem !important;
  }
  .gap-md-19 {
    gap: 7rem !important;
  }
  .gap-md-20 {
    gap: 7.5rem !important;
  }
  .gap-md-21 {
    gap: 8rem !important;
  }
  .gap-md-22 {
    gap: 8.5rem !important;
  }
  .gap-md-23 {
    gap: 9rem !important;
  }
  .gap-md-24 {
    gap: 9.5rem !important;
  }
  .gap-md-25 {
    gap: 10rem !important;
  }
  .gap-md-26 {
    gap: 10.5rem !important;
  }
  .gap-md-27 {
    gap: 11rem !important;
  }
  .gap-md-28 {
    gap: 12rem !important;
  }
  .gap-md-29 {
    gap: 13rem !important;
  }
  .gap-md-30 {
    gap: 14rem !important;
  }
  .gap-md-31 {
    gap: 15rem !important;
  }
  .gap-md-32 {
    gap: 16rem !important;
  }
  .gap-md-33 {
    gap: 17rem !important;
  }
  .gap-md-34 {
    gap: 18rem !important;
  }
  .gap-md-35 {
    gap: 19rem !important;
  }
  .gap-md-36 {
    gap: 20rem !important;
  }
  .gap-md-37 {
    gap: 21rem !important;
  }
  .gap-md-38 {
    gap: 22rem !important;
  }
  .gap-md-39 {
    gap: 23rem !important;
  }
  .gap-md-40 {
    gap: 24rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.35rem !important;
  }
  .row-gap-md-3 {
    row-gap: 0.55rem !important;
  }
  .row-gap-md-4 {
    row-gap: 0.75rem !important;
  }
  .row-gap-md-5 {
    row-gap: 1rem !important;
  }
  .row-gap-md-6 {
    row-gap: 1.25rem !important;
  }
  .row-gap-md-7 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-8 {
    row-gap: 1.75rem !important;
  }
  .row-gap-md-9 {
    row-gap: 2rem !important;
  }
  .row-gap-md-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-md-11 {
    row-gap: 3rem !important;
  }
  .row-gap-md-12 {
    row-gap: 3.5rem !important;
  }
  .row-gap-md-13 {
    row-gap: 4rem !important;
  }
  .row-gap-md-14 {
    row-gap: 4.5rem !important;
  }
  .row-gap-md-15 {
    row-gap: 5rem !important;
  }
  .row-gap-md-16 {
    row-gap: 5.5rem !important;
  }
  .row-gap-md-17 {
    row-gap: 6rem !important;
  }
  .row-gap-md-18 {
    row-gap: 6.5rem !important;
  }
  .row-gap-md-19 {
    row-gap: 7rem !important;
  }
  .row-gap-md-20 {
    row-gap: 7.5rem !important;
  }
  .row-gap-md-21 {
    row-gap: 8rem !important;
  }
  .row-gap-md-22 {
    row-gap: 8.5rem !important;
  }
  .row-gap-md-23 {
    row-gap: 9rem !important;
  }
  .row-gap-md-24 {
    row-gap: 9.5rem !important;
  }
  .row-gap-md-25 {
    row-gap: 10rem !important;
  }
  .row-gap-md-26 {
    row-gap: 10.5rem !important;
  }
  .row-gap-md-27 {
    row-gap: 11rem !important;
  }
  .row-gap-md-28 {
    row-gap: 12rem !important;
  }
  .row-gap-md-29 {
    row-gap: 13rem !important;
  }
  .row-gap-md-30 {
    row-gap: 14rem !important;
  }
  .row-gap-md-31 {
    row-gap: 15rem !important;
  }
  .row-gap-md-32 {
    row-gap: 16rem !important;
  }
  .row-gap-md-33 {
    row-gap: 17rem !important;
  }
  .row-gap-md-34 {
    row-gap: 18rem !important;
  }
  .row-gap-md-35 {
    row-gap: 19rem !important;
  }
  .row-gap-md-36 {
    row-gap: 20rem !important;
  }
  .row-gap-md-37 {
    row-gap: 21rem !important;
  }
  .row-gap-md-38 {
    row-gap: 22rem !important;
  }
  .row-gap-md-39 {
    row-gap: 23rem !important;
  }
  .row-gap-md-40 {
    row-gap: 24rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.35rem !important;
  }
  .column-gap-md-3 {
    column-gap: 0.55rem !important;
  }
  .column-gap-md-4 {
    column-gap: 0.75rem !important;
  }
  .column-gap-md-5 {
    column-gap: 1rem !important;
  }
  .column-gap-md-6 {
    column-gap: 1.25rem !important;
  }
  .column-gap-md-7 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-8 {
    column-gap: 1.75rem !important;
  }
  .column-gap-md-9 {
    column-gap: 2rem !important;
  }
  .column-gap-md-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-md-11 {
    column-gap: 3rem !important;
  }
  .column-gap-md-12 {
    column-gap: 3.5rem !important;
  }
  .column-gap-md-13 {
    column-gap: 4rem !important;
  }
  .column-gap-md-14 {
    column-gap: 4.5rem !important;
  }
  .column-gap-md-15 {
    column-gap: 5rem !important;
  }
  .column-gap-md-16 {
    column-gap: 5.5rem !important;
  }
  .column-gap-md-17 {
    column-gap: 6rem !important;
  }
  .column-gap-md-18 {
    column-gap: 6.5rem !important;
  }
  .column-gap-md-19 {
    column-gap: 7rem !important;
  }
  .column-gap-md-20 {
    column-gap: 7.5rem !important;
  }
  .column-gap-md-21 {
    column-gap: 8rem !important;
  }
  .column-gap-md-22 {
    column-gap: 8.5rem !important;
  }
  .column-gap-md-23 {
    column-gap: 9rem !important;
  }
  .column-gap-md-24 {
    column-gap: 9.5rem !important;
  }
  .column-gap-md-25 {
    column-gap: 10rem !important;
  }
  .column-gap-md-26 {
    column-gap: 10.5rem !important;
  }
  .column-gap-md-27 {
    column-gap: 11rem !important;
  }
  .column-gap-md-28 {
    column-gap: 12rem !important;
  }
  .column-gap-md-29 {
    column-gap: 13rem !important;
  }
  .column-gap-md-30 {
    column-gap: 14rem !important;
  }
  .column-gap-md-31 {
    column-gap: 15rem !important;
  }
  .column-gap-md-32 {
    column-gap: 16rem !important;
  }
  .column-gap-md-33 {
    column-gap: 17rem !important;
  }
  .column-gap-md-34 {
    column-gap: 18rem !important;
  }
  .column-gap-md-35 {
    column-gap: 19rem !important;
  }
  .column-gap-md-36 {
    column-gap: 20rem !important;
  }
  .column-gap-md-37 {
    column-gap: 21rem !important;
  }
  .column-gap-md-38 {
    column-gap: 22rem !important;
  }
  .column-gap-md-39 {
    column-gap: 23rem !important;
  }
  .column-gap-md-40 {
    column-gap: 24rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.35rem !important;
  }
  .m-lg-3 {
    margin: 0.55rem !important;
  }
  .m-lg-4 {
    margin: 0.75rem !important;
  }
  .m-lg-5 {
    margin: 1rem !important;
  }
  .m-lg-6 {
    margin: 1.25rem !important;
  }
  .m-lg-7 {
    margin: 1.5rem !important;
  }
  .m-lg-8 {
    margin: 1.75rem !important;
  }
  .m-lg-9 {
    margin: 2rem !important;
  }
  .m-lg-10 {
    margin: 2.5rem !important;
  }
  .m-lg-11 {
    margin: 3rem !important;
  }
  .m-lg-12 {
    margin: 3.5rem !important;
  }
  .m-lg-13 {
    margin: 4rem !important;
  }
  .m-lg-14 {
    margin: 4.5rem !important;
  }
  .m-lg-15 {
    margin: 5rem !important;
  }
  .m-lg-16 {
    margin: 5.5rem !important;
  }
  .m-lg-17 {
    margin: 6rem !important;
  }
  .m-lg-18 {
    margin: 6.5rem !important;
  }
  .m-lg-19 {
    margin: 7rem !important;
  }
  .m-lg-20 {
    margin: 7.5rem !important;
  }
  .m-lg-21 {
    margin: 8rem !important;
  }
  .m-lg-22 {
    margin: 8.5rem !important;
  }
  .m-lg-23 {
    margin: 9rem !important;
  }
  .m-lg-24 {
    margin: 9.5rem !important;
  }
  .m-lg-25 {
    margin: 10rem !important;
  }
  .m-lg-26 {
    margin: 10.5rem !important;
  }
  .m-lg-27 {
    margin: 11rem !important;
  }
  .m-lg-28 {
    margin: 12rem !important;
  }
  .m-lg-29 {
    margin: 13rem !important;
  }
  .m-lg-30 {
    margin: 14rem !important;
  }
  .m-lg-31 {
    margin: 15rem !important;
  }
  .m-lg-32 {
    margin: 16rem !important;
  }
  .m-lg-33 {
    margin: 17rem !important;
  }
  .m-lg-34 {
    margin: 18rem !important;
  }
  .m-lg-35 {
    margin: 19rem !important;
  }
  .m-lg-36 {
    margin: 20rem !important;
  }
  .m-lg-37 {
    margin: 21rem !important;
  }
  .m-lg-38 {
    margin: 22rem !important;
  }
  .m-lg-39 {
    margin: 23rem !important;
  }
  .m-lg-40 {
    margin: 24rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.35rem !important;
    margin-left: 0.35rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.55rem !important;
    margin-left: 0.55rem !important;
  }
  .mx-lg-4 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-5 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-6 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-7 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-8 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-lg-9 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-11 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-12 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-lg-13 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-14 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-15 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-16 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-lg-17 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-18 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-lg-19 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-lg-20 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-lg-21 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-lg-22 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }
  .mx-lg-23 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-lg-24 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }
  .mx-lg-25 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-26 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }
  .mx-lg-27 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-lg-28 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-lg-29 {
    margin-right: 13rem !important;
    margin-left: 13rem !important;
  }
  .mx-lg-30 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-lg-31 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-lg-32 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-lg-33 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-lg-34 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-lg-35 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-lg-36 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-lg-37 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-lg-38 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-lg-39 {
    margin-right: 23rem !important;
    margin-left: 23rem !important;
  }
  .mx-lg-40 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.35rem !important;
    margin-bottom: 0.35rem !important;
  }
  .my-lg-3 {
    margin-top: 0.55rem !important;
    margin-bottom: 0.55rem !important;
  }
  .my-lg-4 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-5 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-6 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-7 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-8 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-lg-9 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-11 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-12 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-13 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-14 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-15 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-16 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-lg-17 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-18 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-lg-19 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-lg-20 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-lg-21 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-lg-22 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }
  .my-lg-23 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-lg-24 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }
  .my-lg-25 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-26 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }
  .my-lg-27 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-lg-28 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-lg-29 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }
  .my-lg-30 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-lg-31 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-lg-32 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-lg-33 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-lg-34 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-lg-35 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-lg-36 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-lg-37 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-lg-38 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-lg-39 {
    margin-top: 23rem !important;
    margin-bottom: 23rem !important;
  }
  .my-lg-40 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.35rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.55rem !important;
  }
  .mt-lg-4 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-5 {
    margin-top: 1rem !important;
  }
  .mt-lg-6 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-7 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-8 {
    margin-top: 1.75rem !important;
  }
  .mt-lg-9 {
    margin-top: 2rem !important;
  }
  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-11 {
    margin-top: 3rem !important;
  }
  .mt-lg-12 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-13 {
    margin-top: 4rem !important;
  }
  .mt-lg-14 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-15 {
    margin-top: 5rem !important;
  }
  .mt-lg-16 {
    margin-top: 5.5rem !important;
  }
  .mt-lg-17 {
    margin-top: 6rem !important;
  }
  .mt-lg-18 {
    margin-top: 6.5rem !important;
  }
  .mt-lg-19 {
    margin-top: 7rem !important;
  }
  .mt-lg-20 {
    margin-top: 7.5rem !important;
  }
  .mt-lg-21 {
    margin-top: 8rem !important;
  }
  .mt-lg-22 {
    margin-top: 8.5rem !important;
  }
  .mt-lg-23 {
    margin-top: 9rem !important;
  }
  .mt-lg-24 {
    margin-top: 9.5rem !important;
  }
  .mt-lg-25 {
    margin-top: 10rem !important;
  }
  .mt-lg-26 {
    margin-top: 10.5rem !important;
  }
  .mt-lg-27 {
    margin-top: 11rem !important;
  }
  .mt-lg-28 {
    margin-top: 12rem !important;
  }
  .mt-lg-29 {
    margin-top: 13rem !important;
  }
  .mt-lg-30 {
    margin-top: 14rem !important;
  }
  .mt-lg-31 {
    margin-top: 15rem !important;
  }
  .mt-lg-32 {
    margin-top: 16rem !important;
  }
  .mt-lg-33 {
    margin-top: 17rem !important;
  }
  .mt-lg-34 {
    margin-top: 18rem !important;
  }
  .mt-lg-35 {
    margin-top: 19rem !important;
  }
  .mt-lg-36 {
    margin-top: 20rem !important;
  }
  .mt-lg-37 {
    margin-top: 21rem !important;
  }
  .mt-lg-38 {
    margin-top: 22rem !important;
  }
  .mt-lg-39 {
    margin-top: 23rem !important;
  }
  .mt-lg-40 {
    margin-top: 24rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.35rem !important;
  }
  .me-lg-3 {
    margin-right: 0.55rem !important;
  }
  .me-lg-4 {
    margin-right: 0.75rem !important;
  }
  .me-lg-5 {
    margin-right: 1rem !important;
  }
  .me-lg-6 {
    margin-right: 1.25rem !important;
  }
  .me-lg-7 {
    margin-right: 1.5rem !important;
  }
  .me-lg-8 {
    margin-right: 1.75rem !important;
  }
  .me-lg-9 {
    margin-right: 2rem !important;
  }
  .me-lg-10 {
    margin-right: 2.5rem !important;
  }
  .me-lg-11 {
    margin-right: 3rem !important;
  }
  .me-lg-12 {
    margin-right: 3.5rem !important;
  }
  .me-lg-13 {
    margin-right: 4rem !important;
  }
  .me-lg-14 {
    margin-right: 4.5rem !important;
  }
  .me-lg-15 {
    margin-right: 5rem !important;
  }
  .me-lg-16 {
    margin-right: 5.5rem !important;
  }
  .me-lg-17 {
    margin-right: 6rem !important;
  }
  .me-lg-18 {
    margin-right: 6.5rem !important;
  }
  .me-lg-19 {
    margin-right: 7rem !important;
  }
  .me-lg-20 {
    margin-right: 7.5rem !important;
  }
  .me-lg-21 {
    margin-right: 8rem !important;
  }
  .me-lg-22 {
    margin-right: 8.5rem !important;
  }
  .me-lg-23 {
    margin-right: 9rem !important;
  }
  .me-lg-24 {
    margin-right: 9.5rem !important;
  }
  .me-lg-25 {
    margin-right: 10rem !important;
  }
  .me-lg-26 {
    margin-right: 10.5rem !important;
  }
  .me-lg-27 {
    margin-right: 11rem !important;
  }
  .me-lg-28 {
    margin-right: 12rem !important;
  }
  .me-lg-29 {
    margin-right: 13rem !important;
  }
  .me-lg-30 {
    margin-right: 14rem !important;
  }
  .me-lg-31 {
    margin-right: 15rem !important;
  }
  .me-lg-32 {
    margin-right: 16rem !important;
  }
  .me-lg-33 {
    margin-right: 17rem !important;
  }
  .me-lg-34 {
    margin-right: 18rem !important;
  }
  .me-lg-35 {
    margin-right: 19rem !important;
  }
  .me-lg-36 {
    margin-right: 20rem !important;
  }
  .me-lg-37 {
    margin-right: 21rem !important;
  }
  .me-lg-38 {
    margin-right: 22rem !important;
  }
  .me-lg-39 {
    margin-right: 23rem !important;
  }
  .me-lg-40 {
    margin-right: 24rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.35rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.55rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 1.75rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-16 {
    margin-bottom: 5.5rem !important;
  }
  .mb-lg-17 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-18 {
    margin-bottom: 6.5rem !important;
  }
  .mb-lg-19 {
    margin-bottom: 7rem !important;
  }
  .mb-lg-20 {
    margin-bottom: 7.5rem !important;
  }
  .mb-lg-21 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-22 {
    margin-bottom: 8.5rem !important;
  }
  .mb-lg-23 {
    margin-bottom: 9rem !important;
  }
  .mb-lg-24 {
    margin-bottom: 9.5rem !important;
  }
  .mb-lg-25 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-26 {
    margin-bottom: 10.5rem !important;
  }
  .mb-lg-27 {
    margin-bottom: 11rem !important;
  }
  .mb-lg-28 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-29 {
    margin-bottom: 13rem !important;
  }
  .mb-lg-30 {
    margin-bottom: 14rem !important;
  }
  .mb-lg-31 {
    margin-bottom: 15rem !important;
  }
  .mb-lg-32 {
    margin-bottom: 16rem !important;
  }
  .mb-lg-33 {
    margin-bottom: 17rem !important;
  }
  .mb-lg-34 {
    margin-bottom: 18rem !important;
  }
  .mb-lg-35 {
    margin-bottom: 19rem !important;
  }
  .mb-lg-36 {
    margin-bottom: 20rem !important;
  }
  .mb-lg-37 {
    margin-bottom: 21rem !important;
  }
  .mb-lg-38 {
    margin-bottom: 22rem !important;
  }
  .mb-lg-39 {
    margin-bottom: 23rem !important;
  }
  .mb-lg-40 {
    margin-bottom: 24rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.35rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.55rem !important;
  }
  .ms-lg-4 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-5 {
    margin-left: 1rem !important;
  }
  .ms-lg-6 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-7 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-8 {
    margin-left: 1.75rem !important;
  }
  .ms-lg-9 {
    margin-left: 2rem !important;
  }
  .ms-lg-10 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-11 {
    margin-left: 3rem !important;
  }
  .ms-lg-12 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-13 {
    margin-left: 4rem !important;
  }
  .ms-lg-14 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-15 {
    margin-left: 5rem !important;
  }
  .ms-lg-16 {
    margin-left: 5.5rem !important;
  }
  .ms-lg-17 {
    margin-left: 6rem !important;
  }
  .ms-lg-18 {
    margin-left: 6.5rem !important;
  }
  .ms-lg-19 {
    margin-left: 7rem !important;
  }
  .ms-lg-20 {
    margin-left: 7.5rem !important;
  }
  .ms-lg-21 {
    margin-left: 8rem !important;
  }
  .ms-lg-22 {
    margin-left: 8.5rem !important;
  }
  .ms-lg-23 {
    margin-left: 9rem !important;
  }
  .ms-lg-24 {
    margin-left: 9.5rem !important;
  }
  .ms-lg-25 {
    margin-left: 10rem !important;
  }
  .ms-lg-26 {
    margin-left: 10.5rem !important;
  }
  .ms-lg-27 {
    margin-left: 11rem !important;
  }
  .ms-lg-28 {
    margin-left: 12rem !important;
  }
  .ms-lg-29 {
    margin-left: 13rem !important;
  }
  .ms-lg-30 {
    margin-left: 14rem !important;
  }
  .ms-lg-31 {
    margin-left: 15rem !important;
  }
  .ms-lg-32 {
    margin-left: 16rem !important;
  }
  .ms-lg-33 {
    margin-left: 17rem !important;
  }
  .ms-lg-34 {
    margin-left: 18rem !important;
  }
  .ms-lg-35 {
    margin-left: 19rem !important;
  }
  .ms-lg-36 {
    margin-left: 20rem !important;
  }
  .ms-lg-37 {
    margin-left: 21rem !important;
  }
  .ms-lg-38 {
    margin-left: 22rem !important;
  }
  .ms-lg-39 {
    margin-left: 23rem !important;
  }
  .ms-lg-40 {
    margin-left: 24rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.35rem !important;
  }
  .p-lg-3 {
    padding: 0.55rem !important;
  }
  .p-lg-4 {
    padding: 0.75rem !important;
  }
  .p-lg-5 {
    padding: 1rem !important;
  }
  .p-lg-6 {
    padding: 1.25rem !important;
  }
  .p-lg-7 {
    padding: 1.5rem !important;
  }
  .p-lg-8 {
    padding: 1.75rem !important;
  }
  .p-lg-9 {
    padding: 2rem !important;
  }
  .p-lg-10 {
    padding: 2.5rem !important;
  }
  .p-lg-11 {
    padding: 3rem !important;
  }
  .p-lg-12 {
    padding: 3.5rem !important;
  }
  .p-lg-13 {
    padding: 4rem !important;
  }
  .p-lg-14 {
    padding: 4.5rem !important;
  }
  .p-lg-15 {
    padding: 5rem !important;
  }
  .p-lg-16 {
    padding: 5.5rem !important;
  }
  .p-lg-17 {
    padding: 6rem !important;
  }
  .p-lg-18 {
    padding: 6.5rem !important;
  }
  .p-lg-19 {
    padding: 7rem !important;
  }
  .p-lg-20 {
    padding: 7.5rem !important;
  }
  .p-lg-21 {
    padding: 8rem !important;
  }
  .p-lg-22 {
    padding: 8.5rem !important;
  }
  .p-lg-23 {
    padding: 9rem !important;
  }
  .p-lg-24 {
    padding: 9.5rem !important;
  }
  .p-lg-25 {
    padding: 10rem !important;
  }
  .p-lg-26 {
    padding: 10.5rem !important;
  }
  .p-lg-27 {
    padding: 11rem !important;
  }
  .p-lg-28 {
    padding: 12rem !important;
  }
  .p-lg-29 {
    padding: 13rem !important;
  }
  .p-lg-30 {
    padding: 14rem !important;
  }
  .p-lg-31 {
    padding: 15rem !important;
  }
  .p-lg-32 {
    padding: 16rem !important;
  }
  .p-lg-33 {
    padding: 17rem !important;
  }
  .p-lg-34 {
    padding: 18rem !important;
  }
  .p-lg-35 {
    padding: 19rem !important;
  }
  .p-lg-36 {
    padding: 20rem !important;
  }
  .p-lg-37 {
    padding: 21rem !important;
  }
  .p-lg-38 {
    padding: 22rem !important;
  }
  .p-lg-39 {
    padding: 23rem !important;
  }
  .p-lg-40 {
    padding: 24rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.35rem !important;
    padding-left: 0.35rem !important;
  }
  .px-lg-3 {
    padding-right: 0.55rem !important;
    padding-left: 0.55rem !important;
  }
  .px-lg-4 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-lg-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-6 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-8 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-lg-9 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-11 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-12 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-lg-13 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-14 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-lg-15 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-16 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-lg-17 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-18 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-lg-19 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-lg-20 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-lg-21 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-lg-22 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }
  .px-lg-23 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-lg-24 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }
  .px-lg-25 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-lg-26 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }
  .px-lg-27 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-lg-28 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-lg-29 {
    padding-right: 13rem !important;
    padding-left: 13rem !important;
  }
  .px-lg-30 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-lg-31 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .px-lg-32 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-lg-33 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-lg-34 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-lg-35 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-lg-36 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-lg-37 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-lg-38 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-lg-39 {
    padding-right: 23rem !important;
    padding-left: 23rem !important;
  }
  .px-lg-40 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.35rem !important;
    padding-bottom: 0.35rem !important;
  }
  .py-lg-3 {
    padding-top: 0.55rem !important;
    padding-bottom: 0.55rem !important;
  }
  .py-lg-4 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-lg-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-6 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-7 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-8 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-lg-9 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-11 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-12 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-13 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-14 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-lg-15 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-16 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-lg-17 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-18 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-lg-19 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-lg-20 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-lg-21 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-lg-22 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }
  .py-lg-23 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-lg-24 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }
  .py-lg-25 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-lg-26 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }
  .py-lg-27 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-lg-28 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-lg-29 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }
  .py-lg-30 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-lg-31 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .py-lg-32 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-lg-33 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-lg-34 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-lg-35 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-lg-36 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-lg-37 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-lg-38 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-lg-39 {
    padding-top: 23rem !important;
    padding-bottom: 23rem !important;
  }
  .py-lg-40 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.35rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.55rem !important;
  }
  .pt-lg-4 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-5 {
    padding-top: 1rem !important;
  }
  .pt-lg-6 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-7 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-8 {
    padding-top: 1.75rem !important;
  }
  .pt-lg-9 {
    padding-top: 2rem !important;
  }
  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-11 {
    padding-top: 3rem !important;
  }
  .pt-lg-12 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-13 {
    padding-top: 4rem !important;
  }
  .pt-lg-14 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-15 {
    padding-top: 5rem !important;
  }
  .pt-lg-16 {
    padding-top: 5.5rem !important;
  }
  .pt-lg-17 {
    padding-top: 6rem !important;
  }
  .pt-lg-18 {
    padding-top: 6.5rem !important;
  }
  .pt-lg-19 {
    padding-top: 7rem !important;
  }
  .pt-lg-20 {
    padding-top: 7.5rem !important;
  }
  .pt-lg-21 {
    padding-top: 8rem !important;
  }
  .pt-lg-22 {
    padding-top: 8.5rem !important;
  }
  .pt-lg-23 {
    padding-top: 9rem !important;
  }
  .pt-lg-24 {
    padding-top: 9.5rem !important;
  }
  .pt-lg-25 {
    padding-top: 10rem !important;
  }
  .pt-lg-26 {
    padding-top: 10.5rem !important;
  }
  .pt-lg-27 {
    padding-top: 11rem !important;
  }
  .pt-lg-28 {
    padding-top: 12rem !important;
  }
  .pt-lg-29 {
    padding-top: 13rem !important;
  }
  .pt-lg-30 {
    padding-top: 14rem !important;
  }
  .pt-lg-31 {
    padding-top: 15rem !important;
  }
  .pt-lg-32 {
    padding-top: 16rem !important;
  }
  .pt-lg-33 {
    padding-top: 17rem !important;
  }
  .pt-lg-34 {
    padding-top: 18rem !important;
  }
  .pt-lg-35 {
    padding-top: 19rem !important;
  }
  .pt-lg-36 {
    padding-top: 20rem !important;
  }
  .pt-lg-37 {
    padding-top: 21rem !important;
  }
  .pt-lg-38 {
    padding-top: 22rem !important;
  }
  .pt-lg-39 {
    padding-top: 23rem !important;
  }
  .pt-lg-40 {
    padding-top: 24rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.35rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.55rem !important;
  }
  .pe-lg-4 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-5 {
    padding-right: 1rem !important;
  }
  .pe-lg-6 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-7 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-8 {
    padding-right: 1.75rem !important;
  }
  .pe-lg-9 {
    padding-right: 2rem !important;
  }
  .pe-lg-10 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-11 {
    padding-right: 3rem !important;
  }
  .pe-lg-12 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-13 {
    padding-right: 4rem !important;
  }
  .pe-lg-14 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-15 {
    padding-right: 5rem !important;
  }
  .pe-lg-16 {
    padding-right: 5.5rem !important;
  }
  .pe-lg-17 {
    padding-right: 6rem !important;
  }
  .pe-lg-18 {
    padding-right: 6.5rem !important;
  }
  .pe-lg-19 {
    padding-right: 7rem !important;
  }
  .pe-lg-20 {
    padding-right: 7.5rem !important;
  }
  .pe-lg-21 {
    padding-right: 8rem !important;
  }
  .pe-lg-22 {
    padding-right: 8.5rem !important;
  }
  .pe-lg-23 {
    padding-right: 9rem !important;
  }
  .pe-lg-24 {
    padding-right: 9.5rem !important;
  }
  .pe-lg-25 {
    padding-right: 10rem !important;
  }
  .pe-lg-26 {
    padding-right: 10.5rem !important;
  }
  .pe-lg-27 {
    padding-right: 11rem !important;
  }
  .pe-lg-28 {
    padding-right: 12rem !important;
  }
  .pe-lg-29 {
    padding-right: 13rem !important;
  }
  .pe-lg-30 {
    padding-right: 14rem !important;
  }
  .pe-lg-31 {
    padding-right: 15rem !important;
  }
  .pe-lg-32 {
    padding-right: 16rem !important;
  }
  .pe-lg-33 {
    padding-right: 17rem !important;
  }
  .pe-lg-34 {
    padding-right: 18rem !important;
  }
  .pe-lg-35 {
    padding-right: 19rem !important;
  }
  .pe-lg-36 {
    padding-right: 20rem !important;
  }
  .pe-lg-37 {
    padding-right: 21rem !important;
  }
  .pe-lg-38 {
    padding-right: 22rem !important;
  }
  .pe-lg-39 {
    padding-right: 23rem !important;
  }
  .pe-lg-40 {
    padding-right: 24rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.35rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.55rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 1.75rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-16 {
    padding-bottom: 5.5rem !important;
  }
  .pb-lg-17 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-18 {
    padding-bottom: 6.5rem !important;
  }
  .pb-lg-19 {
    padding-bottom: 7rem !important;
  }
  .pb-lg-20 {
    padding-bottom: 7.5rem !important;
  }
  .pb-lg-21 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-22 {
    padding-bottom: 8.5rem !important;
  }
  .pb-lg-23 {
    padding-bottom: 9rem !important;
  }
  .pb-lg-24 {
    padding-bottom: 9.5rem !important;
  }
  .pb-lg-25 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-26 {
    padding-bottom: 10.5rem !important;
  }
  .pb-lg-27 {
    padding-bottom: 11rem !important;
  }
  .pb-lg-28 {
    padding-bottom: 12rem !important;
  }
  .pb-lg-29 {
    padding-bottom: 13rem !important;
  }
  .pb-lg-30 {
    padding-bottom: 14rem !important;
  }
  .pb-lg-31 {
    padding-bottom: 15rem !important;
  }
  .pb-lg-32 {
    padding-bottom: 16rem !important;
  }
  .pb-lg-33 {
    padding-bottom: 17rem !important;
  }
  .pb-lg-34 {
    padding-bottom: 18rem !important;
  }
  .pb-lg-35 {
    padding-bottom: 19rem !important;
  }
  .pb-lg-36 {
    padding-bottom: 20rem !important;
  }
  .pb-lg-37 {
    padding-bottom: 21rem !important;
  }
  .pb-lg-38 {
    padding-bottom: 22rem !important;
  }
  .pb-lg-39 {
    padding-bottom: 23rem !important;
  }
  .pb-lg-40 {
    padding-bottom: 24rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.35rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.55rem !important;
  }
  .ps-lg-4 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-5 {
    padding-left: 1rem !important;
  }
  .ps-lg-6 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-7 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-8 {
    padding-left: 1.75rem !important;
  }
  .ps-lg-9 {
    padding-left: 2rem !important;
  }
  .ps-lg-10 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-11 {
    padding-left: 3rem !important;
  }
  .ps-lg-12 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-13 {
    padding-left: 4rem !important;
  }
  .ps-lg-14 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-15 {
    padding-left: 5rem !important;
  }
  .ps-lg-16 {
    padding-left: 5.5rem !important;
  }
  .ps-lg-17 {
    padding-left: 6rem !important;
  }
  .ps-lg-18 {
    padding-left: 6.5rem !important;
  }
  .ps-lg-19 {
    padding-left: 7rem !important;
  }
  .ps-lg-20 {
    padding-left: 7.5rem !important;
  }
  .ps-lg-21 {
    padding-left: 8rem !important;
  }
  .ps-lg-22 {
    padding-left: 8.5rem !important;
  }
  .ps-lg-23 {
    padding-left: 9rem !important;
  }
  .ps-lg-24 {
    padding-left: 9.5rem !important;
  }
  .ps-lg-25 {
    padding-left: 10rem !important;
  }
  .ps-lg-26 {
    padding-left: 10.5rem !important;
  }
  .ps-lg-27 {
    padding-left: 11rem !important;
  }
  .ps-lg-28 {
    padding-left: 12rem !important;
  }
  .ps-lg-29 {
    padding-left: 13rem !important;
  }
  .ps-lg-30 {
    padding-left: 14rem !important;
  }
  .ps-lg-31 {
    padding-left: 15rem !important;
  }
  .ps-lg-32 {
    padding-left: 16rem !important;
  }
  .ps-lg-33 {
    padding-left: 17rem !important;
  }
  .ps-lg-34 {
    padding-left: 18rem !important;
  }
  .ps-lg-35 {
    padding-left: 19rem !important;
  }
  .ps-lg-36 {
    padding-left: 20rem !important;
  }
  .ps-lg-37 {
    padding-left: 21rem !important;
  }
  .ps-lg-38 {
    padding-left: 22rem !important;
  }
  .ps-lg-39 {
    padding-left: 23rem !important;
  }
  .ps-lg-40 {
    padding-left: 24rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.35rem !important;
  }
  .gap-lg-3 {
    gap: 0.55rem !important;
  }
  .gap-lg-4 {
    gap: 0.75rem !important;
  }
  .gap-lg-5 {
    gap: 1rem !important;
  }
  .gap-lg-6 {
    gap: 1.25rem !important;
  }
  .gap-lg-7 {
    gap: 1.5rem !important;
  }
  .gap-lg-8 {
    gap: 1.75rem !important;
  }
  .gap-lg-9 {
    gap: 2rem !important;
  }
  .gap-lg-10 {
    gap: 2.5rem !important;
  }
  .gap-lg-11 {
    gap: 3rem !important;
  }
  .gap-lg-12 {
    gap: 3.5rem !important;
  }
  .gap-lg-13 {
    gap: 4rem !important;
  }
  .gap-lg-14 {
    gap: 4.5rem !important;
  }
  .gap-lg-15 {
    gap: 5rem !important;
  }
  .gap-lg-16 {
    gap: 5.5rem !important;
  }
  .gap-lg-17 {
    gap: 6rem !important;
  }
  .gap-lg-18 {
    gap: 6.5rem !important;
  }
  .gap-lg-19 {
    gap: 7rem !important;
  }
  .gap-lg-20 {
    gap: 7.5rem !important;
  }
  .gap-lg-21 {
    gap: 8rem !important;
  }
  .gap-lg-22 {
    gap: 8.5rem !important;
  }
  .gap-lg-23 {
    gap: 9rem !important;
  }
  .gap-lg-24 {
    gap: 9.5rem !important;
  }
  .gap-lg-25 {
    gap: 10rem !important;
  }
  .gap-lg-26 {
    gap: 10.5rem !important;
  }
  .gap-lg-27 {
    gap: 11rem !important;
  }
  .gap-lg-28 {
    gap: 12rem !important;
  }
  .gap-lg-29 {
    gap: 13rem !important;
  }
  .gap-lg-30 {
    gap: 14rem !important;
  }
  .gap-lg-31 {
    gap: 15rem !important;
  }
  .gap-lg-32 {
    gap: 16rem !important;
  }
  .gap-lg-33 {
    gap: 17rem !important;
  }
  .gap-lg-34 {
    gap: 18rem !important;
  }
  .gap-lg-35 {
    gap: 19rem !important;
  }
  .gap-lg-36 {
    gap: 20rem !important;
  }
  .gap-lg-37 {
    gap: 21rem !important;
  }
  .gap-lg-38 {
    gap: 22rem !important;
  }
  .gap-lg-39 {
    gap: 23rem !important;
  }
  .gap-lg-40 {
    gap: 24rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.35rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 0.55rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 0.75rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 1.25rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 1.75rem !important;
  }
  .row-gap-lg-9 {
    row-gap: 2rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-lg-11 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-12 {
    row-gap: 3.5rem !important;
  }
  .row-gap-lg-13 {
    row-gap: 4rem !important;
  }
  .row-gap-lg-14 {
    row-gap: 4.5rem !important;
  }
  .row-gap-lg-15 {
    row-gap: 5rem !important;
  }
  .row-gap-lg-16 {
    row-gap: 5.5rem !important;
  }
  .row-gap-lg-17 {
    row-gap: 6rem !important;
  }
  .row-gap-lg-18 {
    row-gap: 6.5rem !important;
  }
  .row-gap-lg-19 {
    row-gap: 7rem !important;
  }
  .row-gap-lg-20 {
    row-gap: 7.5rem !important;
  }
  .row-gap-lg-21 {
    row-gap: 8rem !important;
  }
  .row-gap-lg-22 {
    row-gap: 8.5rem !important;
  }
  .row-gap-lg-23 {
    row-gap: 9rem !important;
  }
  .row-gap-lg-24 {
    row-gap: 9.5rem !important;
  }
  .row-gap-lg-25 {
    row-gap: 10rem !important;
  }
  .row-gap-lg-26 {
    row-gap: 10.5rem !important;
  }
  .row-gap-lg-27 {
    row-gap: 11rem !important;
  }
  .row-gap-lg-28 {
    row-gap: 12rem !important;
  }
  .row-gap-lg-29 {
    row-gap: 13rem !important;
  }
  .row-gap-lg-30 {
    row-gap: 14rem !important;
  }
  .row-gap-lg-31 {
    row-gap: 15rem !important;
  }
  .row-gap-lg-32 {
    row-gap: 16rem !important;
  }
  .row-gap-lg-33 {
    row-gap: 17rem !important;
  }
  .row-gap-lg-34 {
    row-gap: 18rem !important;
  }
  .row-gap-lg-35 {
    row-gap: 19rem !important;
  }
  .row-gap-lg-36 {
    row-gap: 20rem !important;
  }
  .row-gap-lg-37 {
    row-gap: 21rem !important;
  }
  .row-gap-lg-38 {
    row-gap: 22rem !important;
  }
  .row-gap-lg-39 {
    row-gap: 23rem !important;
  }
  .row-gap-lg-40 {
    row-gap: 24rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.35rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 0.55rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 0.75rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 1.25rem !important;
  }
  .column-gap-lg-7 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-8 {
    column-gap: 1.75rem !important;
  }
  .column-gap-lg-9 {
    column-gap: 2rem !important;
  }
  .column-gap-lg-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-lg-11 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-12 {
    column-gap: 3.5rem !important;
  }
  .column-gap-lg-13 {
    column-gap: 4rem !important;
  }
  .column-gap-lg-14 {
    column-gap: 4.5rem !important;
  }
  .column-gap-lg-15 {
    column-gap: 5rem !important;
  }
  .column-gap-lg-16 {
    column-gap: 5.5rem !important;
  }
  .column-gap-lg-17 {
    column-gap: 6rem !important;
  }
  .column-gap-lg-18 {
    column-gap: 6.5rem !important;
  }
  .column-gap-lg-19 {
    column-gap: 7rem !important;
  }
  .column-gap-lg-20 {
    column-gap: 7.5rem !important;
  }
  .column-gap-lg-21 {
    column-gap: 8rem !important;
  }
  .column-gap-lg-22 {
    column-gap: 8.5rem !important;
  }
  .column-gap-lg-23 {
    column-gap: 9rem !important;
  }
  .column-gap-lg-24 {
    column-gap: 9.5rem !important;
  }
  .column-gap-lg-25 {
    column-gap: 10rem !important;
  }
  .column-gap-lg-26 {
    column-gap: 10.5rem !important;
  }
  .column-gap-lg-27 {
    column-gap: 11rem !important;
  }
  .column-gap-lg-28 {
    column-gap: 12rem !important;
  }
  .column-gap-lg-29 {
    column-gap: 13rem !important;
  }
  .column-gap-lg-30 {
    column-gap: 14rem !important;
  }
  .column-gap-lg-31 {
    column-gap: 15rem !important;
  }
  .column-gap-lg-32 {
    column-gap: 16rem !important;
  }
  .column-gap-lg-33 {
    column-gap: 17rem !important;
  }
  .column-gap-lg-34 {
    column-gap: 18rem !important;
  }
  .column-gap-lg-35 {
    column-gap: 19rem !important;
  }
  .column-gap-lg-36 {
    column-gap: 20rem !important;
  }
  .column-gap-lg-37 {
    column-gap: 21rem !important;
  }
  .column-gap-lg-38 {
    column-gap: 22rem !important;
  }
  .column-gap-lg-39 {
    column-gap: 23rem !important;
  }
  .column-gap-lg-40 {
    column-gap: 24rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.35rem !important;
  }
  .m-xl-3 {
    margin: 0.55rem !important;
  }
  .m-xl-4 {
    margin: 0.75rem !important;
  }
  .m-xl-5 {
    margin: 1rem !important;
  }
  .m-xl-6 {
    margin: 1.25rem !important;
  }
  .m-xl-7 {
    margin: 1.5rem !important;
  }
  .m-xl-8 {
    margin: 1.75rem !important;
  }
  .m-xl-9 {
    margin: 2rem !important;
  }
  .m-xl-10 {
    margin: 2.5rem !important;
  }
  .m-xl-11 {
    margin: 3rem !important;
  }
  .m-xl-12 {
    margin: 3.5rem !important;
  }
  .m-xl-13 {
    margin: 4rem !important;
  }
  .m-xl-14 {
    margin: 4.5rem !important;
  }
  .m-xl-15 {
    margin: 5rem !important;
  }
  .m-xl-16 {
    margin: 5.5rem !important;
  }
  .m-xl-17 {
    margin: 6rem !important;
  }
  .m-xl-18 {
    margin: 6.5rem !important;
  }
  .m-xl-19 {
    margin: 7rem !important;
  }
  .m-xl-20 {
    margin: 7.5rem !important;
  }
  .m-xl-21 {
    margin: 8rem !important;
  }
  .m-xl-22 {
    margin: 8.5rem !important;
  }
  .m-xl-23 {
    margin: 9rem !important;
  }
  .m-xl-24 {
    margin: 9.5rem !important;
  }
  .m-xl-25 {
    margin: 10rem !important;
  }
  .m-xl-26 {
    margin: 10.5rem !important;
  }
  .m-xl-27 {
    margin: 11rem !important;
  }
  .m-xl-28 {
    margin: 12rem !important;
  }
  .m-xl-29 {
    margin: 13rem !important;
  }
  .m-xl-30 {
    margin: 14rem !important;
  }
  .m-xl-31 {
    margin: 15rem !important;
  }
  .m-xl-32 {
    margin: 16rem !important;
  }
  .m-xl-33 {
    margin: 17rem !important;
  }
  .m-xl-34 {
    margin: 18rem !important;
  }
  .m-xl-35 {
    margin: 19rem !important;
  }
  .m-xl-36 {
    margin: 20rem !important;
  }
  .m-xl-37 {
    margin: 21rem !important;
  }
  .m-xl-38 {
    margin: 22rem !important;
  }
  .m-xl-39 {
    margin: 23rem !important;
  }
  .m-xl-40 {
    margin: 24rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.35rem !important;
    margin-left: 0.35rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.55rem !important;
    margin-left: 0.55rem !important;
  }
  .mx-xl-4 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-5 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-6 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-7 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-8 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-xl-9 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-11 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-12 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xl-13 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-14 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-15 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-16 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-xl-17 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-18 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-xl-19 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xl-20 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xl-21 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xl-22 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }
  .mx-xl-23 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xl-24 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }
  .mx-xl-25 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-26 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }
  .mx-xl-27 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-xl-28 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xl-29 {
    margin-right: 13rem !important;
    margin-left: 13rem !important;
  }
  .mx-xl-30 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-xl-31 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-xl-32 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-xl-33 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-xl-34 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-xl-35 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-xl-36 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-xl-37 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-xl-38 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-xl-39 {
    margin-right: 23rem !important;
    margin-left: 23rem !important;
  }
  .mx-xl-40 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.35rem !important;
    margin-bottom: 0.35rem !important;
  }
  .my-xl-3 {
    margin-top: 0.55rem !important;
    margin-bottom: 0.55rem !important;
  }
  .my-xl-4 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-5 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-6 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-7 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-8 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-xl-9 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-11 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-12 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-13 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-14 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-15 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-16 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-xl-17 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-18 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-xl-19 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xl-20 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xl-21 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xl-22 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }
  .my-xl-23 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xl-24 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }
  .my-xl-25 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-26 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }
  .my-xl-27 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-xl-28 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xl-29 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }
  .my-xl-30 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-xl-31 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-xl-32 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-xl-33 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-xl-34 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-xl-35 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-xl-36 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-xl-37 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-xl-38 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-xl-39 {
    margin-top: 23rem !important;
    margin-bottom: 23rem !important;
  }
  .my-xl-40 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.35rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.55rem !important;
  }
  .mt-xl-4 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-5 {
    margin-top: 1rem !important;
  }
  .mt-xl-6 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-7 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-8 {
    margin-top: 1.75rem !important;
  }
  .mt-xl-9 {
    margin-top: 2rem !important;
  }
  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-11 {
    margin-top: 3rem !important;
  }
  .mt-xl-12 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-13 {
    margin-top: 4rem !important;
  }
  .mt-xl-14 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-15 {
    margin-top: 5rem !important;
  }
  .mt-xl-16 {
    margin-top: 5.5rem !important;
  }
  .mt-xl-17 {
    margin-top: 6rem !important;
  }
  .mt-xl-18 {
    margin-top: 6.5rem !important;
  }
  .mt-xl-19 {
    margin-top: 7rem !important;
  }
  .mt-xl-20 {
    margin-top: 7.5rem !important;
  }
  .mt-xl-21 {
    margin-top: 8rem !important;
  }
  .mt-xl-22 {
    margin-top: 8.5rem !important;
  }
  .mt-xl-23 {
    margin-top: 9rem !important;
  }
  .mt-xl-24 {
    margin-top: 9.5rem !important;
  }
  .mt-xl-25 {
    margin-top: 10rem !important;
  }
  .mt-xl-26 {
    margin-top: 10.5rem !important;
  }
  .mt-xl-27 {
    margin-top: 11rem !important;
  }
  .mt-xl-28 {
    margin-top: 12rem !important;
  }
  .mt-xl-29 {
    margin-top: 13rem !important;
  }
  .mt-xl-30 {
    margin-top: 14rem !important;
  }
  .mt-xl-31 {
    margin-top: 15rem !important;
  }
  .mt-xl-32 {
    margin-top: 16rem !important;
  }
  .mt-xl-33 {
    margin-top: 17rem !important;
  }
  .mt-xl-34 {
    margin-top: 18rem !important;
  }
  .mt-xl-35 {
    margin-top: 19rem !important;
  }
  .mt-xl-36 {
    margin-top: 20rem !important;
  }
  .mt-xl-37 {
    margin-top: 21rem !important;
  }
  .mt-xl-38 {
    margin-top: 22rem !important;
  }
  .mt-xl-39 {
    margin-top: 23rem !important;
  }
  .mt-xl-40 {
    margin-top: 24rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.35rem !important;
  }
  .me-xl-3 {
    margin-right: 0.55rem !important;
  }
  .me-xl-4 {
    margin-right: 0.75rem !important;
  }
  .me-xl-5 {
    margin-right: 1rem !important;
  }
  .me-xl-6 {
    margin-right: 1.25rem !important;
  }
  .me-xl-7 {
    margin-right: 1.5rem !important;
  }
  .me-xl-8 {
    margin-right: 1.75rem !important;
  }
  .me-xl-9 {
    margin-right: 2rem !important;
  }
  .me-xl-10 {
    margin-right: 2.5rem !important;
  }
  .me-xl-11 {
    margin-right: 3rem !important;
  }
  .me-xl-12 {
    margin-right: 3.5rem !important;
  }
  .me-xl-13 {
    margin-right: 4rem !important;
  }
  .me-xl-14 {
    margin-right: 4.5rem !important;
  }
  .me-xl-15 {
    margin-right: 5rem !important;
  }
  .me-xl-16 {
    margin-right: 5.5rem !important;
  }
  .me-xl-17 {
    margin-right: 6rem !important;
  }
  .me-xl-18 {
    margin-right: 6.5rem !important;
  }
  .me-xl-19 {
    margin-right: 7rem !important;
  }
  .me-xl-20 {
    margin-right: 7.5rem !important;
  }
  .me-xl-21 {
    margin-right: 8rem !important;
  }
  .me-xl-22 {
    margin-right: 8.5rem !important;
  }
  .me-xl-23 {
    margin-right: 9rem !important;
  }
  .me-xl-24 {
    margin-right: 9.5rem !important;
  }
  .me-xl-25 {
    margin-right: 10rem !important;
  }
  .me-xl-26 {
    margin-right: 10.5rem !important;
  }
  .me-xl-27 {
    margin-right: 11rem !important;
  }
  .me-xl-28 {
    margin-right: 12rem !important;
  }
  .me-xl-29 {
    margin-right: 13rem !important;
  }
  .me-xl-30 {
    margin-right: 14rem !important;
  }
  .me-xl-31 {
    margin-right: 15rem !important;
  }
  .me-xl-32 {
    margin-right: 16rem !important;
  }
  .me-xl-33 {
    margin-right: 17rem !important;
  }
  .me-xl-34 {
    margin-right: 18rem !important;
  }
  .me-xl-35 {
    margin-right: 19rem !important;
  }
  .me-xl-36 {
    margin-right: 20rem !important;
  }
  .me-xl-37 {
    margin-right: 21rem !important;
  }
  .me-xl-38 {
    margin-right: 22rem !important;
  }
  .me-xl-39 {
    margin-right: 23rem !important;
  }
  .me-xl-40 {
    margin-right: 24rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.35rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.55rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 1.75rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-16 {
    margin-bottom: 5.5rem !important;
  }
  .mb-xl-17 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-18 {
    margin-bottom: 6.5rem !important;
  }
  .mb-xl-19 {
    margin-bottom: 7rem !important;
  }
  .mb-xl-20 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xl-21 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-22 {
    margin-bottom: 8.5rem !important;
  }
  .mb-xl-23 {
    margin-bottom: 9rem !important;
  }
  .mb-xl-24 {
    margin-bottom: 9.5rem !important;
  }
  .mb-xl-25 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-26 {
    margin-bottom: 10.5rem !important;
  }
  .mb-xl-27 {
    margin-bottom: 11rem !important;
  }
  .mb-xl-28 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-29 {
    margin-bottom: 13rem !important;
  }
  .mb-xl-30 {
    margin-bottom: 14rem !important;
  }
  .mb-xl-31 {
    margin-bottom: 15rem !important;
  }
  .mb-xl-32 {
    margin-bottom: 16rem !important;
  }
  .mb-xl-33 {
    margin-bottom: 17rem !important;
  }
  .mb-xl-34 {
    margin-bottom: 18rem !important;
  }
  .mb-xl-35 {
    margin-bottom: 19rem !important;
  }
  .mb-xl-36 {
    margin-bottom: 20rem !important;
  }
  .mb-xl-37 {
    margin-bottom: 21rem !important;
  }
  .mb-xl-38 {
    margin-bottom: 22rem !important;
  }
  .mb-xl-39 {
    margin-bottom: 23rem !important;
  }
  .mb-xl-40 {
    margin-bottom: 24rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.35rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.55rem !important;
  }
  .ms-xl-4 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-5 {
    margin-left: 1rem !important;
  }
  .ms-xl-6 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-7 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-8 {
    margin-left: 1.75rem !important;
  }
  .ms-xl-9 {
    margin-left: 2rem !important;
  }
  .ms-xl-10 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-11 {
    margin-left: 3rem !important;
  }
  .ms-xl-12 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-13 {
    margin-left: 4rem !important;
  }
  .ms-xl-14 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-15 {
    margin-left: 5rem !important;
  }
  .ms-xl-16 {
    margin-left: 5.5rem !important;
  }
  .ms-xl-17 {
    margin-left: 6rem !important;
  }
  .ms-xl-18 {
    margin-left: 6.5rem !important;
  }
  .ms-xl-19 {
    margin-left: 7rem !important;
  }
  .ms-xl-20 {
    margin-left: 7.5rem !important;
  }
  .ms-xl-21 {
    margin-left: 8rem !important;
  }
  .ms-xl-22 {
    margin-left: 8.5rem !important;
  }
  .ms-xl-23 {
    margin-left: 9rem !important;
  }
  .ms-xl-24 {
    margin-left: 9.5rem !important;
  }
  .ms-xl-25 {
    margin-left: 10rem !important;
  }
  .ms-xl-26 {
    margin-left: 10.5rem !important;
  }
  .ms-xl-27 {
    margin-left: 11rem !important;
  }
  .ms-xl-28 {
    margin-left: 12rem !important;
  }
  .ms-xl-29 {
    margin-left: 13rem !important;
  }
  .ms-xl-30 {
    margin-left: 14rem !important;
  }
  .ms-xl-31 {
    margin-left: 15rem !important;
  }
  .ms-xl-32 {
    margin-left: 16rem !important;
  }
  .ms-xl-33 {
    margin-left: 17rem !important;
  }
  .ms-xl-34 {
    margin-left: 18rem !important;
  }
  .ms-xl-35 {
    margin-left: 19rem !important;
  }
  .ms-xl-36 {
    margin-left: 20rem !important;
  }
  .ms-xl-37 {
    margin-left: 21rem !important;
  }
  .ms-xl-38 {
    margin-left: 22rem !important;
  }
  .ms-xl-39 {
    margin-left: 23rem !important;
  }
  .ms-xl-40 {
    margin-left: 24rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.35rem !important;
  }
  .p-xl-3 {
    padding: 0.55rem !important;
  }
  .p-xl-4 {
    padding: 0.75rem !important;
  }
  .p-xl-5 {
    padding: 1rem !important;
  }
  .p-xl-6 {
    padding: 1.25rem !important;
  }
  .p-xl-7 {
    padding: 1.5rem !important;
  }
  .p-xl-8 {
    padding: 1.75rem !important;
  }
  .p-xl-9 {
    padding: 2rem !important;
  }
  .p-xl-10 {
    padding: 2.5rem !important;
  }
  .p-xl-11 {
    padding: 3rem !important;
  }
  .p-xl-12 {
    padding: 3.5rem !important;
  }
  .p-xl-13 {
    padding: 4rem !important;
  }
  .p-xl-14 {
    padding: 4.5rem !important;
  }
  .p-xl-15 {
    padding: 5rem !important;
  }
  .p-xl-16 {
    padding: 5.5rem !important;
  }
  .p-xl-17 {
    padding: 6rem !important;
  }
  .p-xl-18 {
    padding: 6.5rem !important;
  }
  .p-xl-19 {
    padding: 7rem !important;
  }
  .p-xl-20 {
    padding: 7.5rem !important;
  }
  .p-xl-21 {
    padding: 8rem !important;
  }
  .p-xl-22 {
    padding: 8.5rem !important;
  }
  .p-xl-23 {
    padding: 9rem !important;
  }
  .p-xl-24 {
    padding: 9.5rem !important;
  }
  .p-xl-25 {
    padding: 10rem !important;
  }
  .p-xl-26 {
    padding: 10.5rem !important;
  }
  .p-xl-27 {
    padding: 11rem !important;
  }
  .p-xl-28 {
    padding: 12rem !important;
  }
  .p-xl-29 {
    padding: 13rem !important;
  }
  .p-xl-30 {
    padding: 14rem !important;
  }
  .p-xl-31 {
    padding: 15rem !important;
  }
  .p-xl-32 {
    padding: 16rem !important;
  }
  .p-xl-33 {
    padding: 17rem !important;
  }
  .p-xl-34 {
    padding: 18rem !important;
  }
  .p-xl-35 {
    padding: 19rem !important;
  }
  .p-xl-36 {
    padding: 20rem !important;
  }
  .p-xl-37 {
    padding: 21rem !important;
  }
  .p-xl-38 {
    padding: 22rem !important;
  }
  .p-xl-39 {
    padding: 23rem !important;
  }
  .p-xl-40 {
    padding: 24rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.35rem !important;
    padding-left: 0.35rem !important;
  }
  .px-xl-3 {
    padding-right: 0.55rem !important;
    padding-left: 0.55rem !important;
  }
  .px-xl-4 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xl-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-6 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-8 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-xl-9 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-11 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-12 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xl-13 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-14 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xl-15 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-16 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-xl-17 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-18 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-xl-19 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xl-20 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xl-21 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xl-22 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }
  .px-xl-23 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xl-24 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }
  .px-xl-25 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xl-26 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }
  .px-xl-27 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-xl-28 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xl-29 {
    padding-right: 13rem !important;
    padding-left: 13rem !important;
  }
  .px-xl-30 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-xl-31 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .px-xl-32 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-xl-33 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-xl-34 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-xl-35 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-xl-36 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-xl-37 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-xl-38 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-xl-39 {
    padding-right: 23rem !important;
    padding-left: 23rem !important;
  }
  .px-xl-40 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.35rem !important;
    padding-bottom: 0.35rem !important;
  }
  .py-xl-3 {
    padding-top: 0.55rem !important;
    padding-bottom: 0.55rem !important;
  }
  .py-xl-4 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xl-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-6 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-7 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-8 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-xl-9 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-11 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-12 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-13 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-14 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xl-15 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-16 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-xl-17 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-18 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-xl-19 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xl-20 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xl-21 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xl-22 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }
  .py-xl-23 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xl-24 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }
  .py-xl-25 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xl-26 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }
  .py-xl-27 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-xl-28 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xl-29 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }
  .py-xl-30 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-xl-31 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .py-xl-32 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-xl-33 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-xl-34 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-xl-35 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-xl-36 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-xl-37 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-xl-38 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-xl-39 {
    padding-top: 23rem !important;
    padding-bottom: 23rem !important;
  }
  .py-xl-40 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.35rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.55rem !important;
  }
  .pt-xl-4 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-5 {
    padding-top: 1rem !important;
  }
  .pt-xl-6 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-7 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-8 {
    padding-top: 1.75rem !important;
  }
  .pt-xl-9 {
    padding-top: 2rem !important;
  }
  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-11 {
    padding-top: 3rem !important;
  }
  .pt-xl-12 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-13 {
    padding-top: 4rem !important;
  }
  .pt-xl-14 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-15 {
    padding-top: 5rem !important;
  }
  .pt-xl-16 {
    padding-top: 5.5rem !important;
  }
  .pt-xl-17 {
    padding-top: 6rem !important;
  }
  .pt-xl-18 {
    padding-top: 6.5rem !important;
  }
  .pt-xl-19 {
    padding-top: 7rem !important;
  }
  .pt-xl-20 {
    padding-top: 7.5rem !important;
  }
  .pt-xl-21 {
    padding-top: 8rem !important;
  }
  .pt-xl-22 {
    padding-top: 8.5rem !important;
  }
  .pt-xl-23 {
    padding-top: 9rem !important;
  }
  .pt-xl-24 {
    padding-top: 9.5rem !important;
  }
  .pt-xl-25 {
    padding-top: 10rem !important;
  }
  .pt-xl-26 {
    padding-top: 10.5rem !important;
  }
  .pt-xl-27 {
    padding-top: 11rem !important;
  }
  .pt-xl-28 {
    padding-top: 12rem !important;
  }
  .pt-xl-29 {
    padding-top: 13rem !important;
  }
  .pt-xl-30 {
    padding-top: 14rem !important;
  }
  .pt-xl-31 {
    padding-top: 15rem !important;
  }
  .pt-xl-32 {
    padding-top: 16rem !important;
  }
  .pt-xl-33 {
    padding-top: 17rem !important;
  }
  .pt-xl-34 {
    padding-top: 18rem !important;
  }
  .pt-xl-35 {
    padding-top: 19rem !important;
  }
  .pt-xl-36 {
    padding-top: 20rem !important;
  }
  .pt-xl-37 {
    padding-top: 21rem !important;
  }
  .pt-xl-38 {
    padding-top: 22rem !important;
  }
  .pt-xl-39 {
    padding-top: 23rem !important;
  }
  .pt-xl-40 {
    padding-top: 24rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.35rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.55rem !important;
  }
  .pe-xl-4 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-5 {
    padding-right: 1rem !important;
  }
  .pe-xl-6 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-7 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-8 {
    padding-right: 1.75rem !important;
  }
  .pe-xl-9 {
    padding-right: 2rem !important;
  }
  .pe-xl-10 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-11 {
    padding-right: 3rem !important;
  }
  .pe-xl-12 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-13 {
    padding-right: 4rem !important;
  }
  .pe-xl-14 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-15 {
    padding-right: 5rem !important;
  }
  .pe-xl-16 {
    padding-right: 5.5rem !important;
  }
  .pe-xl-17 {
    padding-right: 6rem !important;
  }
  .pe-xl-18 {
    padding-right: 6.5rem !important;
  }
  .pe-xl-19 {
    padding-right: 7rem !important;
  }
  .pe-xl-20 {
    padding-right: 7.5rem !important;
  }
  .pe-xl-21 {
    padding-right: 8rem !important;
  }
  .pe-xl-22 {
    padding-right: 8.5rem !important;
  }
  .pe-xl-23 {
    padding-right: 9rem !important;
  }
  .pe-xl-24 {
    padding-right: 9.5rem !important;
  }
  .pe-xl-25 {
    padding-right: 10rem !important;
  }
  .pe-xl-26 {
    padding-right: 10.5rem !important;
  }
  .pe-xl-27 {
    padding-right: 11rem !important;
  }
  .pe-xl-28 {
    padding-right: 12rem !important;
  }
  .pe-xl-29 {
    padding-right: 13rem !important;
  }
  .pe-xl-30 {
    padding-right: 14rem !important;
  }
  .pe-xl-31 {
    padding-right: 15rem !important;
  }
  .pe-xl-32 {
    padding-right: 16rem !important;
  }
  .pe-xl-33 {
    padding-right: 17rem !important;
  }
  .pe-xl-34 {
    padding-right: 18rem !important;
  }
  .pe-xl-35 {
    padding-right: 19rem !important;
  }
  .pe-xl-36 {
    padding-right: 20rem !important;
  }
  .pe-xl-37 {
    padding-right: 21rem !important;
  }
  .pe-xl-38 {
    padding-right: 22rem !important;
  }
  .pe-xl-39 {
    padding-right: 23rem !important;
  }
  .pe-xl-40 {
    padding-right: 24rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.35rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.55rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 1.75rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-13 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-14 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-15 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-16 {
    padding-bottom: 5.5rem !important;
  }
  .pb-xl-17 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-18 {
    padding-bottom: 6.5rem !important;
  }
  .pb-xl-19 {
    padding-bottom: 7rem !important;
  }
  .pb-xl-20 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xl-21 {
    padding-bottom: 8rem !important;
  }
  .pb-xl-22 {
    padding-bottom: 8.5rem !important;
  }
  .pb-xl-23 {
    padding-bottom: 9rem !important;
  }
  .pb-xl-24 {
    padding-bottom: 9.5rem !important;
  }
  .pb-xl-25 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-26 {
    padding-bottom: 10.5rem !important;
  }
  .pb-xl-27 {
    padding-bottom: 11rem !important;
  }
  .pb-xl-28 {
    padding-bottom: 12rem !important;
  }
  .pb-xl-29 {
    padding-bottom: 13rem !important;
  }
  .pb-xl-30 {
    padding-bottom: 14rem !important;
  }
  .pb-xl-31 {
    padding-bottom: 15rem !important;
  }
  .pb-xl-32 {
    padding-bottom: 16rem !important;
  }
  .pb-xl-33 {
    padding-bottom: 17rem !important;
  }
  .pb-xl-34 {
    padding-bottom: 18rem !important;
  }
  .pb-xl-35 {
    padding-bottom: 19rem !important;
  }
  .pb-xl-36 {
    padding-bottom: 20rem !important;
  }
  .pb-xl-37 {
    padding-bottom: 21rem !important;
  }
  .pb-xl-38 {
    padding-bottom: 22rem !important;
  }
  .pb-xl-39 {
    padding-bottom: 23rem !important;
  }
  .pb-xl-40 {
    padding-bottom: 24rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.35rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.55rem !important;
  }
  .ps-xl-4 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-5 {
    padding-left: 1rem !important;
  }
  .ps-xl-6 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-7 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-8 {
    padding-left: 1.75rem !important;
  }
  .ps-xl-9 {
    padding-left: 2rem !important;
  }
  .ps-xl-10 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-11 {
    padding-left: 3rem !important;
  }
  .ps-xl-12 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-13 {
    padding-left: 4rem !important;
  }
  .ps-xl-14 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-15 {
    padding-left: 5rem !important;
  }
  .ps-xl-16 {
    padding-left: 5.5rem !important;
  }
  .ps-xl-17 {
    padding-left: 6rem !important;
  }
  .ps-xl-18 {
    padding-left: 6.5rem !important;
  }
  .ps-xl-19 {
    padding-left: 7rem !important;
  }
  .ps-xl-20 {
    padding-left: 7.5rem !important;
  }
  .ps-xl-21 {
    padding-left: 8rem !important;
  }
  .ps-xl-22 {
    padding-left: 8.5rem !important;
  }
  .ps-xl-23 {
    padding-left: 9rem !important;
  }
  .ps-xl-24 {
    padding-left: 9.5rem !important;
  }
  .ps-xl-25 {
    padding-left: 10rem !important;
  }
  .ps-xl-26 {
    padding-left: 10.5rem !important;
  }
  .ps-xl-27 {
    padding-left: 11rem !important;
  }
  .ps-xl-28 {
    padding-left: 12rem !important;
  }
  .ps-xl-29 {
    padding-left: 13rem !important;
  }
  .ps-xl-30 {
    padding-left: 14rem !important;
  }
  .ps-xl-31 {
    padding-left: 15rem !important;
  }
  .ps-xl-32 {
    padding-left: 16rem !important;
  }
  .ps-xl-33 {
    padding-left: 17rem !important;
  }
  .ps-xl-34 {
    padding-left: 18rem !important;
  }
  .ps-xl-35 {
    padding-left: 19rem !important;
  }
  .ps-xl-36 {
    padding-left: 20rem !important;
  }
  .ps-xl-37 {
    padding-left: 21rem !important;
  }
  .ps-xl-38 {
    padding-left: 22rem !important;
  }
  .ps-xl-39 {
    padding-left: 23rem !important;
  }
  .ps-xl-40 {
    padding-left: 24rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.35rem !important;
  }
  .gap-xl-3 {
    gap: 0.55rem !important;
  }
  .gap-xl-4 {
    gap: 0.75rem !important;
  }
  .gap-xl-5 {
    gap: 1rem !important;
  }
  .gap-xl-6 {
    gap: 1.25rem !important;
  }
  .gap-xl-7 {
    gap: 1.5rem !important;
  }
  .gap-xl-8 {
    gap: 1.75rem !important;
  }
  .gap-xl-9 {
    gap: 2rem !important;
  }
  .gap-xl-10 {
    gap: 2.5rem !important;
  }
  .gap-xl-11 {
    gap: 3rem !important;
  }
  .gap-xl-12 {
    gap: 3.5rem !important;
  }
  .gap-xl-13 {
    gap: 4rem !important;
  }
  .gap-xl-14 {
    gap: 4.5rem !important;
  }
  .gap-xl-15 {
    gap: 5rem !important;
  }
  .gap-xl-16 {
    gap: 5.5rem !important;
  }
  .gap-xl-17 {
    gap: 6rem !important;
  }
  .gap-xl-18 {
    gap: 6.5rem !important;
  }
  .gap-xl-19 {
    gap: 7rem !important;
  }
  .gap-xl-20 {
    gap: 7.5rem !important;
  }
  .gap-xl-21 {
    gap: 8rem !important;
  }
  .gap-xl-22 {
    gap: 8.5rem !important;
  }
  .gap-xl-23 {
    gap: 9rem !important;
  }
  .gap-xl-24 {
    gap: 9.5rem !important;
  }
  .gap-xl-25 {
    gap: 10rem !important;
  }
  .gap-xl-26 {
    gap: 10.5rem !important;
  }
  .gap-xl-27 {
    gap: 11rem !important;
  }
  .gap-xl-28 {
    gap: 12rem !important;
  }
  .gap-xl-29 {
    gap: 13rem !important;
  }
  .gap-xl-30 {
    gap: 14rem !important;
  }
  .gap-xl-31 {
    gap: 15rem !important;
  }
  .gap-xl-32 {
    gap: 16rem !important;
  }
  .gap-xl-33 {
    gap: 17rem !important;
  }
  .gap-xl-34 {
    gap: 18rem !important;
  }
  .gap-xl-35 {
    gap: 19rem !important;
  }
  .gap-xl-36 {
    gap: 20rem !important;
  }
  .gap-xl-37 {
    gap: 21rem !important;
  }
  .gap-xl-38 {
    gap: 22rem !important;
  }
  .gap-xl-39 {
    gap: 23rem !important;
  }
  .gap-xl-40 {
    gap: 24rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.35rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 0.55rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 1.75rem !important;
  }
  .row-gap-xl-9 {
    row-gap: 2rem !important;
  }
  .row-gap-xl-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xl-11 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-12 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xl-13 {
    row-gap: 4rem !important;
  }
  .row-gap-xl-14 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xl-15 {
    row-gap: 5rem !important;
  }
  .row-gap-xl-16 {
    row-gap: 5.5rem !important;
  }
  .row-gap-xl-17 {
    row-gap: 6rem !important;
  }
  .row-gap-xl-18 {
    row-gap: 6.5rem !important;
  }
  .row-gap-xl-19 {
    row-gap: 7rem !important;
  }
  .row-gap-xl-20 {
    row-gap: 7.5rem !important;
  }
  .row-gap-xl-21 {
    row-gap: 8rem !important;
  }
  .row-gap-xl-22 {
    row-gap: 8.5rem !important;
  }
  .row-gap-xl-23 {
    row-gap: 9rem !important;
  }
  .row-gap-xl-24 {
    row-gap: 9.5rem !important;
  }
  .row-gap-xl-25 {
    row-gap: 10rem !important;
  }
  .row-gap-xl-26 {
    row-gap: 10.5rem !important;
  }
  .row-gap-xl-27 {
    row-gap: 11rem !important;
  }
  .row-gap-xl-28 {
    row-gap: 12rem !important;
  }
  .row-gap-xl-29 {
    row-gap: 13rem !important;
  }
  .row-gap-xl-30 {
    row-gap: 14rem !important;
  }
  .row-gap-xl-31 {
    row-gap: 15rem !important;
  }
  .row-gap-xl-32 {
    row-gap: 16rem !important;
  }
  .row-gap-xl-33 {
    row-gap: 17rem !important;
  }
  .row-gap-xl-34 {
    row-gap: 18rem !important;
  }
  .row-gap-xl-35 {
    row-gap: 19rem !important;
  }
  .row-gap-xl-36 {
    row-gap: 20rem !important;
  }
  .row-gap-xl-37 {
    row-gap: 21rem !important;
  }
  .row-gap-xl-38 {
    row-gap: 22rem !important;
  }
  .row-gap-xl-39 {
    row-gap: 23rem !important;
  }
  .row-gap-xl-40 {
    row-gap: 24rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.35rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 0.55rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 1.25rem !important;
  }
  .column-gap-xl-7 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-8 {
    column-gap: 1.75rem !important;
  }
  .column-gap-xl-9 {
    column-gap: 2rem !important;
  }
  .column-gap-xl-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-xl-11 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-12 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xl-13 {
    column-gap: 4rem !important;
  }
  .column-gap-xl-14 {
    column-gap: 4.5rem !important;
  }
  .column-gap-xl-15 {
    column-gap: 5rem !important;
  }
  .column-gap-xl-16 {
    column-gap: 5.5rem !important;
  }
  .column-gap-xl-17 {
    column-gap: 6rem !important;
  }
  .column-gap-xl-18 {
    column-gap: 6.5rem !important;
  }
  .column-gap-xl-19 {
    column-gap: 7rem !important;
  }
  .column-gap-xl-20 {
    column-gap: 7.5rem !important;
  }
  .column-gap-xl-21 {
    column-gap: 8rem !important;
  }
  .column-gap-xl-22 {
    column-gap: 8.5rem !important;
  }
  .column-gap-xl-23 {
    column-gap: 9rem !important;
  }
  .column-gap-xl-24 {
    column-gap: 9.5rem !important;
  }
  .column-gap-xl-25 {
    column-gap: 10rem !important;
  }
  .column-gap-xl-26 {
    column-gap: 10.5rem !important;
  }
  .column-gap-xl-27 {
    column-gap: 11rem !important;
  }
  .column-gap-xl-28 {
    column-gap: 12rem !important;
  }
  .column-gap-xl-29 {
    column-gap: 13rem !important;
  }
  .column-gap-xl-30 {
    column-gap: 14rem !important;
  }
  .column-gap-xl-31 {
    column-gap: 15rem !important;
  }
  .column-gap-xl-32 {
    column-gap: 16rem !important;
  }
  .column-gap-xl-33 {
    column-gap: 17rem !important;
  }
  .column-gap-xl-34 {
    column-gap: 18rem !important;
  }
  .column-gap-xl-35 {
    column-gap: 19rem !important;
  }
  .column-gap-xl-36 {
    column-gap: 20rem !important;
  }
  .column-gap-xl-37 {
    column-gap: 21rem !important;
  }
  .column-gap-xl-38 {
    column-gap: 22rem !important;
  }
  .column-gap-xl-39 {
    column-gap: 23rem !important;
  }
  .column-gap-xl-40 {
    column-gap: 24rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1380px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.35rem !important;
  }
  .m-xxl-3 {
    margin: 0.55rem !important;
  }
  .m-xxl-4 {
    margin: 0.75rem !important;
  }
  .m-xxl-5 {
    margin: 1rem !important;
  }
  .m-xxl-6 {
    margin: 1.25rem !important;
  }
  .m-xxl-7 {
    margin: 1.5rem !important;
  }
  .m-xxl-8 {
    margin: 1.75rem !important;
  }
  .m-xxl-9 {
    margin: 2rem !important;
  }
  .m-xxl-10 {
    margin: 2.5rem !important;
  }
  .m-xxl-11 {
    margin: 3rem !important;
  }
  .m-xxl-12 {
    margin: 3.5rem !important;
  }
  .m-xxl-13 {
    margin: 4rem !important;
  }
  .m-xxl-14 {
    margin: 4.5rem !important;
  }
  .m-xxl-15 {
    margin: 5rem !important;
  }
  .m-xxl-16 {
    margin: 5.5rem !important;
  }
  .m-xxl-17 {
    margin: 6rem !important;
  }
  .m-xxl-18 {
    margin: 6.5rem !important;
  }
  .m-xxl-19 {
    margin: 7rem !important;
  }
  .m-xxl-20 {
    margin: 7.5rem !important;
  }
  .m-xxl-21 {
    margin: 8rem !important;
  }
  .m-xxl-22 {
    margin: 8.5rem !important;
  }
  .m-xxl-23 {
    margin: 9rem !important;
  }
  .m-xxl-24 {
    margin: 9.5rem !important;
  }
  .m-xxl-25 {
    margin: 10rem !important;
  }
  .m-xxl-26 {
    margin: 10.5rem !important;
  }
  .m-xxl-27 {
    margin: 11rem !important;
  }
  .m-xxl-28 {
    margin: 12rem !important;
  }
  .m-xxl-29 {
    margin: 13rem !important;
  }
  .m-xxl-30 {
    margin: 14rem !important;
  }
  .m-xxl-31 {
    margin: 15rem !important;
  }
  .m-xxl-32 {
    margin: 16rem !important;
  }
  .m-xxl-33 {
    margin: 17rem !important;
  }
  .m-xxl-34 {
    margin: 18rem !important;
  }
  .m-xxl-35 {
    margin: 19rem !important;
  }
  .m-xxl-36 {
    margin: 20rem !important;
  }
  .m-xxl-37 {
    margin: 21rem !important;
  }
  .m-xxl-38 {
    margin: 22rem !important;
  }
  .m-xxl-39 {
    margin: 23rem !important;
  }
  .m-xxl-40 {
    margin: 24rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.35rem !important;
    margin-left: 0.35rem !important;
  }
  .mx-xxl-3 {
    margin-right: 0.55rem !important;
    margin-left: 0.55rem !important;
  }
  .mx-xxl-4 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xxl-5 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-6 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-7 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-8 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-xxl-9 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-11 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-12 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xxl-13 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-14 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-15 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-16 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important;
  }
  .mx-xxl-17 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-18 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-xxl-19 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xxl-20 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xxl-21 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xxl-22 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important;
  }
  .mx-xxl-23 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xxl-24 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important;
  }
  .mx-xxl-25 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-26 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important;
  }
  .mx-xxl-27 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-xxl-28 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xxl-29 {
    margin-right: 13rem !important;
    margin-left: 13rem !important;
  }
  .mx-xxl-30 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-xxl-31 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-xxl-32 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-xxl-33 {
    margin-right: 17rem !important;
    margin-left: 17rem !important;
  }
  .mx-xxl-34 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-xxl-35 {
    margin-right: 19rem !important;
    margin-left: 19rem !important;
  }
  .mx-xxl-36 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-xxl-37 {
    margin-right: 21rem !important;
    margin-left: 21rem !important;
  }
  .mx-xxl-38 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-xxl-39 {
    margin-right: 23rem !important;
    margin-left: 23rem !important;
  }
  .mx-xxl-40 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.35rem !important;
    margin-bottom: 0.35rem !important;
  }
  .my-xxl-3 {
    margin-top: 0.55rem !important;
    margin-bottom: 0.55rem !important;
  }
  .my-xxl-4 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xxl-5 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-6 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-7 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-8 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-xxl-9 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-11 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-12 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-13 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-14 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-15 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-16 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }
  .my-xxl-17 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-18 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-xxl-19 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xxl-20 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xxl-21 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xxl-22 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important;
  }
  .my-xxl-23 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xxl-24 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important;
  }
  .my-xxl-25 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-26 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important;
  }
  .my-xxl-27 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-xxl-28 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xxl-29 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }
  .my-xxl-30 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-xxl-31 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-xxl-32 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-xxl-33 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important;
  }
  .my-xxl-34 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-xxl-35 {
    margin-top: 19rem !important;
    margin-bottom: 19rem !important;
  }
  .my-xxl-36 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-xxl-37 {
    margin-top: 21rem !important;
    margin-bottom: 21rem !important;
  }
  .my-xxl-38 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-xxl-39 {
    margin-top: 23rem !important;
    margin-bottom: 23rem !important;
  }
  .my-xxl-40 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.35rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.55rem !important;
  }
  .mt-xxl-4 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-5 {
    margin-top: 1rem !important;
  }
  .mt-xxl-6 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-7 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-8 {
    margin-top: 1.75rem !important;
  }
  .mt-xxl-9 {
    margin-top: 2rem !important;
  }
  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-11 {
    margin-top: 3rem !important;
  }
  .mt-xxl-12 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-13 {
    margin-top: 4rem !important;
  }
  .mt-xxl-14 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-15 {
    margin-top: 5rem !important;
  }
  .mt-xxl-16 {
    margin-top: 5.5rem !important;
  }
  .mt-xxl-17 {
    margin-top: 6rem !important;
  }
  .mt-xxl-18 {
    margin-top: 6.5rem !important;
  }
  .mt-xxl-19 {
    margin-top: 7rem !important;
  }
  .mt-xxl-20 {
    margin-top: 7.5rem !important;
  }
  .mt-xxl-21 {
    margin-top: 8rem !important;
  }
  .mt-xxl-22 {
    margin-top: 8.5rem !important;
  }
  .mt-xxl-23 {
    margin-top: 9rem !important;
  }
  .mt-xxl-24 {
    margin-top: 9.5rem !important;
  }
  .mt-xxl-25 {
    margin-top: 10rem !important;
  }
  .mt-xxl-26 {
    margin-top: 10.5rem !important;
  }
  .mt-xxl-27 {
    margin-top: 11rem !important;
  }
  .mt-xxl-28 {
    margin-top: 12rem !important;
  }
  .mt-xxl-29 {
    margin-top: 13rem !important;
  }
  .mt-xxl-30 {
    margin-top: 14rem !important;
  }
  .mt-xxl-31 {
    margin-top: 15rem !important;
  }
  .mt-xxl-32 {
    margin-top: 16rem !important;
  }
  .mt-xxl-33 {
    margin-top: 17rem !important;
  }
  .mt-xxl-34 {
    margin-top: 18rem !important;
  }
  .mt-xxl-35 {
    margin-top: 19rem !important;
  }
  .mt-xxl-36 {
    margin-top: 20rem !important;
  }
  .mt-xxl-37 {
    margin-top: 21rem !important;
  }
  .mt-xxl-38 {
    margin-top: 22rem !important;
  }
  .mt-xxl-39 {
    margin-top: 23rem !important;
  }
  .mt-xxl-40 {
    margin-top: 24rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.35rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.55rem !important;
  }
  .me-xxl-4 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-5 {
    margin-right: 1rem !important;
  }
  .me-xxl-6 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-7 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-8 {
    margin-right: 1.75rem !important;
  }
  .me-xxl-9 {
    margin-right: 2rem !important;
  }
  .me-xxl-10 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-11 {
    margin-right: 3rem !important;
  }
  .me-xxl-12 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-13 {
    margin-right: 4rem !important;
  }
  .me-xxl-14 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-15 {
    margin-right: 5rem !important;
  }
  .me-xxl-16 {
    margin-right: 5.5rem !important;
  }
  .me-xxl-17 {
    margin-right: 6rem !important;
  }
  .me-xxl-18 {
    margin-right: 6.5rem !important;
  }
  .me-xxl-19 {
    margin-right: 7rem !important;
  }
  .me-xxl-20 {
    margin-right: 7.5rem !important;
  }
  .me-xxl-21 {
    margin-right: 8rem !important;
  }
  .me-xxl-22 {
    margin-right: 8.5rem !important;
  }
  .me-xxl-23 {
    margin-right: 9rem !important;
  }
  .me-xxl-24 {
    margin-right: 9.5rem !important;
  }
  .me-xxl-25 {
    margin-right: 10rem !important;
  }
  .me-xxl-26 {
    margin-right: 10.5rem !important;
  }
  .me-xxl-27 {
    margin-right: 11rem !important;
  }
  .me-xxl-28 {
    margin-right: 12rem !important;
  }
  .me-xxl-29 {
    margin-right: 13rem !important;
  }
  .me-xxl-30 {
    margin-right: 14rem !important;
  }
  .me-xxl-31 {
    margin-right: 15rem !important;
  }
  .me-xxl-32 {
    margin-right: 16rem !important;
  }
  .me-xxl-33 {
    margin-right: 17rem !important;
  }
  .me-xxl-34 {
    margin-right: 18rem !important;
  }
  .me-xxl-35 {
    margin-right: 19rem !important;
  }
  .me-xxl-36 {
    margin-right: 20rem !important;
  }
  .me-xxl-37 {
    margin-right: 21rem !important;
  }
  .me-xxl-38 {
    margin-right: 22rem !important;
  }
  .me-xxl-39 {
    margin-right: 23rem !important;
  }
  .me-xxl-40 {
    margin-right: 24rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.35rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.55rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 1.75rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-16 {
    margin-bottom: 5.5rem !important;
  }
  .mb-xxl-17 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-18 {
    margin-bottom: 6.5rem !important;
  }
  .mb-xxl-19 {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-20 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xxl-21 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-22 {
    margin-bottom: 8.5rem !important;
  }
  .mb-xxl-23 {
    margin-bottom: 9rem !important;
  }
  .mb-xxl-24 {
    margin-bottom: 9.5rem !important;
  }
  .mb-xxl-25 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-26 {
    margin-bottom: 10.5rem !important;
  }
  .mb-xxl-27 {
    margin-bottom: 11rem !important;
  }
  .mb-xxl-28 {
    margin-bottom: 12rem !important;
  }
  .mb-xxl-29 {
    margin-bottom: 13rem !important;
  }
  .mb-xxl-30 {
    margin-bottom: 14rem !important;
  }
  .mb-xxl-31 {
    margin-bottom: 15rem !important;
  }
  .mb-xxl-32 {
    margin-bottom: 16rem !important;
  }
  .mb-xxl-33 {
    margin-bottom: 17rem !important;
  }
  .mb-xxl-34 {
    margin-bottom: 18rem !important;
  }
  .mb-xxl-35 {
    margin-bottom: 19rem !important;
  }
  .mb-xxl-36 {
    margin-bottom: 20rem !important;
  }
  .mb-xxl-37 {
    margin-bottom: 21rem !important;
  }
  .mb-xxl-38 {
    margin-bottom: 22rem !important;
  }
  .mb-xxl-39 {
    margin-bottom: 23rem !important;
  }
  .mb-xxl-40 {
    margin-bottom: 24rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.35rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.55rem !important;
  }
  .ms-xxl-4 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-5 {
    margin-left: 1rem !important;
  }
  .ms-xxl-6 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-7 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-8 {
    margin-left: 1.75rem !important;
  }
  .ms-xxl-9 {
    margin-left: 2rem !important;
  }
  .ms-xxl-10 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-11 {
    margin-left: 3rem !important;
  }
  .ms-xxl-12 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-13 {
    margin-left: 4rem !important;
  }
  .ms-xxl-14 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-15 {
    margin-left: 5rem !important;
  }
  .ms-xxl-16 {
    margin-left: 5.5rem !important;
  }
  .ms-xxl-17 {
    margin-left: 6rem !important;
  }
  .ms-xxl-18 {
    margin-left: 6.5rem !important;
  }
  .ms-xxl-19 {
    margin-left: 7rem !important;
  }
  .ms-xxl-20 {
    margin-left: 7.5rem !important;
  }
  .ms-xxl-21 {
    margin-left: 8rem !important;
  }
  .ms-xxl-22 {
    margin-left: 8.5rem !important;
  }
  .ms-xxl-23 {
    margin-left: 9rem !important;
  }
  .ms-xxl-24 {
    margin-left: 9.5rem !important;
  }
  .ms-xxl-25 {
    margin-left: 10rem !important;
  }
  .ms-xxl-26 {
    margin-left: 10.5rem !important;
  }
  .ms-xxl-27 {
    margin-left: 11rem !important;
  }
  .ms-xxl-28 {
    margin-left: 12rem !important;
  }
  .ms-xxl-29 {
    margin-left: 13rem !important;
  }
  .ms-xxl-30 {
    margin-left: 14rem !important;
  }
  .ms-xxl-31 {
    margin-left: 15rem !important;
  }
  .ms-xxl-32 {
    margin-left: 16rem !important;
  }
  .ms-xxl-33 {
    margin-left: 17rem !important;
  }
  .ms-xxl-34 {
    margin-left: 18rem !important;
  }
  .ms-xxl-35 {
    margin-left: 19rem !important;
  }
  .ms-xxl-36 {
    margin-left: 20rem !important;
  }
  .ms-xxl-37 {
    margin-left: 21rem !important;
  }
  .ms-xxl-38 {
    margin-left: 22rem !important;
  }
  .ms-xxl-39 {
    margin-left: 23rem !important;
  }
  .ms-xxl-40 {
    margin-left: 24rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.35rem !important;
  }
  .p-xxl-3 {
    padding: 0.55rem !important;
  }
  .p-xxl-4 {
    padding: 0.75rem !important;
  }
  .p-xxl-5 {
    padding: 1rem !important;
  }
  .p-xxl-6 {
    padding: 1.25rem !important;
  }
  .p-xxl-7 {
    padding: 1.5rem !important;
  }
  .p-xxl-8 {
    padding: 1.75rem !important;
  }
  .p-xxl-9 {
    padding: 2rem !important;
  }
  .p-xxl-10 {
    padding: 2.5rem !important;
  }
  .p-xxl-11 {
    padding: 3rem !important;
  }
  .p-xxl-12 {
    padding: 3.5rem !important;
  }
  .p-xxl-13 {
    padding: 4rem !important;
  }
  .p-xxl-14 {
    padding: 4.5rem !important;
  }
  .p-xxl-15 {
    padding: 5rem !important;
  }
  .p-xxl-16 {
    padding: 5.5rem !important;
  }
  .p-xxl-17 {
    padding: 6rem !important;
  }
  .p-xxl-18 {
    padding: 6.5rem !important;
  }
  .p-xxl-19 {
    padding: 7rem !important;
  }
  .p-xxl-20 {
    padding: 7.5rem !important;
  }
  .p-xxl-21 {
    padding: 8rem !important;
  }
  .p-xxl-22 {
    padding: 8.5rem !important;
  }
  .p-xxl-23 {
    padding: 9rem !important;
  }
  .p-xxl-24 {
    padding: 9.5rem !important;
  }
  .p-xxl-25 {
    padding: 10rem !important;
  }
  .p-xxl-26 {
    padding: 10.5rem !important;
  }
  .p-xxl-27 {
    padding: 11rem !important;
  }
  .p-xxl-28 {
    padding: 12rem !important;
  }
  .p-xxl-29 {
    padding: 13rem !important;
  }
  .p-xxl-30 {
    padding: 14rem !important;
  }
  .p-xxl-31 {
    padding: 15rem !important;
  }
  .p-xxl-32 {
    padding: 16rem !important;
  }
  .p-xxl-33 {
    padding: 17rem !important;
  }
  .p-xxl-34 {
    padding: 18rem !important;
  }
  .p-xxl-35 {
    padding: 19rem !important;
  }
  .p-xxl-36 {
    padding: 20rem !important;
  }
  .p-xxl-37 {
    padding: 21rem !important;
  }
  .p-xxl-38 {
    padding: 22rem !important;
  }
  .p-xxl-39 {
    padding: 23rem !important;
  }
  .p-xxl-40 {
    padding: 24rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.35rem !important;
    padding-left: 0.35rem !important;
  }
  .px-xxl-3 {
    padding-right: 0.55rem !important;
    padding-left: 0.55rem !important;
  }
  .px-xxl-4 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xxl-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-6 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-8 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-xxl-9 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-11 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-12 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xxl-13 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-14 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xxl-15 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-16 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important;
  }
  .px-xxl-17 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-18 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-xxl-19 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xxl-20 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xxl-21 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xxl-22 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
  }
  .px-xxl-23 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xxl-24 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important;
  }
  .px-xxl-25 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xxl-26 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
  }
  .px-xxl-27 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-xxl-28 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xxl-29 {
    padding-right: 13rem !important;
    padding-left: 13rem !important;
  }
  .px-xxl-30 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-xxl-31 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .px-xxl-32 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-xxl-33 {
    padding-right: 17rem !important;
    padding-left: 17rem !important;
  }
  .px-xxl-34 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-xxl-35 {
    padding-right: 19rem !important;
    padding-left: 19rem !important;
  }
  .px-xxl-36 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-xxl-37 {
    padding-right: 21rem !important;
    padding-left: 21rem !important;
  }
  .px-xxl-38 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-xxl-39 {
    padding-right: 23rem !important;
    padding-left: 23rem !important;
  }
  .px-xxl-40 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.35rem !important;
    padding-bottom: 0.35rem !important;
  }
  .py-xxl-3 {
    padding-top: 0.55rem !important;
    padding-bottom: 0.55rem !important;
  }
  .py-xxl-4 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xxl-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-6 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-7 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-8 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-xxl-9 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-11 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-12 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-13 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-14 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xxl-15 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-16 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }
  .py-xxl-17 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-18 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-xxl-19 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xxl-20 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xxl-21 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xxl-22 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important;
  }
  .py-xxl-23 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xxl-24 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important;
  }
  .py-xxl-25 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xxl-26 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important;
  }
  .py-xxl-27 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-xxl-28 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xxl-29 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }
  .py-xxl-30 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-xxl-31 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .py-xxl-32 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-xxl-33 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important;
  }
  .py-xxl-34 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-xxl-35 {
    padding-top: 19rem !important;
    padding-bottom: 19rem !important;
  }
  .py-xxl-36 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-xxl-37 {
    padding-top: 21rem !important;
    padding-bottom: 21rem !important;
  }
  .py-xxl-38 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-xxl-39 {
    padding-top: 23rem !important;
    padding-bottom: 23rem !important;
  }
  .py-xxl-40 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.35rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.55rem !important;
  }
  .pt-xxl-4 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-5 {
    padding-top: 1rem !important;
  }
  .pt-xxl-6 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-7 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-8 {
    padding-top: 1.75rem !important;
  }
  .pt-xxl-9 {
    padding-top: 2rem !important;
  }
  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-11 {
    padding-top: 3rem !important;
  }
  .pt-xxl-12 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-13 {
    padding-top: 4rem !important;
  }
  .pt-xxl-14 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-15 {
    padding-top: 5rem !important;
  }
  .pt-xxl-16 {
    padding-top: 5.5rem !important;
  }
  .pt-xxl-17 {
    padding-top: 6rem !important;
  }
  .pt-xxl-18 {
    padding-top: 6.5rem !important;
  }
  .pt-xxl-19 {
    padding-top: 7rem !important;
  }
  .pt-xxl-20 {
    padding-top: 7.5rem !important;
  }
  .pt-xxl-21 {
    padding-top: 8rem !important;
  }
  .pt-xxl-22 {
    padding-top: 8.5rem !important;
  }
  .pt-xxl-23 {
    padding-top: 9rem !important;
  }
  .pt-xxl-24 {
    padding-top: 9.5rem !important;
  }
  .pt-xxl-25 {
    padding-top: 10rem !important;
  }
  .pt-xxl-26 {
    padding-top: 10.5rem !important;
  }
  .pt-xxl-27 {
    padding-top: 11rem !important;
  }
  .pt-xxl-28 {
    padding-top: 12rem !important;
  }
  .pt-xxl-29 {
    padding-top: 13rem !important;
  }
  .pt-xxl-30 {
    padding-top: 14rem !important;
  }
  .pt-xxl-31 {
    padding-top: 15rem !important;
  }
  .pt-xxl-32 {
    padding-top: 16rem !important;
  }
  .pt-xxl-33 {
    padding-top: 17rem !important;
  }
  .pt-xxl-34 {
    padding-top: 18rem !important;
  }
  .pt-xxl-35 {
    padding-top: 19rem !important;
  }
  .pt-xxl-36 {
    padding-top: 20rem !important;
  }
  .pt-xxl-37 {
    padding-top: 21rem !important;
  }
  .pt-xxl-38 {
    padding-top: 22rem !important;
  }
  .pt-xxl-39 {
    padding-top: 23rem !important;
  }
  .pt-xxl-40 {
    padding-top: 24rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.35rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.55rem !important;
  }
  .pe-xxl-4 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-5 {
    padding-right: 1rem !important;
  }
  .pe-xxl-6 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-7 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-8 {
    padding-right: 1.75rem !important;
  }
  .pe-xxl-9 {
    padding-right: 2rem !important;
  }
  .pe-xxl-10 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-11 {
    padding-right: 3rem !important;
  }
  .pe-xxl-12 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-13 {
    padding-right: 4rem !important;
  }
  .pe-xxl-14 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-15 {
    padding-right: 5rem !important;
  }
  .pe-xxl-16 {
    padding-right: 5.5rem !important;
  }
  .pe-xxl-17 {
    padding-right: 6rem !important;
  }
  .pe-xxl-18 {
    padding-right: 6.5rem !important;
  }
  .pe-xxl-19 {
    padding-right: 7rem !important;
  }
  .pe-xxl-20 {
    padding-right: 7.5rem !important;
  }
  .pe-xxl-21 {
    padding-right: 8rem !important;
  }
  .pe-xxl-22 {
    padding-right: 8.5rem !important;
  }
  .pe-xxl-23 {
    padding-right: 9rem !important;
  }
  .pe-xxl-24 {
    padding-right: 9.5rem !important;
  }
  .pe-xxl-25 {
    padding-right: 10rem !important;
  }
  .pe-xxl-26 {
    padding-right: 10.5rem !important;
  }
  .pe-xxl-27 {
    padding-right: 11rem !important;
  }
  .pe-xxl-28 {
    padding-right: 12rem !important;
  }
  .pe-xxl-29 {
    padding-right: 13rem !important;
  }
  .pe-xxl-30 {
    padding-right: 14rem !important;
  }
  .pe-xxl-31 {
    padding-right: 15rem !important;
  }
  .pe-xxl-32 {
    padding-right: 16rem !important;
  }
  .pe-xxl-33 {
    padding-right: 17rem !important;
  }
  .pe-xxl-34 {
    padding-right: 18rem !important;
  }
  .pe-xxl-35 {
    padding-right: 19rem !important;
  }
  .pe-xxl-36 {
    padding-right: 20rem !important;
  }
  .pe-xxl-37 {
    padding-right: 21rem !important;
  }
  .pe-xxl-38 {
    padding-right: 22rem !important;
  }
  .pe-xxl-39 {
    padding-right: 23rem !important;
  }
  .pe-xxl-40 {
    padding-right: 24rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.35rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.55rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 1.75rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-13 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-14 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-15 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-16 {
    padding-bottom: 5.5rem !important;
  }
  .pb-xxl-17 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-18 {
    padding-bottom: 6.5rem !important;
  }
  .pb-xxl-19 {
    padding-bottom: 7rem !important;
  }
  .pb-xxl-20 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xxl-21 {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-22 {
    padding-bottom: 8.5rem !important;
  }
  .pb-xxl-23 {
    padding-bottom: 9rem !important;
  }
  .pb-xxl-24 {
    padding-bottom: 9.5rem !important;
  }
  .pb-xxl-25 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-26 {
    padding-bottom: 10.5rem !important;
  }
  .pb-xxl-27 {
    padding-bottom: 11rem !important;
  }
  .pb-xxl-28 {
    padding-bottom: 12rem !important;
  }
  .pb-xxl-29 {
    padding-bottom: 13rem !important;
  }
  .pb-xxl-30 {
    padding-bottom: 14rem !important;
  }
  .pb-xxl-31 {
    padding-bottom: 15rem !important;
  }
  .pb-xxl-32 {
    padding-bottom: 16rem !important;
  }
  .pb-xxl-33 {
    padding-bottom: 17rem !important;
  }
  .pb-xxl-34 {
    padding-bottom: 18rem !important;
  }
  .pb-xxl-35 {
    padding-bottom: 19rem !important;
  }
  .pb-xxl-36 {
    padding-bottom: 20rem !important;
  }
  .pb-xxl-37 {
    padding-bottom: 21rem !important;
  }
  .pb-xxl-38 {
    padding-bottom: 22rem !important;
  }
  .pb-xxl-39 {
    padding-bottom: 23rem !important;
  }
  .pb-xxl-40 {
    padding-bottom: 24rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.35rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.55rem !important;
  }
  .ps-xxl-4 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-5 {
    padding-left: 1rem !important;
  }
  .ps-xxl-6 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-7 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-8 {
    padding-left: 1.75rem !important;
  }
  .ps-xxl-9 {
    padding-left: 2rem !important;
  }
  .ps-xxl-10 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-11 {
    padding-left: 3rem !important;
  }
  .ps-xxl-12 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-13 {
    padding-left: 4rem !important;
  }
  .ps-xxl-14 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-15 {
    padding-left: 5rem !important;
  }
  .ps-xxl-16 {
    padding-left: 5.5rem !important;
  }
  .ps-xxl-17 {
    padding-left: 6rem !important;
  }
  .ps-xxl-18 {
    padding-left: 6.5rem !important;
  }
  .ps-xxl-19 {
    padding-left: 7rem !important;
  }
  .ps-xxl-20 {
    padding-left: 7.5rem !important;
  }
  .ps-xxl-21 {
    padding-left: 8rem !important;
  }
  .ps-xxl-22 {
    padding-left: 8.5rem !important;
  }
  .ps-xxl-23 {
    padding-left: 9rem !important;
  }
  .ps-xxl-24 {
    padding-left: 9.5rem !important;
  }
  .ps-xxl-25 {
    padding-left: 10rem !important;
  }
  .ps-xxl-26 {
    padding-left: 10.5rem !important;
  }
  .ps-xxl-27 {
    padding-left: 11rem !important;
  }
  .ps-xxl-28 {
    padding-left: 12rem !important;
  }
  .ps-xxl-29 {
    padding-left: 13rem !important;
  }
  .ps-xxl-30 {
    padding-left: 14rem !important;
  }
  .ps-xxl-31 {
    padding-left: 15rem !important;
  }
  .ps-xxl-32 {
    padding-left: 16rem !important;
  }
  .ps-xxl-33 {
    padding-left: 17rem !important;
  }
  .ps-xxl-34 {
    padding-left: 18rem !important;
  }
  .ps-xxl-35 {
    padding-left: 19rem !important;
  }
  .ps-xxl-36 {
    padding-left: 20rem !important;
  }
  .ps-xxl-37 {
    padding-left: 21rem !important;
  }
  .ps-xxl-38 {
    padding-left: 22rem !important;
  }
  .ps-xxl-39 {
    padding-left: 23rem !important;
  }
  .ps-xxl-40 {
    padding-left: 24rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.35rem !important;
  }
  .gap-xxl-3 {
    gap: 0.55rem !important;
  }
  .gap-xxl-4 {
    gap: 0.75rem !important;
  }
  .gap-xxl-5 {
    gap: 1rem !important;
  }
  .gap-xxl-6 {
    gap: 1.25rem !important;
  }
  .gap-xxl-7 {
    gap: 1.5rem !important;
  }
  .gap-xxl-8 {
    gap: 1.75rem !important;
  }
  .gap-xxl-9 {
    gap: 2rem !important;
  }
  .gap-xxl-10 {
    gap: 2.5rem !important;
  }
  .gap-xxl-11 {
    gap: 3rem !important;
  }
  .gap-xxl-12 {
    gap: 3.5rem !important;
  }
  .gap-xxl-13 {
    gap: 4rem !important;
  }
  .gap-xxl-14 {
    gap: 4.5rem !important;
  }
  .gap-xxl-15 {
    gap: 5rem !important;
  }
  .gap-xxl-16 {
    gap: 5.5rem !important;
  }
  .gap-xxl-17 {
    gap: 6rem !important;
  }
  .gap-xxl-18 {
    gap: 6.5rem !important;
  }
  .gap-xxl-19 {
    gap: 7rem !important;
  }
  .gap-xxl-20 {
    gap: 7.5rem !important;
  }
  .gap-xxl-21 {
    gap: 8rem !important;
  }
  .gap-xxl-22 {
    gap: 8.5rem !important;
  }
  .gap-xxl-23 {
    gap: 9rem !important;
  }
  .gap-xxl-24 {
    gap: 9.5rem !important;
  }
  .gap-xxl-25 {
    gap: 10rem !important;
  }
  .gap-xxl-26 {
    gap: 10.5rem !important;
  }
  .gap-xxl-27 {
    gap: 11rem !important;
  }
  .gap-xxl-28 {
    gap: 12rem !important;
  }
  .gap-xxl-29 {
    gap: 13rem !important;
  }
  .gap-xxl-30 {
    gap: 14rem !important;
  }
  .gap-xxl-31 {
    gap: 15rem !important;
  }
  .gap-xxl-32 {
    gap: 16rem !important;
  }
  .gap-xxl-33 {
    gap: 17rem !important;
  }
  .gap-xxl-34 {
    gap: 18rem !important;
  }
  .gap-xxl-35 {
    gap: 19rem !important;
  }
  .gap-xxl-36 {
    gap: 20rem !important;
  }
  .gap-xxl-37 {
    gap: 21rem !important;
  }
  .gap-xxl-38 {
    gap: 22rem !important;
  }
  .gap-xxl-39 {
    gap: 23rem !important;
  }
  .gap-xxl-40 {
    gap: 24rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.35rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 0.55rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 1.75rem !important;
  }
  .row-gap-xxl-9 {
    row-gap: 2rem !important;
  }
  .row-gap-xxl-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xxl-11 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-12 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xxl-13 {
    row-gap: 4rem !important;
  }
  .row-gap-xxl-14 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xxl-15 {
    row-gap: 5rem !important;
  }
  .row-gap-xxl-16 {
    row-gap: 5.5rem !important;
  }
  .row-gap-xxl-17 {
    row-gap: 6rem !important;
  }
  .row-gap-xxl-18 {
    row-gap: 6.5rem !important;
  }
  .row-gap-xxl-19 {
    row-gap: 7rem !important;
  }
  .row-gap-xxl-20 {
    row-gap: 7.5rem !important;
  }
  .row-gap-xxl-21 {
    row-gap: 8rem !important;
  }
  .row-gap-xxl-22 {
    row-gap: 8.5rem !important;
  }
  .row-gap-xxl-23 {
    row-gap: 9rem !important;
  }
  .row-gap-xxl-24 {
    row-gap: 9.5rem !important;
  }
  .row-gap-xxl-25 {
    row-gap: 10rem !important;
  }
  .row-gap-xxl-26 {
    row-gap: 10.5rem !important;
  }
  .row-gap-xxl-27 {
    row-gap: 11rem !important;
  }
  .row-gap-xxl-28 {
    row-gap: 12rem !important;
  }
  .row-gap-xxl-29 {
    row-gap: 13rem !important;
  }
  .row-gap-xxl-30 {
    row-gap: 14rem !important;
  }
  .row-gap-xxl-31 {
    row-gap: 15rem !important;
  }
  .row-gap-xxl-32 {
    row-gap: 16rem !important;
  }
  .row-gap-xxl-33 {
    row-gap: 17rem !important;
  }
  .row-gap-xxl-34 {
    row-gap: 18rem !important;
  }
  .row-gap-xxl-35 {
    row-gap: 19rem !important;
  }
  .row-gap-xxl-36 {
    row-gap: 20rem !important;
  }
  .row-gap-xxl-37 {
    row-gap: 21rem !important;
  }
  .row-gap-xxl-38 {
    row-gap: 22rem !important;
  }
  .row-gap-xxl-39 {
    row-gap: 23rem !important;
  }
  .row-gap-xxl-40 {
    row-gap: 24rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.35rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 0.55rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-6 {
    column-gap: 1.25rem !important;
  }
  .column-gap-xxl-7 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-8 {
    column-gap: 1.75rem !important;
  }
  .column-gap-xxl-9 {
    column-gap: 2rem !important;
  }
  .column-gap-xxl-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-xxl-11 {
    column-gap: 3rem !important;
  }
  .column-gap-xxl-12 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xxl-13 {
    column-gap: 4rem !important;
  }
  .column-gap-xxl-14 {
    column-gap: 4.5rem !important;
  }
  .column-gap-xxl-15 {
    column-gap: 5rem !important;
  }
  .column-gap-xxl-16 {
    column-gap: 5.5rem !important;
  }
  .column-gap-xxl-17 {
    column-gap: 6rem !important;
  }
  .column-gap-xxl-18 {
    column-gap: 6.5rem !important;
  }
  .column-gap-xxl-19 {
    column-gap: 7rem !important;
  }
  .column-gap-xxl-20 {
    column-gap: 7.5rem !important;
  }
  .column-gap-xxl-21 {
    column-gap: 8rem !important;
  }
  .column-gap-xxl-22 {
    column-gap: 8.5rem !important;
  }
  .column-gap-xxl-23 {
    column-gap: 9rem !important;
  }
  .column-gap-xxl-24 {
    column-gap: 9.5rem !important;
  }
  .column-gap-xxl-25 {
    column-gap: 10rem !important;
  }
  .column-gap-xxl-26 {
    column-gap: 10.5rem !important;
  }
  .column-gap-xxl-27 {
    column-gap: 11rem !important;
  }
  .column-gap-xxl-28 {
    column-gap: 12rem !important;
  }
  .column-gap-xxl-29 {
    column-gap: 13rem !important;
  }
  .column-gap-xxl-30 {
    column-gap: 14rem !important;
  }
  .column-gap-xxl-31 {
    column-gap: 15rem !important;
  }
  .column-gap-xxl-32 {
    column-gap: 16rem !important;
  }
  .column-gap-xxl-33 {
    column-gap: 17rem !important;
  }
  .column-gap-xxl-34 {
    column-gap: 18rem !important;
  }
  .column-gap-xxl-35 {
    column-gap: 19rem !important;
  }
  .column-gap-xxl-36 {
    column-gap: 20rem !important;
  }
  .column-gap-xxl-37 {
    column-gap: 21rem !important;
  }
  .column-gap-xxl-38 {
    column-gap: 22rem !important;
  }
  .column-gap-xxl-39 {
    column-gap: 23rem !important;
  }
  .column-gap-xxl-40 {
    column-gap: 24rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.8125rem !important;
  }
  .fs-2 {
    font-size: 2.41875rem !important;
  }
  .fs-3 {
    font-size: 1.96875rem !important;
  }
  .fs-4 {
    font-size: 1.51875rem !important;
  }
  .fs-5 {
    font-size: 1.29375rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* END UTILITIES */
/*-----------------------------------------------------------*/
/* START THEME CUSTOM */
#header .header-navbar {
  --bs-header-navbar-bg-gradient: linear-gradient(180deg, rgba(22, 21, 20, 0.95) 0%, rgba(22, 21, 20, 0) 100%);
  --bs-header-navbar-navbar-brand-height: 8rem;
  --bs-header-navbar-sticky-navbar-brand-height: 5rem;
  --bs-header-navbar-brand-img-height: 56px;
  --bs-header-navbar-brand-img-margin-bottom: 0.5rem;
  --bs-header-navbar-sticky-brand-text-margin-top: -1.5rem;
  height: var(--bs-header-navbar-navbar-brand-height);
  position: relative;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}
#header .header-navbar::before {
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  background: var(--bs-header-navbar-bg-gradient);
  z-index: -1;
}
#header .header-navbar .navbar-brand {
  height: var(--bs-header-navbar-navbar-brand-height);
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}
#header .header-navbar .navbar-brand .brand-img {
  height: var(--bs-header-navbar-brand-img-height);
  width: auto;
  margin-bottom: var(--bs-header-navbar-brand-img-margin-bottom);
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}
#header .header-navbar .navbar-brand .brand-text {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}
#header .header-navbar .navbar-nav > .nav-item > .nav-link {
  position: relative;
  font-size: 1.40625rem;
  font-weight: 600 !important;
}
#header .header-navbar .navbar-nav > .nav-item > .nav-link.active, #header .header-navbar .navbar-nav > .nav-item > .nav-link:hover, #header .header-navbar .navbar-nav > .nav-item > .nav-link:focus {
  color: var(--bs-primary);
}
#header .header-navbar .navbar-nav > .nav-item > .nav-link .dropdown-toggle-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: unset;
}
#header .header-navbar .dropdown-menu:before {
  content: "";
  position: absolute;
  left: 20px;
  top: -18px;
  width: 0;
  height: 0;
  border-bottom: 10px solid var(--bs-body-bg);
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
#header .header-navbar .dropdown-menu .dropdown-item.active, #header .header-navbar .dropdown-menu .dropdown-item:active, #header .header-navbar .dropdown-menu .dropdown-item:hover, #header .header-navbar .dropdown-menu .dropdown-item:focus {
  color: var(--bs-primary);
  background-color: transparent;
}
#header .header-navbar .navbar-toggler {
  color: var(--bs-white);
}
@media (min-width: 576px) {
  #header .header-navbar .navbar-expand-sm .navbar-nav-left,
  #header .header-navbar .navbar-expand-sm .navbar-nav-right {
    flex: 1 0 50%;
  }
  #header .header-navbar .navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
    color: var(--bs-white);
    font-size: unset;
    font-weight: 400 !important;
  }
  #header .header-navbar .navbar-expand-sm .navbar-nav > .nav-item > .nav-link.active, #header .header-navbar .navbar-expand-sm .navbar-nav > .nav-item > .nav-link:hover, #header .header-navbar .navbar-expand-sm .navbar-nav > .nav-item > .nav-link:focus {
    color: var(--bs-primary-light);
  }
  #header .header-navbar .navbar-expand-sm .navbar-nav > .nav-item > .nav-link .dropdown-toggle-icon {
    font-size: 70%;
    position: unset;
    right: unset;
    top: unset;
    transform: unset;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
  }
  #header .header-navbar .navbar-expand-sm .dropdown-menu {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}
@media (min-width: 768px) {
  #header .header-navbar .navbar-expand-md .navbar-nav-left,
  #header .header-navbar .navbar-expand-md .navbar-nav-right {
    flex: 1 0 50%;
  }
  #header .header-navbar .navbar-expand-md .navbar-nav > .nav-item > .nav-link {
    color: var(--bs-white);
    font-size: unset;
    font-weight: 400 !important;
  }
  #header .header-navbar .navbar-expand-md .navbar-nav > .nav-item > .nav-link.active, #header .header-navbar .navbar-expand-md .navbar-nav > .nav-item > .nav-link:hover, #header .header-navbar .navbar-expand-md .navbar-nav > .nav-item > .nav-link:focus {
    color: var(--bs-primary-light);
  }
  #header .header-navbar .navbar-expand-md .navbar-nav > .nav-item > .nav-link .dropdown-toggle-icon {
    font-size: 70%;
    position: unset;
    right: unset;
    top: unset;
    transform: unset;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
  }
  #header .header-navbar .navbar-expand-md .dropdown-menu {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}
@media (min-width: 992px) {
  #header .header-navbar .navbar-expand-lg .navbar-nav-left,
  #header .header-navbar .navbar-expand-lg .navbar-nav-right {
    flex: 1 0 50%;
  }
  #header .header-navbar .navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
    color: var(--bs-white);
    font-size: unset;
    font-weight: 400 !important;
  }
  #header .header-navbar .navbar-expand-lg .navbar-nav > .nav-item > .nav-link.active, #header .header-navbar .navbar-expand-lg .navbar-nav > .nav-item > .nav-link:hover, #header .header-navbar .navbar-expand-lg .navbar-nav > .nav-item > .nav-link:focus {
    color: var(--bs-primary-light);
  }
  #header .header-navbar .navbar-expand-lg .navbar-nav > .nav-item > .nav-link .dropdown-toggle-icon {
    font-size: 70%;
    position: unset;
    right: unset;
    top: unset;
    transform: unset;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
  }
  #header .header-navbar .navbar-expand-lg .dropdown-menu {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}
@media (min-width: 1200px) {
  #header .header-navbar .navbar-expand-xl .navbar-nav-left,
  #header .header-navbar .navbar-expand-xl .navbar-nav-right {
    flex: 1 0 50%;
  }
  #header .header-navbar .navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
    color: var(--bs-white);
    font-size: unset;
    font-weight: 400 !important;
  }
  #header .header-navbar .navbar-expand-xl .navbar-nav > .nav-item > .nav-link.active, #header .header-navbar .navbar-expand-xl .navbar-nav > .nav-item > .nav-link:hover, #header .header-navbar .navbar-expand-xl .navbar-nav > .nav-item > .nav-link:focus {
    color: var(--bs-primary-light);
  }
  #header .header-navbar .navbar-expand-xl .navbar-nav > .nav-item > .nav-link .dropdown-toggle-icon {
    font-size: 70%;
    position: unset;
    right: unset;
    top: unset;
    transform: unset;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
  }
  #header .header-navbar .navbar-expand-xl .dropdown-menu {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}
@media (min-width: 1380px) {
  #header .header-navbar .navbar-expand-xxl .navbar-nav-left,
  #header .header-navbar .navbar-expand-xxl .navbar-nav-right {
    flex: 1 0 50%;
  }
  #header .header-navbar .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link {
    color: var(--bs-white);
    font-size: unset;
    font-weight: 400 !important;
  }
  #header .header-navbar .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link.active, #header .header-navbar .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link:hover, #header .header-navbar .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link:focus {
    color: var(--bs-primary-light);
  }
  #header .header-navbar .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link .dropdown-toggle-icon {
    font-size: 70%;
    position: unset;
    right: unset;
    top: unset;
    transform: unset;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
  }
  #header .header-navbar .navbar-expand-xxl .dropdown-menu {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}
#header .header-navbar .navbar-expand .navbar-nav-left,
#header .header-navbar .navbar-expand .navbar-nav-right {
  flex: 1 0 50%;
}
#header .header-navbar .navbar-expand .navbar-nav > .nav-item > .nav-link {
  color: var(--bs-white);
  font-size: unset;
  font-weight: 400 !important;
}
#header .header-navbar .navbar-expand .navbar-nav > .nav-item > .nav-link.active, #header .header-navbar .navbar-expand .navbar-nav > .nav-item > .nav-link:hover, #header .header-navbar .navbar-expand .navbar-nav > .nav-item > .nav-link:focus {
  color: var(--bs-primary-light);
}
#header .header-navbar .navbar-expand .navbar-nav > .nav-item > .nav-link .dropdown-toggle-icon {
  font-size: 70%;
  position: unset;
  right: unset;
  top: unset;
  transform: unset;
  -webkit-transform: unset;
  -moz-transform: unset;
  -ms-transform: unset;
  -o-transform: unset;
}
#header .header-navbar .navbar-expand .dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
#header .header-navbar.header-navbar-sticky {
  height: var(--bs-header-navbar-sticky-navbar-brand-height);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-bottom: 1px solid var(--bs-light-border-subtle);
}
#header .header-navbar.header-navbar-sticky .navbar-brand {
  height: var(--bs-header-navbar-sticky-navbar-brand-height);
}
#header .header-navbar.header-navbar-sticky .navbar-brand .brand-img {
  opacity: 0;
  height: 0;
  margin-bottom: 0;
}
#header .header-navbar.header-navbar-sticky .navbar-brand .brand-text {
  margin-top: var(--bs-header-navbar-sticky-brand-text-margin-top);
}
#header .header-navbar.header-navbar-sticky .navbar-nav > .nav-item > .nav-link:not(.active, :active, :hover, :focus) {
  color: var(--bs-body);
}
#header .header-navbar.header-navbar-sticky .navbar-nav > .nav-item > .nav-link.active, #header .header-navbar.header-navbar-sticky .navbar-nav > .nav-item > .nav-link:hover, #header .header-navbar.header-navbar-sticky .navbar-nav > .nav-item > .nav-link:focus {
  color: var(--bs-primary);
}
#header .header-navbar.header-navbar-sticky .navbar-toggler {
  color: var(--bs-body);
}

.logo-light {
  display: none;
}

.logo {
  display: inline-block;
}

[data-bs-theme=dark] .logo {
  display: none;
}
[data-bs-theme=dark] .logo-light {
  display: inline-block;
}

.hero-carousel .hero-caption,
.hero-image .hero-caption,
.hero-video .hero-caption {
  position: relative;
  height: calc(100vh - 2.5rem);
}
.hero-carousel.hero-image-full .hero-caption,
.hero-image.hero-image-full .hero-caption,
.hero-video.hero-image-full .hero-caption {
  height: 100vh;
}
@media (max-width: 991.98px) {
  .hero-carousel .hero-caption, .hero-carousel.hero-image-full .hero-caption,
  .hero-image .hero-caption,
  .hero-image.hero-image-full .hero-caption,
  .hero-video .hero-caption,
  .hero-video.hero-image-full .hero-caption {
    height: 895px;
  }
}
@media (max-width: 767.98px) {
  .hero-carousel .hero-caption, .hero-carousel.hero-image-full .hero-caption,
  .hero-image .hero-caption,
  .hero-image.hero-image-full .hero-caption,
  .hero-video .hero-caption,
  .hero-video.hero-image-full .hero-caption {
    height: 550px;
  }
}

/* Check room top */
.check-room-top {
  position: absolute;
  bottom: 0rem;
  left: 0;
  right: 0;
  z-index: 4;
}
@media (max-width: 991.98px) {
  .check-room-top {
    position: relative;
    bottom: unset;
    left: unset;
    right: unset;
  }
  .check-room-top .card {
    box-shadow: none !important;
  }
}

/* Check room footer */
.check-room-bottom {
  margin-top: -2.5rem;
}
@media (max-width: 991.98px) {
  .check-room-bottom {
    margin-top: -4.25rem;
  }
}
@media (max-width: 767.98px) {
  .check-room-bottom {
    margin-top: -6.15rem;
  }
}

.scroll-top {
  visibility: hidden;
  opacity: 0;
  width: 40px;
  height: 40px;
  background: rgba(var(--bs-primary-rgb), 0.55);
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}
.scroll-top.active {
  visibility: visible;
  opacity: 1;
}
.scroll-top:hover {
  background: rgba(var(--bs-primary-rgb), 0.85);
}

.image-wrapper {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: "cover";
  position: relative;
  z-index: 0;
}
.image-wrapper:not(.mobile) {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: "fixed" !important;
}
.image-wrapper.bg-auto {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: "auto";
  background-attachment: "scroll" !important;
}
.image-wrapper.bg-full {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: "100%";
  background-attachment: "scroll" !important;
}
.image-wrapper.bg-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: "cover";
  background-attachment: "scroll" !important;
}
.image-wrapper.bg-overlay:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.image-wrapper.bg-overlay-300:before {
  background: rgba(0, 0, 0, 0.3);
}
.image-wrapper.bg-overlay-300-light:before {
  background: rgba(255, 255, 255, 0.3);
}
.image-wrapper.bg-overlay-400:before {
  background: rgba(0, 0, 0, 0.4);
}
.image-wrapper.bg-overlay-400-light:before {
  background: rgba(255, 255, 255, 0.4);
}
.image-wrapper.bg-overlay-500:before {
  background: rgba(0, 0, 0, 0.5);
}
.image-wrapper.bg-overlay-500-light:before {
  background: rgba(255, 255, 255, 0.5);
}
.image-wrapper.bg-overlay-600:before {
  background: rgba(0, 0, 0, 0.6);
}
.image-wrapper.bg-overlay-600-light:before {
  background: rgba(255, 255, 255, 0.6);
}
.image-wrapper.bg-overlay-700:before {
  background: rgba(0, 0, 0, 0.7);
}
.image-wrapper.bg-overlay-700-light:before {
  background: rgba(255, 255, 255, 0.7);
}
.image-wrapper.bg-overlay-800:before {
  background: rgba(0, 0, 0, 0.8);
}
.image-wrapper.bg-overlay-800-light:before {
  background: rgba(255, 255, 255, 0.8);
}
.image-wrapper.bg-overlay-900:before {
  background: rgba(0, 0, 0, 0.9);
}
.image-wrapper.bg-overlay-900-light:before {
  background: rgba(255, 255, 255, 0.9);
}
.image-wrapper.bg-overlay .bg-content {
  position: relative;
  z-index: 2;
  height: 100%;
}
.image-wrapper.card:before {
  border-radius: 0 !important;
}
@media (max-width: 991.98px) {
  .image-wrapper.bg-full {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: "cover";
  }
}

.video-wrapper {
  position: relative;
  overflow: hidden;
}
.video-wrapper.bg-overlay:after {
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.35);
}
.video-wrapper.bg-overlay-300:after {
  background: rgba(0, 0, 0, 0.3);
}
.video-wrapper.bg-overlay-300-light:after {
  background: rgba(255, 255, 255, 0.3);
}
.video-wrapper.bg-overlay-400:after {
  background: rgba(0, 0, 0, 0.4);
}
.video-wrapper.bg-overlay-400-light:after {
  background: rgba(255, 255, 255, 0.4);
}
.video-wrapper.bg-overlay-500:after {
  background: rgba(0, 0, 0, 0.5);
}
.video-wrapper.bg-overlay-500-light:after {
  background: rgba(255, 255, 255, 0.5);
}
.video-wrapper.bg-overlay-600:after {
  background: rgba(0, 0, 0, 0.6);
}
.video-wrapper.bg-overlay-600-light:after {
  background: rgba(255, 255, 255, 0.6);
}
.video-wrapper.bg-overlay-700:after {
  background: rgba(0, 0, 0, 0.7);
}
.video-wrapper.bg-overlay-700-light:after {
  background: rgba(255, 255, 255, 0.7);
}
.video-wrapper.bg-overlay-800:after {
  background: rgba(0, 0, 0, 0.8);
}
.video-wrapper.bg-overlay-800-light:after {
  background: rgba(255, 255, 255, 0.8);
}
.video-wrapper.bg-overlay-900:after {
  background: rgba(0, 0, 0, 0.9);
}
.video-wrapper.bg-overlay-900-light:after {
  background: rgba(255, 255, 255, 0.9);
}
.video-wrapper.ratio::before {
  content: unset;
}
.video-wrapper.bg-overlay .bg-content {
  position: relative;
  z-index: 2;
  height: 100%;
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
@-moz-keyframes slideIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
@-ms-keyframes slideIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
@-o-keyframes slideIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
@keyframes slideIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
.slideIn {
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.35s;
  -webkit-animation-delay: 0s;
  -webkit-animation-fill-mode: both;
  -moz-animation-name: slideIn;
  -moz-animation-duration: 0.35s;
  -moz-animation-delay: 0s;
  -moz-animation-fill-mode: both;
  -ms-animation-name: slideIn;
  -ms-animation-duration: 0.35s;
  -ms-animation-delay: 0s;
  -ms-animation-fill-mode: both;
  -o-animation-name: slideIn;
  -o-animation-duration: 0.35s;
  -o-animation-delay: 0s;
  -o-animation-fill-mode: both;
  animation-name: slideIn;
  animation-duration: 0.35s;
  animation-delay: 0s;
  animation-fill-mode: both;
}

.upDown {
  -webkit-animation-name: mover;
  -webkit-animation-duration: 0.75s;
  -webkit-animation-delay: 0s;
  -webkit-animation-fill-mode: both;
  -moz-animation-name: mover;
  -moz-animation-duration: 0.75s;
  -moz-animation-delay: 0s;
  -moz-animation-fill-mode: both;
  -ms-animation-name: mover;
  -ms-animation-duration: 0.75s;
  -ms-animation-delay: 0s;
  -ms-animation-fill-mode: both;
  -o-animation-name: mover;
  -o-animation-duration: 0.75s;
  -o-animation-delay: 0s;
  -o-animation-fill-mode: both;
  animation-name: mover;
  animation-duration: 0.75s;
  animation-delay: 0s;
  animation-fill-mode: both;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
@-moz-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
@-ms-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
@-o-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
.star-rate-view {
  position: relative;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 108.9 103.6'%3E%3Cg data-name='Layer 2'%3E%3Cpath fill='%23e9ecef' d='m108.9 39.6-37.6-5.5L54.4 0 37.6 34.1 0 39.6l27.2 26.5-6.4 37.5 33.6-17.7 33.7 17.7-6.4-37.5 27.2-26.5z' data-name='Layer 1'/%3E%3C/g%3E%3C/svg%3E");
  background-position: 0 0;
  background-repeat: repeat-x;
  text-align: left;
}
.star-rate-view.star-rate-size-xs {
  width: 50px;
  height: 10px;
  background-size: 10px;
}
.star-rate-view.star-rate-size-sm {
  width: 80px;
  height: 16px;
  background-size: 16px;
}
.star-rate-view.star-rate-size-md, .star-rate-view {
  width: 120px;
  height: 24px;
  background-size: 24px;
}
.star-rate-view.star-rate-size-lg {
  width: 160px;
  height: 32px;
  background-size: 32px;
}
.star-rate-view .star-value {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 108.9 103.6'%3E%3Cg data-name='Layer 2'%3E%3Cpath fill='%23eeb461' d='m54.4 0 16.9 34.1 37.6 5.5-27.2 26.5 6.4 37.5-33.7-17.7-33.6 17.7 6.4-37.5L0 39.6l37.6-5.5L54.4 0z' data-name='Layer 1'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: repeat-x;
}
.star-rate-view .star-value.rate-0 {
  width: 0%;
}
.star-rate-view .star-value.rate-5 {
  width: 10%;
}
.star-rate-view .star-value.rate-10 {
  width: 20%;
}
.star-rate-view .star-value.rate-15 {
  width: 30%;
}
.star-rate-view .star-value.rate-20 {
  width: 40%;
}
.star-rate-view .star-value.rate-25 {
  width: 50%;
}
.star-rate-view .star-value.rate-30 {
  width: 60%;
}
.star-rate-view .star-value.rate-35 {
  width: 70%;
}
.star-rate-view .star-value.rate-40 {
  width: 80%;
}
.star-rate-view .star-value.rate-45 {
  width: 90%;
}
.star-rate-view .star-value.rate-50 {
  width: 100%;
}
.star-rate-view.star-rate-size-xs .star-value {
  background-size: 10px;
}
.star-rate-view.star-rate-size-sm .star-value {
  background-size: 16px;
}
.star-rate-view .star-value, .star-rate-view.star-rate-size-md .star-value {
  background-size: 24px;
}
.star-rate-view.star-rate-size-lg .star-value {
  background-size: 32px;
}

.icon-scroll {
  --bs-icon-scroll-width: 2.2rem;
  --bs-icon-scroll-height: 3.5rem;
  --bs-icon-scroll-border-width: 1px;
  --bs-icon-scroll-border-color: #ffffff;
  --bs-icon-scroll-border-radius: 1.1rem;
  --bs-icon-scroll-dot-size: 0.5rem;
  --bs-icon-scroll-dot-animation-duration: 1.5s;
  display: inline-block;
  width: var(--bs-icon-scroll-width);
  height: var(--bs-icon-scroll-height);
  border: var(--bs-icon-scroll-border-width) solid var(--bs-icon-scroll-border-color);
  border-radius: var(--bs-icon-scroll-border-radius);
  position: relative;
}
.icon-scroll:before {
  content: "";
  position: absolute;
  width: var(--bs-icon-scroll-dot-size);
  height: var(--bs-icon-scroll-dot-size);
  top: var(--bs-icon-scroll-dot-size);
  left: calc(50% - var(--bs-icon-scroll-dot-size) / 2);
  border-radius: 50%;
  background: #fff;
  animation-duration: var(--bs-icon-scroll-dot-animation-duration);
  animation-iteration-count: infinite;
  animation-name: scroll;
}

@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(calc(var(--bs-icon-scroll-height) - var(--bs-icon-scroll-dot-size) * 3));
    -webkit-transform: translateY(calc(var(--bs-icon-scroll-height) - var(--bs-icon-scroll-dot-size) * 3));
    -moz-transform: translateY(calc(var(--bs-icon-scroll-height) - var(--bs-icon-scroll-dot-size) * 3));
    -ms-transform: translateY(calc(var(--bs-icon-scroll-height) - var(--bs-icon-scroll-dot-size) * 3));
    -o-transform: translateY(calc(var(--bs-icon-scroll-height) - var(--bs-icon-scroll-dot-size) * 3));
  }
}
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(calc(var(--bs-icon-scroll-height) - var(--bs-icon-scroll-dot-size) * 3));
    -webkit-transform: translateY(calc(var(--bs-icon-scroll-height) - var(--bs-icon-scroll-dot-size) * 3));
    -moz-transform: translateY(calc(var(--bs-icon-scroll-height) - var(--bs-icon-scroll-dot-size) * 3));
    -ms-transform: translateY(calc(var(--bs-icon-scroll-height) - var(--bs-icon-scroll-dot-size) * 3));
    -o-transform: translateY(calc(var(--bs-icon-scroll-height) - var(--bs-icon-scroll-dot-size) * 3));
  }
}
.room-slide {
  --bs-room-slide-height: 480px;
  --bs-room-slide-item-padding: 0.5rem;
  --bs-room-slide-item-title-pading-top: 6rem;
  --bs-room-slide-item-title-font-size-collapse: 1rem;
  --bs-room-slide-item-content-padding-collapse: 1.5rem;
  --bs-room-slide-item-content-margin-bottom: -1rem;
  --bs-room-slide-item-button-margin-bottom: -4.5rem;
  --bs-card-overlay-bottom-bg-gradient: linear-gradient(0deg, rgb(22, 21, 20) 0%, rgba(22, 21, 20, 0) 100%);
  --bs-card-overlay-bottom-bg-gradient: linear-gradient(0deg, rgb(22, 21, 20) 0%, rgba(22, 21, 20, 0) 100%);
}
@media (min-width: 992px) {
  .room-slide {
    display: flex;
  }
  .room-slide .room-slide-item {
    width: 16.666666%;
    height: var(--bs-room-slide-height);
    position: relative;
    overflow: hidden;
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
  }
  .room-slide .room-slide-item:not(:last-child) {
    padding-right: var(--bs-room-slide-item-padding);
  }
  .room-slide .room-slide-item.active {
    width: 50%;
    cursor: unset;
  }
  .room-slide .room-slide-item.active .card-title {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
  }
  .room-slide .room-slide-item.active .card.card-overlay-bottom .card-text {
    visibility: visible;
    opacity: 1;
    margin-bottom: 0;
  }
  .room-slide .room-slide-item .card, .room-slide .room-slide-item .card-img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .room-slide .room-slide-item .card-img-overlay {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
  }
  .room-slide .room-slide-item:not(.active) .card-img-overlay {
    padding: var(--bs-room-slide-item-content-padding-collapse) !important;
  }
  .room-slide .room-slide-item .card-title, .room-slide .room-slide-item .card-link {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
  }
  .room-slide .room-slide-item .card-text {
    visibility: hidden;
    opacity: 0;
    margin-bottom: var(--bs-room-slide-item-content-margin-bottom);
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
  }
  .room-slide .room-slide-item:not(.active) .card-title {
    font-size: var(--bs-room-slide-item-title-font-size-collapse);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .room-slide .room-slide-item:not(.active) .card-title {
    width: min-content;
  }
}
@media (min-width: 992px) {
  .room-slide .room-slide-item:not(.active) .card-link {
    opacity: 0;
    visibility: hidden;
    margin-bottom: var(--bs-room-slide-item-button-margin-bottom);
  }
}
@media (min-width: 992px) {
  .room-slide.room-slide-3col .room-slide-item {
    width: 25%;
  }
  .room-slide.room-slide-3col .room-slide-item.active {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .room-slide.room-slide-5col .room-slide-item {
    width: 15%;
  }
  .room-slide.room-slide-5col .room-slide-item:not(.active) .card-title {
    width: min-content;
  }
  .room-slide.room-slide-5col .room-slide-item.active {
    width: 40%;
  }
}

[data-theme-color-toggle=default] {
  background: #9B7C64 !important;
  color: #fff !important;
  position: relative;
  display: block;
}
[data-theme-color-toggle=default] i {
  opacity: 0;
  visibility: hidden;
}
[data-theme-color-toggle=default].selected i {
  opacity: 1;
  visibility: visible;
}

[data-theme-color-toggle=blue] {
  background: #366DC0 !important;
  color: #fff !important;
  position: relative;
  display: block;
}
[data-theme-color-toggle=blue] i {
  opacity: 0;
  visibility: hidden;
}
[data-theme-color-toggle=blue].selected i {
  opacity: 1;
  visibility: visible;
}

[data-theme-color-toggle=indigo] {
  background: #633A94 !important;
  color: #fff !important;
  position: relative;
  display: block;
}
[data-theme-color-toggle=indigo] i {
  opacity: 0;
  visibility: hidden;
}
[data-theme-color-toggle=indigo].selected i {
  opacity: 1;
  visibility: visible;
}

[data-theme-color-toggle=purple] {
  background: #504EAB !important;
  color: #fff !important;
  position: relative;
  display: block;
}
[data-theme-color-toggle=purple] i {
  opacity: 0;
  visibility: hidden;
}
[data-theme-color-toggle=purple].selected i {
  opacity: 1;
  visibility: visible;
}

[data-theme-color-toggle=pink] {
  background: #a2428c !important;
  color: #fff !important;
  position: relative;
  display: block;
}
[data-theme-color-toggle=pink] i {
  opacity: 0;
  visibility: hidden;
}
[data-theme-color-toggle=pink].selected i {
  opacity: 1;
  visibility: visible;
}

[data-theme-color-toggle=red] {
  background: #C44D4D !important;
  color: #fff !important;
  position: relative;
  display: block;
}
[data-theme-color-toggle=red] i {
  opacity: 0;
  visibility: hidden;
}
[data-theme-color-toggle=red].selected i {
  opacity: 1;
  visibility: visible;
}

[data-theme-color-toggle=orange] {
  background: #CF8452 !important;
  color: #fff !important;
  position: relative;
  display: block;
}
[data-theme-color-toggle=orange] i {
  opacity: 0;
  visibility: hidden;
}
[data-theme-color-toggle=orange].selected i {
  opacity: 1;
  visibility: visible;
}

[data-theme-color-toggle=yellow] {
  background: #CCAC57 !important;
  color: #fff !important;
  position: relative;
  display: block;
}
[data-theme-color-toggle=yellow] i {
  opacity: 0;
  visibility: hidden;
}
[data-theme-color-toggle=yellow].selected i {
  opacity: 1;
  visibility: visible;
}

[data-theme-color-toggle=green] {
  background: #548D57 !important;
  color: #fff !important;
  position: relative;
  display: block;
}
[data-theme-color-toggle=green] i {
  opacity: 0;
  visibility: hidden;
}
[data-theme-color-toggle=green].selected i {
  opacity: 1;
  visibility: visible;
}

[data-theme-color-toggle=teal] {
  background: #329BA0 !important;
  color: #fff !important;
  position: relative;
  display: block;
}
[data-theme-color-toggle=teal] i {
  opacity: 0;
  visibility: hidden;
}
[data-theme-color-toggle=teal].selected i {
  opacity: 1;
  visibility: visible;
}

[data-theme-color-toggle=cyan] {
  background: #2C83B1 !important;
  color: #fff !important;
  position: relative;
  display: block;
}
[data-theme-color-toggle=cyan] i {
  opacity: 0;
  visibility: hidden;
}
[data-theme-color-toggle=cyan].selected i {
  opacity: 1;
  visibility: visible;
}

[data-theme-color-toggle=gray] {
  background: #666A7D !important;
  color: #fff !important;
  position: relative;
  display: block;
}
[data-theme-color-toggle=gray] i {
  opacity: 0;
  visibility: hidden;
}
[data-theme-color-toggle=gray].selected i {
  opacity: 1;
  visibility: visible;
}

pre,
code,
kbd,
samp {
  font-weight: 500;
}

.code-wrapper {
  margin-bottom: 0;
  position: relative;
}
.code-wrapper .btn-clipboard {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  transform: none;
  font-size: 0.65rem;
  padding: 0.1rem 0.45rem 0.1rem;
  z-index: 3;
}
.code-wrapper pre > code[class*=language-] {
  padding: 2.25em;
}

.sticky-top-90 {
  top: 90px !important;
}

/* END THEME CUSTOM */