.star-rate-view {
    position: relative;
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 108.9 103.6'%3E%3Cg data-name='Layer 2'%3E%3Cpath fill='%23e9ecef' d='m108.9 39.6-37.6-5.5L54.4 0 37.6 34.1 0 39.6l27.2 26.5-6.4 37.5 33.6-17.7 33.7 17.7-6.4-37.5 27.2-26.5z' data-name='Layer 1'/%3E%3C/g%3E%3C/svg%3E");
    background-position: 0 0;
    background-repeat: repeat-x;
    text-align: left;

    &.star-rate-size-xs {
        width: 50px;
        height: 10px;
        background-size: 10px;
    }

    &.star-rate-size-sm {
        width: 80px;
        height: 16px;
        background-size: 16px;
    }

    &.star-rate-size-md,
    & {
        width: 120px;
        height: 24px;
        background-size: 24px;
    }

    &.star-rate-size-lg {
        width: 160px;
        height: 32px;
        background-size: 32px;
    }

    .star-value {
        position: absolute;
        height: 100%;
        width: 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 108.9 103.6'%3E%3Cg data-name='Layer 2'%3E%3Cpath fill='%23eeb461' d='m54.4 0 16.9 34.1 37.6 5.5-27.2 26.5 6.4 37.5-33.7-17.7-33.6 17.7 6.4-37.5L0 39.6l37.6-5.5L54.4 0z' data-name='Layer 1'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: repeat-x;

        @for $i from 0 through 10 {
            $rateValue: $i *5;
            $rateWidth: $i *10;

            &.rate-#{$rateValue} {
                width: #{$rateWidth} + '%';
            }
        }
    }

    @each $size in xs, sm, md, lg {
        @if ($size == 'md') {
            & .star-value,
            &.star-rate-size-#{$size} .star-value {
                @include bg-size ($size);
            }
        }

        @else {
            &.star-rate-size-#{$size} .star-value {
                @include bg-size ($size);
            }
        }
    }
}
