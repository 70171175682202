/* Video play button */
.btn-video-play {
    --#{$prefix}btn-video-play-color: #{$btn-video-play-color};
    --#{$prefix}btn-video-play-size: #{$btn-video-play-size};
    --#{$prefix}btn-video-play-size-md: #{$btn-video-play-size};
    --#{$prefix}btn-video-play-size-sm: #{$btn-video-play-size-sm};
    --#{$prefix}btn-video-play-size-lg: #{$btn-video-play-size-lg};
    --#{$prefix}btn-video-play-border-size: #{$btn-video-play-border-size};
    --#{$prefix}btn-video-play-border-size-md: #{$btn-video-play-border-size};
    --#{$prefix}btn-video-play-border-size-sm: #{$btn-video-play-border-size-sm};
    --#{$prefix}btn-video-play-border-size-lg: #{$btn-video-play-border-size-lg};
    --#{$prefix}btn-video-play-animation: #{$btn-video-play-animation};
    --#{$prefix}btn-video-play-transition: #{$transition-base};

    padding-left: 5px;
    position: relative;
    box-sizing: content-box;
    display: inline-flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: calc(var(--#{$prefix}btn-video-play-size) + 10px);
    height: calc(var(--#{$prefix}btn-video-play-size) + 10px);

    &:before,
    &:after {
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        content: "";
        display: inline-block;
        width: var(--#{$prefix}btn-video-play-size);
        height: var(--#{$prefix}btn-video-play-size);
        border-radius: 50%;
        transition: var(--#{$prefix}btn-video-play-transition);
        background: var(--#{$prefix}btn-video-play-color);
    }

    &:before {
        animation: var(--#{$prefix}btn-video-play-animation);
    }

    &:hover:before,
    &:hover:after {
        opacity: 0.55;
    }

    & span {
        display: block;
        position: relative;
        z-index: 3;
        width: 0;
        height: 0;
        border-left: var(--#{$prefix}btn-video-play-border-size) solid #fff;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }

    $i: 2;
    @each $size in sm md lg {
        $i: $i + 2;

        &.btn-video-play-#{$size}:before,
        &.btn-video-play-#{$size}:after {
            width: var(--#{$prefix}btn-video-play-size-#{$size});
            height: var(--#{$prefix}btn-video-play-size-#{$size});
        }

        &.btn-video-play-#{$size} {
            width: calc(var(--#{$prefix}btn-video-play-size-#{$size}) + $i*1px + 4px);
            height: calc(var(--#{$prefix}btn-video-play-size-#{$size}) + $i*1px + 4px);

            & span {
                border-left: var(--#{$prefix}btn-video-play-border-size-#{$size}) solid #fff;
                border-top: calc(var(--#{$prefix}btn-video-play-border-size-#{$size}) - $i*1px) solid transparent;
                border-bottom: calc(var(--#{$prefix}btn-video-play-border-size-#{$size}) - $i*1px) solid transparent;
            }
        }
    }
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}
