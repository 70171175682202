figure {
    --#{$prefix}figure-image-transition: #{$transition-base};
    --#{$prefix}figure-image-bg: #{$figure-image-bg};
    --#{$prefix}figure-image-bg-opacity: #{$figure-image-bg-opacity};
    --#{$prefix}figure-image-bg-static-opacity: #{$figure-image-bg-static-opacity};
    --#{$prefix}figure-image-scale: #{$figure-image-scale};

    &.image-hover-overlay-static {
        @include image-overlay-scale();

        &:before {
            @include image-overlay-befor-after();
            background: rgba(var(--#{$prefix}figure-image-bg), var(--#{$prefix}figure-image-bg-static-opacity));
        }
    }

    &.image-hover-overlay {
        @include image-overlay-scale(); 

        &:after {
            @include image-overlay-befor-after();
            background: rgba(var(--#{$prefix}figure-image-bg), var(--#{$prefix}figure-image-bg-opacity));
            opacity: 0;
        }

        &:hover::after {
            opacity: 1;
        }
    }

    &.image-hover-scale {
        @include image-overlay-scale();

        & img {
            transition: var(--#{$prefix}figure-image-transition);
        }

        &:hover img {
            transform: scale(var(--#{$prefix}figure-image-scale));
        }
    }

    & .image-hover-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: var(--#{$prefix}figure-image-transition);
        z-index: 2;
        color: white;
    }

    &:hover .image-hover-icon {
        opacity: 1;
    }
}
