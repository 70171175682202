#header .header-navbar {
    --#{$prefix}header-navbar-bg-gradient: #{$header-navbar-bg-gradient};

    --#{$prefix}header-navbar-navbar-brand-height: #{$header-navbar-navbar-brand-height};
    --#{$prefix}header-navbar-sticky-navbar-brand-height: #{$header-navbar-sticky-navbar-brand-height};

    --#{$prefix}header-navbar-brand-img-height: #{$header-navbar-brand-img-height};
    --#{$prefix}header-navbar-brand-img-margin-bottom: #{$header-navbar-brand-img-margin-bottom};
    --#{$prefix}header-navbar-sticky-brand-text-margin-top: #{$header-navbar-sticky-brand-text-margin-top};

    height: var(--#{$prefix}header-navbar-navbar-brand-height);
    position: relative;
    @include transition-base;

    &::before {
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        position: absolute;
        background: var(--#{$prefix}header-navbar-bg-gradient);
        z-index: -1;
    }

    & .navbar-brand {
        height: var(--#{$prefix}header-navbar-navbar-brand-height);
        @include transition-base;

        & .brand-img {
            height: var(--#{$prefix}header-navbar-brand-img-height);
            width: auto;
            margin-bottom: var(--#{$prefix}header-navbar-brand-img-margin-bottom);
            @include transition-base;
        }

        & .brand-text {
            @include transition-base;
        }

    }

    & .navbar-nav>.nav-item>.nav-link {
        position: relative;
        font-size: #{$font-size-lg};
        font-weight: #{$font-weight-bold} !important;

        &.active,
        &:hover,
        &:focus {
            color: var(--#{$prefix}primary);
        }

        & .dropdown-toggle-icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: unset;
        }

    }

    & .dropdown-menu {
        &:before {
            content: '';
            position: absolute;
            left: 20px;
            top: -18px;
            width: 0;
            height: 0;
            border-bottom: 10px solid var(--bs-body-bg);
            border-top: 10px solid transparent;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }

        & .dropdown-item {

            &.active,
            &:active,
            &:hover,
            &:focus {
                color: var(--#{$prefix}primary);
                background-color: transparent;
            }
        }
    }

    & .navbar-toggler {
        color: var(--#{$prefix}white);
    }

    // Navbar collapses.
    & .navbar-expand {
        @each $breakpoint in map-keys($grid-breakpoints) {
            $next: breakpoint-next($breakpoint, $grid-breakpoints);
            $infix: breakpoint-infix($next, $grid-breakpoints);

            &#{$infix} {
                @include media-breakpoint-up($next) {

                    & .navbar-nav-left,
                    .navbar-nav-right {
                        flex: 1 0 50%;
                    }

                    & .navbar-nav>.nav-item>.nav-link {
                        color: var(--#{$prefix}white);
                        font-size: unset;
                        font-weight: #{$font-weight-medium} !important;

                        &.active,
                        &:hover,
                        &:focus {
                            color: var(--#{$prefix}primary-light);
                        }

                        & .dropdown-toggle-icon {
                            font-size: 70%;
                            position: unset;
                            right: unset;
                            top: unset;
                            transform: unset;
                            -webkit-transform: unset;
                            -moz-transform: unset;
                            -ms-transform: unset;
                            -o-transform: unset;
                        }

                    }

                    & .dropdown-menu {
                        box-shadow: #{$box-shadow};
                    }
                }
            }
        }
    }

    &.header-navbar-sticky {
        height: var(--#{$prefix}header-navbar-sticky-navbar-brand-height);
        box-shadow: #{$box-shadow-sm};
        border-bottom: 1px solid var(--#{$prefix}light-border-subtle);

        & .navbar-brand {
            height: var(--#{$prefix}header-navbar-sticky-navbar-brand-height);

            & .brand-img {
                opacity: 0;
                height: 0;
                margin-bottom: 0;
            }

            & .brand-text {
                margin-top: var(--#{$prefix}header-navbar-sticky-brand-text-margin-top);
            }

        }

        & .navbar-nav>.nav-item>.nav-link:not(.active, :active, :hover, :focus) {
            color: var(--#{$prefix}body);
        }

        & .navbar-nav>.nav-item>.nav-link {

            &.active,
            &:hover,
            &:focus {
                color: var(--#{$prefix}primary);
            }
        }

        & .navbar-toggler {
            color: var(--#{$prefix}body);
        }
    }

}

.logo-light {
    display: none;
}

.logo {
    display: inline-block;
}

[data-bs-theme="dark"] {
    & .logo {
        display: none;
    }

    & .logo-light {
        display: inline-block;
    }
}
