/* Check room top */
.check-room-top {
    position: absolute;
    bottom: 0rem;
    left: 0;
    right: 0;
    z-index: 4;

    @include media-breakpoint-down(lg) {
        position: relative;
        bottom: unset;
        left: unset;
        right: unset;

        & .card {
            box-shadow: none !important;
        }

    }

}

/* Check room footer */
.check-room-bottom {
    margin-top: -2.5rem;

    @include media-breakpoint-down(lg) {
        margin-top: -4.25rem;
    }

    @include media-breakpoint-down(md) {
        margin-top: -6.15rem;
    }
}