// Scroll bar
@each $ex in '::',
'::-moz-',
'::-webkit-' {

    *#{$ex}scrollbar {
        width: #{$scrollbar-width};
    }

    *#{$ex}scrollbar-track {
        background-color: var(#{$scrollbar-track-bg});
    }

    *#{$ex}scrollbar-thumb {
        border: #{$scrollbar-thumb-border} solid transparent;
        background-clip: content-box;
        background-color: var(#{$scrollbar-thumb-bg});
    }
}

// Text Selection Color
@each $ex in '::',
'::-moz-',
'::-webkit-' {
    #{$ex}selection {
        background: $primary;
        color: $white;
    }
}

// Strong
b,
strong {
    font-weight: $font-weight-semibold;
}

mark {
    font-family: #{$font-family-monospace} !important;
    font-size: #{$font-size-base * .85};
}