// // Scroll top
.scroll-top {
    visibility: hidden;
    opacity: 0;
    width: 40px;
    height: 40px;
    background: rgba(var(--#{$prefix}primary-rgb), 0.55);
    @include transition-base;

    &.active {
        visibility: visible;
        opacity: 1;
    }

    &:hover {
        background: rgba(var(--#{$prefix}primary-rgb), 0.85);
    }
}