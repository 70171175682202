.hero-carousel,
.hero-image,
.hero-video {
    & .hero-caption {
        position: relative;
        height: calc(100vh - 2.5rem);
    }

    &.hero-image-full .hero-caption {
        height: 100vh;
    }

    & .hero-caption,
    &.hero-image-full .hero-caption {
        @include media-breakpoint-down(lg) {
            height: 895px;
        }

        @include media-breakpoint-down(md) {
            height: 550px;
        }
    }
}